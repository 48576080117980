import { configureStore } from "@reduxjs/toolkit";
import { AppDispatch } from "@store/store-helper";
import {
  planCreationReducer,
  resetPlanCreationState,
} from "@store/plan-creation/plan-creation-slice";
import * as Sentry from "@sentry/react";

// When adding a new reducer here also add the corresponding reset action to the "resetStore" function.
export const rootReducer = {
  planCreation: planCreationReducer,
};

/**
 * Sentry's Redux enhancer. It captures all actions and state changes.
 * DOC: https://docs.sentry.io/platforms/javascript/guides/react/features/redux/
 */
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below. It should be updated to filter out sensitive user data
});

export const store = configureStore({
  reducer: rootReducer,
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

/**
 * Resets the store. Dispatches all reducers reset actions. It can be called from any component.
 * For every new store slice added it's corresponding reset action must be added here.
 *
 * @param dispatch AppDispatch
 */
export function resetStore(dispatch: AppDispatch): void {
  dispatch(resetPlanCreationState());
}
