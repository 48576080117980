import { AdminAPITypes } from "@stellar/api-logic";
import { getAccountDisplayName } from "@utils/data-display";
import { IBreadcrumbsData } from "@components/breadcrumbs/breadcrumbs-types";
import {
  generateAccountRoute,
  generateCompanyRoute,
  generateProjectRoute,
} from "@router/router-helper";

/**
 * Helper module for generating breadcrumb data for different data types
 */
export const BreadcrumbsData = {
  /**
   * Generate breadcrumb data for a project
   */
  forProject(project: AdminAPITypes.IAdmProject): IBreadcrumbsData {
    return {
      label: project.name,
      link: generateProjectRoute(project.id),
    };
  },

  /**
   * Generate breadcrumb data for a user account
   */
  forAccount(account: AdminAPITypes.IAdmUser): IBreadcrumbsData {
    return {
      label: getAccountDisplayName(account),
      link: generateAccountRoute(account.identity),
    };
  },

  /**
   * Generate breadcrumb data for a company
   */
  forCompany(company: AdminAPITypes.IAdmCompany): IBreadcrumbsData {
    return {
      label: company.name,
      link: generateCompanyRoute(company.id),
    };
  },
};
