import { Box, TableCell } from "@mui/material";
import { HBSortableTable } from "@components/tables/hb-sortable-table";
import { AdminAPITypes } from "@stellar/api-logic";
import { getAccountDisplayName } from "@utils/data-display";
import { Compare } from "@utils/compare";
import { SortableColumn } from "@components/tables/hb-table-types";
import { AccountLink } from "@components/links/internal-links";
import { HBChip } from "@components/hb-customs/hb-chip";
import { generateCompanyRoute } from "@router/router-helper";
import { DateTimeUtils } from "@stellar/web-core";
import EmailIcon from "@mui/icons-material/Email";
import { AccountUtils } from "@utils/account";

interface IHBAccountTable {
  /** List of accounts to show in a table */
  accounts: AdminAPITypes.IAdmUser[];
}

export function HBAccountTable({ accounts }: IHBAccountTable): JSX.Element {
  const columns: SortableColumn<AdminAPITypes.IAdmUser>[] = [
    { id: "name", label: "Name", compareFunction: Compare.usersByName },
    { id: "email", label: "User Email" },
    {
      id: "companies",
      label: "Company",
      compareFunction: Compare.usersByCompany,
    },
    { id: "createdAt", label: "Created" },
  ];
  return (
    <HBSortableTable
      renderTableCells={(
        account: AdminAPITypes.IAdmUser & { id: string; name: string }
      ) => <AccountTableRowCells account={account} />}
      columns={columns}
      tableData={accounts.map((account) => ({
        ...account,
        // TODO: Workaround for now as id is mandatory for RowData but account.id might be undefined. However,
        // this "fixing" should not be done here but somewhere else
        id: account.identity,
        name: getAccountDisplayName(account),
      }))}
    />
  );
}

function AccountTableRowCells({
  account,
}: {
  account: AdminAPITypes.IAdmUser;
}): JSX.Element {
  return (
    <>
      <TableCell component="th" scope="row" padding="none">
        <NameCellContent account={account} />
      </TableCell>
      <TableCell>
        <EmailCellContent account={account} />
      </TableCell>
      <TableCell>
        {account.companies.map((company) => {
          return (
            <HBChip
              key={company.id}
              label={company.name}
              routeTo={generateCompanyRoute(company.id)}
            />
          );
        })}
      </TableCell>
      <TableCell>{DateTimeUtils.format({ date: account.createdAt })}</TableCell>
    </>
  );
}

interface IAccountProps {
  /** Account object that is displayed */
  account: AdminAPITypes.IAdmUser;
}

/**
 * renders the content of name cell
 * renders account name and email icon with a title when account is not created yet
 */
function NameCellContent({ account }: IAccountProps): JSX.Element {
  if (AccountUtils.isAccountCreated(account)) {
    return (
      <AccountLink accountId={account.identity}>
        {getAccountDisplayName(account)}
      </AccountLink>
    );
  }

  return (
    <>
      {getAccountDisplayName(account)}
      <Box component="span" title="Pending Invitation" sx={{ ml: "5px" }}>
        <EmailIcon fontSize="small" sx={{ verticalAlign: "text-bottom" }} />
      </Box>
    </>
  );
}

/**
 * renders the content of email cell
 */
function EmailCellContent({ account }: IAccountProps): JSX.Element {
  if (AccountUtils.isAccountCreated(account)) {
    return (
      <AccountLink accountId={account.identity}>{account.email}</AccountLink>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to return JSX.Element
  return <>{account.email}</>;
}
