import { HBDateRangePicker } from "@components/hb-customs/hb-date-range-picker";
import { Grid2 as Grid } from "@mui/material";
import { PlanRangeInfo } from "@pages/create-plan/create-plan-container/plan-range-info";
import { creationDatesSelector } from "@store/plan-creation/plan-creation-selector";
import { setCreationDates } from "@store/plan-creation/plan-creation-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useMemo } from "react";

/** Displaying date range and also the plan range info */
export function CreatePlanDateSection(): JSX.Element {
  const { startDate, endDate } = useAppSelector(creationDatesSelector);
  const dispatch = useAppDispatch();

  const creationDate = useMemo(() => {
    return {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    };
  }, [endDate, startDate]);

  return (
    <>
      <Grid size={{ md: 6 }}>
        <HBDateRangePicker
          startDate={creationDate.startDate}
          endDate={creationDate.endDate}
          onStartDateChanged={(newDate) =>
            dispatch(
              setCreationDates({
                startDate: newDate.getTime(),
              })
            )
          }
          onEndDateChanged={(newDate) =>
            dispatch(
              setCreationDates({
                endDate: newDate.getTime(),
              })
            )
          }
        />
      </Grid>
      <Grid size={{ md: 5 }}>
        <PlanRangeInfo startDate={startDate} endDate={endDate} />
      </Grid>
    </>
  );
}
