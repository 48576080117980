import { ApiClient } from "@stellar/api-logic";
import { runtimeConfig } from "@src/runtime-config";

export const CoreApi = new ApiClient({
  url: runtimeConfig.urls.apiUrl,
  clientOptions: {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- this key is defined by the backend
      "X-Holobuilder-Component": "adminpanel",
    },
  },
});
