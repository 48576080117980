/**
 * Code is inspired by the EnhancedTable found on
 * https://material-ui.com/components/tables/#sorting-amp-selecting
 */

import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Fragment, ReactNode, useState } from "react";
import { ComparatorFn } from "@utils/compare";
import {
  findColumnCompareFunction,
  isColumnWithDisabledSorting,
} from "@components/tables/hb-sortable-column";
import { SortableColumn } from "@components/tables/hb-table-types";

type OrderDirection = "asc" | "desc";

interface IHBSortableTableHeadProps<T> {
  /** Properties for the head cells */
  columns: SortableColumn<T>[];

  /** Hide/show the toggle to select all rows */
  isSelectable: boolean;

  /** Callback then the toggle for selection all rows is clicked */
  onSelectAllClick(event: React.ChangeEvent<HTMLInputElement>): void;

  /** The number of selected rows */
  numSelected: number;

  /** Callback when a header is clicked sorting */
  onRequestSort(
    event: React.MouseEvent<unknown>,
    property: keyof T | string
  ): void;

  /** Attribute name the table is sorted by */
  orderBy: keyof T | string;

  /** Current order of the sorting */
  order: OrderDirection;

  /** Number of rows in the table */
  rowCount: number;
}

function HBSortableTableHead<T>({
  columns,
  onSelectAllClick: handleSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  isSelectable,
}: IHBSortableTableHeadProps<T>): JSX.Element {
  return (
    <TableHead>
      <TableRow>
        {isSelectable && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={0 < numSelected && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={handleSelectAllClick}
              color="secondary"
            />
          </TableCell>
        )}
        {columns.map((column, index) => {
          function handleOnSortClick(event: React.MouseEvent<unknown>): void {
            onRequestSort(event, column.id);
          }

          return (
            <TableCell
              key={String(column.id)}
              padding={isSelectable && index === 0 ? "none" : "normal"}
              sortDirection={orderBy === column.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={handleOnSortClick}
                disabled={isColumnWithDisabledSorting(column)}
              >
                {column.label}
                {orderBy === column.id ? (
                  <Box
                    component="span"
                    sx={{
                      border: "0px",
                      clip: "rect(0 0 0 0)",
                      height: "1px",
                      m: "-1px",
                      overflow: "hidden",
                      p: "0px",
                      position: "absolute",
                      top: "20px",
                      width: "1px",
                    }}
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

/** Base props for the HBSortableTable component */
interface IHBSortableTableBaseProps<T> {
  /** Data to show inside the table body */
  tableData: T[];

  /** Columns defining the structure of the table */
  columns: SortableColumn<T>[];

  /** Id of the column that the table initially orders by */
  orderById?: keyof T;
}

/**
 * Props to pass when using the HBSortableTable in the default configuration.
 * Use the IHBCustomizedSortableTableProps variant to further customize the rendering of table rows.
 */
interface IHBSortableAndSelectableTableProps<T> {
  /** Function to render the table cells (without a row wrapper) for a list item */
  renderTableCells(data: T): ReactNode;

  /**
   * Function to render a whole table row for a list item. Undefined is allowed because
   * it should not be passed here, as the renderTableCells function is already used for rendering.
   */
  renderTableRow?: undefined;

  /** Enables the selection feature of the table (checkboxes on the left side). */
  isSelectable?: boolean;
}

/**
 * Props to pass when using the HBSortableTable with a custom TableRow renderer.
 * The selection feature of the table cannot be used in this case.
 */
interface IHBCustomizedSortableTableProps<T> {
  /** Function to manually render a whole custom table row for a list item. */
  renderTableRow(data: T): ReactNode;

  /**
   * Function to render the table cells (without a row wrapper) for a list item. Undefined is allowed because
   * it should not be passed here, as the renderTableRow function is already used for rendering.
   */
  renderTableCells?: undefined;

  /**
   * Enables the selection feature of the table.
   * Only false is allowed here, because Selection feature is not available when using renderTableRow for rendering.
   */
  isSelectable: false;
}

/** Props for the HBSortableTable component */
type IHBSortableTableProps<T> = IHBSortableTableBaseProps<T> &
  (IHBSortableAndSelectableTableProps<T> | IHBCustomizedSortableTableProps<T>);

/** A table with sortable and selectable rows. */
export function HBSortableTable<T extends { id: string | number }>({
  tableData,
  columns,
  renderTableCells,
  renderTableRow,
  isSelectable = false,
  orderById,
}: IHBSortableTableProps<T>): JSX.Element {
  const [order, setOrder] = useState<OrderDirection>("asc");
  const [orderBy, setOrderBy] = useState<keyof T | string>(
    orderById ? orderById : "name"
  );
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: string
  ): void {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");

    setOrderBy(property);
  }

  function handleSelectAllClick(
    event: React.ChangeEvent<HTMLInputElement>
  ): void {
    if (isSelectable) {
      if (event.target.checked) {
        setSelectedRowIds(tableData.map((data) => `${data.id}`));
        return;
      }

      setSelectedRowIds([]);
    }
  }

  function handleRowClick(event: React.MouseEvent<unknown>, id: string): void {
    if (isSelectable) {
      const selectedIndex = selectedRowIds.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRowIds, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRowIds.slice(1));
      } else if (selectedIndex === selectedRowIds.length - 1) {
        newSelected = newSelected.concat(selectedRowIds.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRowIds.slice(0, selectedIndex),
          selectedRowIds.slice(selectedIndex + 1)
        );
      }

      setSelectedRowIds(newSelected);
    }
  }

  const compareFunction = findColumnCompareFunction(columns, orderBy as string);
  const orderedCompareFunction = createCompareFunctionWithOrder(
    compareFunction,
    order
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <Table stickyHeader sx={{ minWidth: "750px" }}>
          <HBSortableTableHead
            {...{
              isSelectable,
              columns,
              order,
              orderBy,
            }}
            numSelected={selectedRowIds.length}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={tableData.length}
          />

          <TableBody>
            {stableSort(tableData, orderedCompareFunction).map((rowData) => {
              const isItemSelected =
                isSelectable && selectedRowIds.indexOf(`${rowData.id}`) !== -1;

              function handleTableRowClick(
                event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
              ): void {
                handleRowClick(event, `${rowData.id}`);
              }

              if (renderTableRow) {
                return (
                  <Fragment key={rowData.id}>
                    {renderTableRow(rowData)}
                  </Fragment>
                );
              } else if (renderTableCells) {
                return (
                  <HBSortableTableRow
                    key={rowData.id}
                    handleTableRowClick={handleTableRowClick}
                    renderTableCells={renderTableCells}
                    rowData={rowData}
                    isItemSelected={isItemSelected}
                    isSelectable={isSelectable}
                  />
                );
              } else {
                throw new Error(
                  "Either renderTableRow or renderTableCells needs to be passed to HBSortableTable"
                );
              }
            })}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
}

interface IHBSortableTableRowProps<T> {
  /** Function that defines what happens when the row is clicked by the user */
  handleTableRowClick(
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ): void;

  /** Function that renders the content in the row as table cells */
  renderTableCells(data: T): ReactNode;

  /** The content that should be shown inside of the table row */
  rowData: T;

  /** Boolean if user has selected this row or not */
  isItemSelected: boolean;

  /** Enables the selection feature of the table (checkboxes on the left side) */
  isSelectable: boolean;
}

function HBSortableTableRow<T extends { id: string | number }>({
  handleTableRowClick,
  renderTableCells,
  rowData,
  isItemSelected,
  isSelectable,
}: IHBSortableTableRowProps<T>): JSX.Element {
  return (
    <TableRow
      hover
      onClick={handleTableRowClick}
      role="checkbox"
      tabIndex={-1}
      selected={isItemSelected}
    >
      {isSelectable && (
        <TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} />
        </TableCell>
      )}

      {renderTableCells(rowData)}
    </TableRow>
  );
}

/**
 * Returns a compareFunction respecting a sort order, by reversing the input function's result when needed.
 * Assumes the input compareFunction is following the ascending convention of Array.sort().
 */
function createCompareFunctionWithOrder<T>(
  ascendingCompareFunction: ComparatorFn<T>,
  order: OrderDirection
): ComparatorFn<T> {
  if (order === "asc") {
    return ascendingCompareFunction;
  }

  return (a, b) => -ascendingCompareFunction(a, b);
}

/**
 * Sorting function that makes sure that elements with the same sort values always end up in the same order
 *
 * @param array Elements to sort
 * @param comparator Compare function to use
 */
function stableSort<T>(array: T[], comparator: ComparatorFn<T>): T[] {
  const stabilizedThis: [T, number][] = array.map((element, index) => [
    element,
    index,
  ]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((element) => element[0]);
}
