import { store } from "@store/store";
// eslint-disable-next-line no-restricted-imports -- The only place needed to define useAppDispatch and useAppSelector
import { useDispatch, useSelector } from "react-redux";

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;

// Use throughout app instead of plain `useDispatch` and `useSelector` to get type-safety
// Link: https://react-redux.js.org/using-react-redux/usage-with-typescript#withtypes
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
