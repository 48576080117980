import { PlanInfoFooter } from "@components/footers";
import { GeneralPlansTable } from "@components/tables/plans-table/general-plans-table";
import { PlansContext } from "@src/contexts/plans-context";
import { ContentLayout } from "@pages/content-layout";
import { useState } from "react";
import { useErrorHandler } from "@components/error-boundary/error-handling-context";
import { usePlansOfSubjectType } from "@hooks/plan/use-plans-of-subject-type";
import { SubjectEntityIdTypes, SubjectEntityTypes } from "@utils/utility-types";
import { LoadingScreen } from "@components/loading-screen";
import { AdminAPITypes } from "@stellar/api-logic";
import { HardwarePlansTable } from "@components/tables/plans-table/hardware-plans-table";

interface IProps {
  /** Type of the subject entity that the plan tab is related to */
  subjectType: SubjectEntityTypes;

  /** ID of the subject entity that the plan tab is related to */
  subjectId: SubjectEntityIdTypes;
}

/** Content inside the plans tab */
export function PlanInfo({ subjectType, subjectId }: IProps): JSX.Element {
  const [shouldFetchPlans, setShouldFetchPlans] = useState<boolean>(true);

  const { handleErrorWithDialog } = useErrorHandler();

  const [plans, isFetchingPlans] = usePlansOfSubjectType(
    subjectType,
    subjectId,
    handleErrorWithDialog,
    shouldFetchPlans,
    setShouldFetchPlans
  );
  const isDeviceType = subjectType === AdminAPITypes.ESubjectType.device;
  return (
    <ContentLayout
      footer={
        <PlanInfoFooter
          numberOfPlans={plans.length}
          subjectType={subjectType}
          subjectId={subjectId}
        />
      }
    >
      <LoadingScreen isFetching={isFetchingPlans}>
        <PlansContext.Provider
          value={{
            plans,
            fetchPlans: () => setShouldFetchPlans(true),
          }}
        >
          {isDeviceType ? <HardwarePlansTable /> : <GeneralPlansTable />}
        </PlansContext.Provider>
      </LoadingScreen>
    </ContentLayout>
  );
}
