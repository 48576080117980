import { Link, LinkProps } from "@mui/material";
import { PropsWithChildren } from "react";

interface IHBLinkProps {
  /** URL of the link */
  href: string;

  /** Text to be shown on hover */
  title?: string;

  /** Custom styling for link */
  sx?: LinkProps["sx"];
}

/** Custom Link component for HB */
export function HBLink({
  children,
  href,
  title,
  sx,
}: PropsWithChildren<IHBLinkProps>): JSX.Element {
  return (
    <Link
      sx={{ textDecoration: "underline", ...sx }}
      href={href}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </Link>
  );
}
