import { useState } from "react";
import { AdminAPITypes, CoreAPITypes } from "@stellar/api-logic";
import { CoreApi } from "@api/index";
import { Box, FormControl, MenuItem, TextField } from "@mui/material";
import { useErrorHandler } from "@components/error-boundary/error-handling-context";
import { HBButton } from "@components/hb-customs/hb-button";
import { HBSelect } from "@components/hb-customs/hb-select";
import { HBSnackbar } from "@components/hb-customs/hb-snackbar";
import { HBDialog } from "@components/hb-customs/hb-dialog";
import { startCase } from "lodash";

interface IProps {
  /** Used in change authentication API calls. */
  userId: string | undefined;

  /**
   * The current authentication provider id for the user's account.
   * Used to set the initial position of the change provider select element.
   */
  providerId: CoreAPITypes.EUserJsonProviderId;

  /** Full name of user. Used in dialog box */
  userName: string;
}

/**
 * Used to change a user's authentication provider.
 * Including SSO providers, email and company SSO.
 */
export function ChangeAccountAuthProvider({
  userId,
  providerId,
  userName,
}: IProps): JSX.Element {
  const { handleErrorWithDialog } = useErrorHandler();
  const errorMessage = "Authentication Method Change Error: ";

  const [currentProviderId, setCurrentProviderId] =
    useState<CoreAPITypes.EUserJsonProviderId>(providerId);
  const [selectedProvider, setSelectedProvider] =
    useState<AdminAPITypes.EAdminAuthMethods>(
      convertProviderIdToProvider(providerId)
    );

  const [companyId, setCompanyId] = useState<string>("");
  const [shouldShowConfirmation, setShouldShowConfirmation] =
    useState<boolean>(false);
  const [shouldShowProviderNotValid, setShouldShowProviderNotValid] =
    useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  /**
   * Used to determine if the current authentication provider is the same
   * as the authentication provider that the user has selected in the UI.
   * This is to tell the user that the app will reset the authentication
   * provider and not change it to another provider.
   */
  const shouldResetSelectedProvider =
    isSelectedProviderSameAsCurrentProvider(
      selectedProvider,
      currentProviderId
    ) &&
    (selectedProvider !== AdminAPITypes.EAdminAuthMethods.companySso ||
      !companyId);

  const providerChangeText: string = shouldResetSelectedProvider
    ? "reset"
    : "change";

  const availableProviders = Object.values(AdminAPITypes.EAdminAuthMethods)
    .map((provider) => startCase(provider))
    .join(", ");

  const isSelectedProviderCompanySso =
    selectedProvider ===
    AdminAPITypes.AdminAuthUserJsonProviderIdToOAuthProvider.GENERIC_OAUTH;

  async function handleOnDialogConfirm(): Promise<void> {
    if (!userId) {
      return;
    }
    try {
      if (shouldResetSelectedProvider) {
        const { providerId } = await CoreApi.V0.ADMIN.resetAuthMethod(userId);

        // Clears company id input field if reset provider is Company SSO
        if (providerId === CoreAPITypes.EUserJsonProviderId.genericOAuth) {
          setCompanyId("");
        }
        setIsOpen(true);
      } else {
        if (selectedProvider === AdminAPITypes.EAdminAuthMethods.companySso) {
          const { providerId } =
            await CoreApi.V0.ADMIN.changeAuthMethodToCompanySso(
              userId,
              companyId
            );

          setCurrentProviderId(providerId);
          setCompanyId("");
        } else {
          const { providerId } = await CoreApi.V0.ADMIN.changeAuthMethod(
            userId,
            selectedProvider
          );

          setCurrentProviderId(providerId);
        }
        setIsOpen(true);
      }
    } catch (error) {
      handleErrorWithDialog(errorMessage, error);
    } finally {
      setShouldShowConfirmation(false);
    }
  }

  function handleChange(provider: string, companyId: string): void {
    if (isNotValidProvider(provider)) {
      setSelectedProvider(provider as AdminAPITypes.EAdminAuthMethods);
      setShouldShowProviderNotValid(true);
    } else {
      setSelectedProvider(provider as AdminAPITypes.EAdminAuthMethods);
    }

    if (companyId) {
      setCompanyId("");
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          padding: "0.5rem 0px",
        }}
      >
        <FormControl sx={{ minWidth: "8rem", width: "fit-content" }}>
          <HBSelect
            value={selectedProvider}
            onChange={(ev) => handleChange(ev.target.value, companyId)}
            variant="outlined"
            label="Methods"
          >
            {AdminAPITypes.AdminAuthMethods.map((provider) => (
              <MenuItem
                key={provider}
                value={provider}
                disabled={
                  provider ===
                  AdminAPITypes.AdminAuthUserJsonProviderIdToOAuthProvider
                    .serviceAccount
                }
              >
                {startCase(provider)}
              </MenuItem>
            ))}
          </HBSelect>
        </FormControl>

        {isSelectedProviderCompanySso && (
          <FormControl sx={{ minWidth: "8rem", width: "fit-content" }}>
            <TextField
              label="Company Id"
              required
              variant="outlined"
              value={companyId}
              onChange={(ev) => {
                setCompanyId(ev.target.value);
              }}
            />
          </FormControl>
        )}

        <HBButton
          size="large"
          onClick={() => setShouldShowConfirmation(true)}
          isDisabled={
            isNotValidProvider(selectedProvider) ||
            (isSelectedProviderCompanySso &&
              selectedProvider !==
                convertProviderIdToProvider(currentProviderId) &&
              !companyId)
          }
        >
          {startCase(providerChangeText)}
        </HBButton>
      </Box>

      {shouldShowProviderNotValid && (
        <HBDialog
          title="Cannot Change To Selected Authentication Method"
          onClose={() => setShouldShowProviderNotValid(false)}
        >
          <p>
            <b>{startCase(selectedProvider)}</b> is not a valid authentication
            method.
          </p>
          <p>Please choose from: {availableProviders}.</p>
        </HBDialog>
      )}

      {shouldShowConfirmation && (
        <HBDialog
          title="Change authentication method"
          onClose={() => setShouldShowConfirmation(false)}
          onConfirm={handleOnDialogConfirm}
        >
          Are you sure you want to {providerChangeText} the authentication
          method for {userName}
          {shouldResetSelectedProvider && "?"}{" "}
          {!shouldResetSelectedProvider && (
            <>
              to <b>{startCase(selectedProvider)}</b>
              {!companyId && "?"}{" "}
            </>
          )}
          {companyId && (
            <>
              with ID: <b>{companyId}</b>?
            </>
          )}
        </HBDialog>
      )}

      <HBSnackbar isOpen={isOpen} onClose={() => setIsOpen(false)}>
        Authentication method updated.
      </HBSnackbar>
    </>
  );
}

function convertProviderIdToProvider(
  providerId: CoreAPITypes.EUserJsonProviderId
): AdminAPITypes.EAdminAuthMethods {
  return AdminAPITypes.AdminAuthUserJsonProviderIdToOAuthProvider[
    providerId as keyof typeof AdminAPITypes.AdminAuthUserJsonProviderIdToOAuthProvider
  ] as AdminAPITypes.EAdminAuthMethods;
}

function isNotValidProvider(method: string): boolean {
  return !Object.values(AdminAPITypes.EAdminAuthMethods).includes(
    method as AdminAPITypes.EAdminAuthMethods
  );
}

function isSelectedProviderSameAsCurrentProvider(
  selectedProvider: AdminAPITypes.EAdminAuthMethods,
  currentProviderId: CoreAPITypes.EUserJsonProviderId
): boolean {
  return selectedProvider === convertProviderIdToProvider(currentProviderId);
}
