import { TextField } from "@mui/material";

interface Props {
  /** Label of the text field */
  label?: string;

  /** The actual comment */
  text: string;

  /** Setter for changing the comment */
  setText: (newText: string) => void;
}

/** Shows a full width multiline textfield */
export function HBMultilineTextField({
  label,
  text,
  setText,
}: Props): JSX.Element {
  return (
    <TextField
      label={label}
      multiline
      minRows={4}
      value={text}
      onChange={(event: React.ChangeEvent<{ value: string }>) => {
        setText(event.target.value);
      }}
      variant="outlined"
      fullWidth
      required
    />
  );
}
