import { runtimeConfig } from "@src/runtime-config";
import { LocalStorageUtils } from "@utils/local-storage-utils";
import { getPathnameFromUrl, getQueryParamsFromSearch } from "@utils/url-utils";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useLoggedInUser } from "@hooks/use-logged-in-user";
import { useSphereXGApi } from "@hooks/use-sphere-xg-api";

/**
 * No matter the dependencies it seems that the useEffect to call the init route
 * is called twice, so it stores similar to the isApiInitialized state, whether
 * initialized was already called.
 */
let isApiInitializedLocally = false;

/**
 * Hook to initialize the user in the dashboard 2.0 API
 */
export function useTrackPageLoad(): void {
  const routeLocation = useLocation();
  const appParams = useParams();
  const [loggedInUser] = useLoggedInUser();
  const { postInit } = useSphereXGApi();

  // Call the routes to initialize the user in the dashboard 2.0 API
  // This route will take care of tracking the page load in Amplitude for all users.
  useEffect(() => {
    async function trackPageLoaded(): Promise<void> {
      const urlPathname = getPathnameFromUrl(routeLocation.pathname, appParams);
      const urlQuery = getQueryParamsFromSearch(routeLocation.search);

      const deviceSizes = {
        screenWidth: window.screen.width,
        windowWidth: window.innerWidth,
        screenHeight: window.screen.height,
        windowHeight: window.innerHeight,
        windowOrientation: window.screen.orientation?.type,
      };

      try {
        const appInitTime =
          LocalStorageUtils.getStringItem("app-init-time") ?? undefined;
        const response = await postInit({
          version: runtimeConfig.appVersion,
          application: "Admin Panel",
          userId: loggedInUser?.userId ?? "ADMIN_PANEL_USER",
          userEmail: loggedInUser?.mailAddress ?? "",
          appInitTime,
          urlPathname,
          urlQuery,
          ...deviceSizes,
        });
        if (response.success) {
          LocalStorageUtils.setStringItem(
            "app-init-time",
            Date.now().toString()
          );
        }
      } catch (error) {
        // TODO: Use Sentry https://faro01.atlassian.net/browse/ST-747
        // eslint-disable-next-line no-console -- It is ok to log the message
        console.error("Error calling init route", { error: error as Error });
      }
    }

    if (
      !isApiInitializedLocally &&
      routeLocation?.pathname &&
      appParams &&
      loggedInUser
    ) {
      isApiInitializedLocally = true;
      try {
        // We make sure that there is no way the application fails if something goes wrong in this call
        trackPageLoaded();
      } catch (error) {
        // eslint-disable-next-line no-console -- It is ok to log the message
        console.error("Error calling init route", { error: error as Error });
      }
    }
  }, [routeLocation, appParams, loggedInUser, postInit]);
}
