import { Header } from "@components/header";
import { AdminAPITypes } from "@stellar/api-logic";
import { CreatePlanContainer } from "@pages/create-plan/create-plan-container/create-plan-container";
import { ContentLayout } from "@pages/content-layout";
import { useErrorHandler } from "@components/error-boundary/error-handling-context";
import { CompanyBreadcrumbs } from "@components/breadcrumbs/company-breadcrumbs";
import { useCompany } from "@hooks/use-company";
import { LoadingScreen } from "@components/loading-screen";
import { useAppParams } from "@router/router-helper";
import { useAppDispatch } from "@store/store-helper";
import { setPlanSubject } from "@store/plan-creation/plan-creation-slice";

interface Props {
  /** Whether the plan is a device plan or a simple company plan */
  isDevicePlan: boolean;
}

/** Show the general layout of plan creation for company */
export function CreateCompanyPlanPage({ isDevicePlan }: Props): JSX.Element {
  const { companyId } = useAppParams();
  const dispatch = useAppDispatch();

  const { handleErrorWithPage } = useErrorHandler();

  const [company, isLoading] = useCompany(companyId, handleErrorWithPage);

  if (company) {
    dispatch(
      setPlanSubject({
        planSubjectId: company.id.toString(),
        planSubjectType: isDevicePlan
          ? AdminAPITypes.ESubjectType.device
          : AdminAPITypes.ESubjectType.company,
        planSubjectName: company.name,
      })
    );
  }

  return (
    <LoadingScreen isFetching={!company || isLoading}>
      {company && (
        <ContentLayout
          breadcrumbs={<CompanyBreadcrumbs company={company} />}
          header={
            <Header
              heading={
                isDevicePlan
                  ? "Create New Hardware License"
                  : "Create New Company Plan"
              }
              shouldUseSlimVariant
            />
          }
        >
          <CreatePlanContainer />
        </ContentLayout>
      )}
    </LoadingScreen>
  );
}
