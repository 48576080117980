import { InfoGrid } from "@components/info-grid/info-grid";
import { AdminAPITypes } from "@stellar/api-logic";
import { Constraints } from "@components/constraints";
import { ILabelInfoPair } from "@components/info-grid/info-row-types";
import {
  formatPlanName,
  getAccountDisplayName,
  uppercaseFirst,
} from "@utils/data-display";
import { PlanDataExtractor } from "@utils/plan-utils/plan-data-extractor";
import { AccountEmail } from "@components/plan-info-grid/account-email";
import { DateTimeUtils } from "@stellar/web-core";
import { InlineEditableTextField } from "@components/inline-editable-text-field";
import { useErrorHandler } from "@components/error-boundary/error-handling-context";
import { usePlansContext } from "@src/contexts/plans-context";
import { useUpdatePlan } from "@hooks/plan/use-update-plan";
import { PlanUtcDate } from "@components/plan-info-grid/plan-utc-date";

interface IProps {
  /** The plan to show the details of */
  plan: AdminAPITypes.IPlan;
}

/**
 * Shows an InfoGrid containing a plan's details.
 * Fetches additional information about a plan's subscription if needed.
 */
export function PlanInfoGrid({ plan }: IProps): JSX.Element {
  return (
    <InfoGrid
      leftColumnRows={CreateLeftPlanInfoColumnRows(plan)}
      rightColumnRows={createRightPlanInfoColumnRows(plan)}
    />
  );
}

/**
 * Creates the left column of ILabelInfoPair for the table used in a plan-info-grid component
 */
function CreateLeftPlanInfoColumnRows(
  plan: AdminAPITypes.IPlan
): ILabelInfoPair[] {
  const { fetchPlans } = usePlansContext();
  const { handleErrorWithDialog } = useErrorHandler();

  const { updatePlan } = useUpdatePlan(handleErrorWithDialog);

  const leftColumnRows: ILabelInfoPair[] = [
    { label: "Name:", info: formatPlanName(plan) },
  ];

  leftColumnRows.push(
    ...[
      createSubscriberInfoRowProps(plan),
      {
        label: "Start Date:",
        info: <PlanUtcDate date={plan.startDate} />,
      },
    ]
  );

  const planEndDate = PlanDataExtractor.getEndDate(plan);

  async function onCommentChanged(comment: string): Promise<void> {
    await updatePlan(plan, { description: comment });

    fetchPlans();
  }

  if (planEndDate) {
    leftColumnRows.push({
      label: "End Date:",
      info: <PlanUtcDate date={planEndDate} />,
    });
  }

  return leftColumnRows.concat([
    {
      label: "Limits:",
      info: <Constraints constraints={plan.constraints} />,
    },
    {
      label: "Comments:",
      info: (
        <InlineEditableTextField
          initialText={plan.description}
          isMultiLine={true}
          onConfirmCallback={onCommentChanged}
        />
      ),
    },
  ]);
}

/**
 * Creates the right column of ILabelInfoPair for the table used in a plan-info-grid component
 */
function createRightPlanInfoColumnRows(
  plan: AdminAPITypes.IPlan
): ILabelInfoPair[] {
  const rightColumnRows: ILabelInfoPair[] = [];

  rightColumnRows.push(
    {
      label: "Created by:",
      info: <AccountEmail accountId={plan.createdBy} />,
    },
    {
      label: "Created date:",
      info: DateTimeUtils.format({ date: plan.createdAt }),
    },
    {
      label: "Status:",
      info: uppercaseFirst(plan.status.identifier),
    }
  );

  if (plan.status.identifier === "invalid" && plan.status.message) {
    rightColumnRows.push({
      label: "Invalidation Reason:",
      info: <b>{plan.status.message}</b>,
    });
  }

  return rightColumnRows;
}

/**
 * Creates the ILabelInfoPair for the Subscriber field that varies with the subscription type.
 */
function createSubscriberInfoRowProps(
  plan: AdminAPITypes.IPlan
): ILabelInfoPair {
  switch (plan.subscriber.type) {
    case AdminAPITypes.ESubscriberType.company: {
      return {
        label: "Company:",
        info: plan.subscriber.obj.name,
      };
    }
    case AdminAPITypes.ESubscriberType.user: {
      return {
        label: "User:",
        info: getAccountDisplayName(plan.subscriber.obj),
      };
    }
  }
}
