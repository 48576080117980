import { Link } from "react-router-dom";
import { MainRoutes } from "@router/route-params";
import { APITypes } from "@stellar/api-logic";
import { ReactChildrenOnly } from "@utils/utility-types";
import { PropsWithChildren } from "react";
import {
  generateAccountRoute,
  generateCompanyRoute,
  generateProjectRoute,
} from "@router/router-helper";

/**
 * Provide a link to the homepage from anywhere
 */
export function HomeLink({ children }: ReactChildrenOnly): JSX.Element {
  return <Link to={MainRoutes.root}>{children}</Link>;
}

interface IAccountLinkProps {
  /** ID of the user account that the user will be redirected when they click on the link */
  accountId: APITypes.UserId;
}

/**
 * Provide a link to an account from anywhere
 */
export function AccountLink({
  accountId,
  children,
}: PropsWithChildren<IAccountLinkProps>): JSX.Element {
  return <Link to={generateAccountRoute(accountId)}>{children}</Link>;
}

interface ICompanyLinkProps {
  // ID of the company that the user will be redirected when they click on the link
  companyId: string | number;
}

/**
 * Provide a link to a company from anywhere
 */
export function CompanyLink({
  companyId,
  children,
}: PropsWithChildren<ICompanyLinkProps>): JSX.Element {
  return <Link to={generateCompanyRoute(companyId)}>{children}</Link>;
}

interface IProjectLinkProps {
  /** ID of the project that the user will be redirected when they click on the link */
  projectId: APITypes.ProjectId;
}

/**
 * Provide a link to a project from anywhere
 */
export function ProjectLink({
  projectId,
  children,
}: PropsWithChildren<IProjectLinkProps>): JSX.Element {
  return <Link to={generateProjectRoute(projectId)}>{children}</Link>;
}
