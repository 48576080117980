import { Typography } from "@mui/material";
import { IErrorWithTitle } from "@components/error-boundary/error-types";
import { ErrorBoundaryUtils } from "@components/error-boundary/error-boundary-utils";
import { Warning } from "@mui/icons-material";
import { HBDialog } from "@components/hb-customs/hb-dialog";

interface IProps {
  /** An error with user readable text and actual error */
  errorWithTitle: IErrorWithTitle;

  /** Callback when Close button is clicked */
  onClose(): void;
}

/** Displays an error message in a Dialog with a close button. */
export function ErrorDialog({ errorWithTitle, onClose }: IProps): JSX.Element {
  function handleClose(): void {
    onClose();
  }

  return (
    <HBDialog
      onClose={handleClose}
      title={
        <>
          <Warning sx={{ marginRight: "10px" }} />
          <Typography variant="h6">{errorWithTitle.title}</Typography>
        </>
      }
    >
      <Typography component="div" gutterBottom>
        <ErrorBoundaryUtils.GetErrorDisplayMessage
          error={errorWithTitle.error}
        />
      </Typography>
    </HBDialog>
  );
}
