import { useErrorHandler } from "@components/error-boundary/error-handling-context";
import { HBSpinner } from "@components/hb-customs/hb-spinner";
import { AdminAPITypes } from "@stellar/api-logic";
import { ProjectCreationContext } from "@pages/create-project/project-creation-context";
import { useEffect, useState } from "react";
import { useAppNavigation } from "@router/use-app-navigation";
import { useCreateProject } from "@hooks/use-create-project";
import { CreateProjectPage } from "@pages/create-project/create-project-page";
import { PlanCreationDialog } from "@pages/create-project/plan-creation-dialog";
import { ICompanyProjectCreation } from "@pages/create-project/project-creation-context-types";
import { ProjectSummaryPage } from "@pages/create-project/project-summary-page";

interface ICreateProjectContainerProps {
  /** The subject of the project which it belongs to */
  subject: ICompanyProjectCreation;
}

/**
 * Manage the general project creation logics and states and update the related context
 */
export function CreateProjectContainer({
  subject,
}: ICreateProjectContainerProps): JSX.Element | null {
  const [projectName, setProjectName] = useState<string>("");
  const [projectAddress, setProjectAddress] = useState<string>("");
  const [projectArea, setProjectArea] = useState<number | null>(0);
  const [projectAreaUnit, setProjectAreaUnit] =
    useState<AdminAPITypes.EAreaUnit>(AdminAPITypes.EAreaUnit.sqft);

  // If new project is for account, initial value is set to account's email as this value should not be changed
  const [projectManager, setProjectManager] = useState<string>("");

  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [shouldShowSummary, setShouldShowSummary] = useState<boolean>(false);
  const [isStandalone, setIsStandalone] = useState<boolean>(false);

  const { handleErrorWithDialog } = useErrorHandler();
  const { navigateToProfilePage } = useAppNavigation();

  const {
    isRequestingProjectCreation,
    requestCreateProject,
    createdProjectId,
  } = useCreateProject({ errorHandler: handleErrorWithDialog });

  // Update fetching state based on project creation request state
  useEffect(() => {
    setIsFetchingData(isRequestingProjectCreation);
  }, [isRequestingProjectCreation]);

  if (isFetchingData) {
    return <HBSpinner />;
  }

  const createProjectPayload = {
    name: projectName,
    address: projectAddress,
    area: {
      amount: projectArea ?? 0,
      unit: projectAreaUnit,
    },
    projectManager,
    // eslint-disable-next-line @typescript-eslint/naming-convention -- backend variable definition
    standalone: isStandalone,
  };

  // Navigate to the user's or company's profile page after creation of a non-standalone project
  if (createdProjectId && !isStandalone) {
    navigateToProfilePage(subject.type, subject.company.id.toString());
    return null;
  }

  let content: JSX.Element;
  if (createdProjectId) {
    content = <PlanCreationDialog />;
  } else {
    content = (
      <>
        {/* TODO: Block navigating away if there is an unsaved form: https://faro01.atlassian.net/browse/ST-2322 */}

        {shouldShowSummary ? (
          <ProjectSummaryPage
            onConfirmCreation={() =>
              requestCreateProject({
                createProjectPayload,
                subject,
              })
            }
          />
        ) : (
          <CreateProjectPage />
        )}
      </>
    );
  }

  return (
    <ProjectCreationContext.Provider
      value={{
        subject,
        projectName,
        setProjectName,
        projectAddress,
        setProjectAddress,
        projectArea,
        setProjectArea,
        projectAreaUnit,
        setProjectAreaUnit,
        projectManager,
        setProjectManager,
        createdProjectId,
        isFetchingData,
        setIsFetchingData,
        isStandalone,
        setIsStandalone,
        shouldShowSummary,
        setShouldShowSummary,
      }}
    >
      {createdProjectId && <PlanCreationDialog />}

      {content}
    </ProjectCreationContext.Provider>
  );
}
