import { Button, ButtonProps } from "@mui/material";
import { PropsWithChildren } from "react";

type MuiButtonVariants = "text" | "outlined" | "contained";

export interface IHBButtonProps
  extends Pick<ButtonProps, "onClick" | "size" | "startIcon"> {
  /** The three button types we have */
  type?: "primary" | "secondary" | "text";

  /** If `true`, the button will be disabled. */
  isDisabled?: boolean;

  /** Optional sx properties to override the button style. */
  sx?: ButtonProps["sx"];
}

/**
 * Custom HoloBuilder button
 *
 * Wrapper around the material-ui Button component offering limited settings,
 * basically removing the usage of the "color" props and the "default" Button variant,
 * which can't be changed via the theme
 *
 * primary -> filled button
 * secondary -> outlined button (not filled)
 * text -> just the text/content
 */
export function HBButton({
  children,
  isDisabled,
  type = "primary",
  size,
  sx = {},
  startIcon,
  onClick,
}: PropsWithChildren<IHBButtonProps>): JSX.Element {
  let buttonVariant: MuiButtonVariants = "contained";
  if (type === "secondary") {
    buttonVariant = "outlined";
  } else if (type === "text") {
    buttonVariant = "text";
  }

  return (
    <Button
      color={"primary"}
      variant={buttonVariant}
      disabled={isDisabled}
      {...{
        onClick,
        size,
        startIcon,
      }}
      sx={sx}
    >
      {children}
    </Button>
  );
}
