import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import {
  BaseProduct,
  CustomProductIdentification,
  ProductIdentifier,
} from "@store/plan-creation/plan-creation-slice-helper-types";
import { allProducts } from "@utils/product/product-list";

/** Returns true if the product is a single feature */
export function isProductSingleFeature(product: BaseProduct): boolean {
  return product.type === "feature";
}

/** Returns true if the product is a bundle feature */
export function isProductBundleFeature(product: BaseProduct): boolean {
  return product.type === "bundle";
}

/** Returns true if the product is a custom feature */
export function isProductCustomFeature(product: BaseProduct): boolean {
  return product.type === "custom";
}

/** Returns true if the identifier is a trial */
export function isIdentifierTrial(identifier: ProductIdentifier): boolean {
  return allProducts[identifier]?.type === "trial";
}

/** Maps the custom product ID to the bundle identifications that will be added with it */
export const customProductIdToBundleIdentification: Record<
  CustomProductIdentification,
  ProductIdentifier[]
> = {
  standard360: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGStandard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
  ],

  standardPointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGStandard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
  ],

  pro360: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGPro,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
  ],

  proPointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGPro,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
  ],

  enterprise360: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
  ],

  enterprisePointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
  ],

  enterprise360PointCloud: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
  ],

  enterprise360Trial: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXgTrialIndicator,
  ],

  enterprisePointCloudTrial: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXgTrialIndicator,
  ],

  enterprise360PointCloudTrial: [
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGEnterprise,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddon360,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXGAddonPointCloud,
    APITypes.EUserSubscriptionRole.sphereDashboard,
    AdminAPITypes.EFeatureBundleIdentifier.sphereXgTrialIndicator,
  ],
};
