import { formatPlanSubjectType } from "@utils/data-display";
import { ICompanyProjectCreation } from "@pages/create-project/project-creation-context-types";

/**
 * Generates a text displaying whether the project is for a group in a company or a user
 */
export function formatCreatedProjectSubject(
  subject: ICompanyProjectCreation
): string {
  return `${formatPlanSubjectType(subject.type)} - ${subject.group.name}`;
}

// TODO: This logic is copied from the Dashboard. Needs to be moved to web-core and add appropriate tests for it
/**
 * Check whether the input is a valid email address or not
 */
export function isValidEmail(email: string): boolean {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- 6 is the minimum length of an email address
  if (!email || !email.length || email.length < 6) {
    return false;
  } else {
    // eslint-disable-next-line max-len
    return /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }
}
