import { Header } from "@components/header";
import { ILabelInfoPair } from "@components/info-grid/info-row-types";
import { OldAdminPanelCompanyLink } from "@components/links/external-links";
import { DateTimeUtils } from "@stellar/web-core";
import { useCompanyContext } from "@pages/company-profile/company-context";

/**
 * Display company information in the header of company profile page
 */
export function CompanyProfileHeader(): JSX.Element {
  const company = useCompanyContext();

  const headerProperties: ILabelInfoPair[] = [
    {
      label: "Created",
      info: DateTimeUtils.format({ date: company.createdAt }),
    },
    {
      label: "Members",
      info: (
        <>
          {company.memberCount} (
          <OldAdminPanelCompanyLink companyId={company.id}>
            View Members
          </OldAdminPanelCompanyLink>
          )
        </>
      ),
    },
    {
      label: "Projects",
      info: company.projectCount,
    },
  ];

  return (
    <Header
      title="Company"
      heading={company.name}
      rightProperties={headerProperties}
      shouldUseSlimVariant
    />
  );
}
