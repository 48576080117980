import { PropsWithChildren } from "react";
import { runtimeConfig } from "@src/runtime-config";
import { APITypes } from "@stellar/api-logic";
import { HBLink } from "@components/hb-customs/hb-link";
import { ReactChildrenOnly } from "@utils/utility-types";
import { Color } from "@utils/styles/default-colors";

/** External link to the workspace page */
export function WorkspaceLink({ children }: ReactChildrenOnly): JSX.Element {
  return (
    <HBLink href={runtimeConfig.urls.entryPageUrl} title="Go to Workspaces">
      {children}
    </HBLink>
  );
}

interface IOldAdminPanelCompanyLinkProps {
  /** ID of the company to open in the old Admin Panel */
  companyId: APITypes.CompanyId;
}

/** External link to company with companyId in the old admin-panel */
export function OldAdminPanelCompanyLink({
  children,
  companyId,
}: PropsWithChildren<IOldAdminPanelCompanyLinkProps>): JSX.Element {
  const href = new URL(
    `admin#nav_dashboard/edit/${companyId}`,
    runtimeConfig.urls.apiUrl
  ).toString();

  return (
    <HBLink href={href} title="Open Company in Legacy Admin Panel">
      {children}
    </HBLink>
  );
}

interface IOldAdminPanelProjectLinkProps {
  /** ID of the project to open in the old Admin Panel */
  projectId: APITypes.ProjectId;
}

/** External link to project with projectId in the old admin-panel */
export function OldAdminPanelProjectLink({
  children,
  projectId,
}: PropsWithChildren<IOldAdminPanelProjectLinkProps>): JSX.Element {
  const href = new URL(
    `admin#nav_projects/search/${projectId}`,
    runtimeConfig.urls.apiUrl
  ).toString();

  return (
    <HBLink href={href} title="Open Project in Legacy Admin Panel">
      {children}
    </HBLink>
  );
}

interface IOldAdminPanelAccountLinkProps {
  /** ID of the account to open in the old Admin Panel */
  accountId: APITypes.UserId;
}

/** External link to account with accountId in the old admin-panel */
export function OldAdminPanelAccountLink({
  children,
  accountId,
}: PropsWithChildren<IOldAdminPanelAccountLinkProps>): JSX.Element {
  const href = new URL(
    `admin#nav_actions/search/${accountId}`,
    runtimeConfig.urls.apiUrl
  ).toString();

  return (
    <HBLink href={href} title="Open Account in Legacy Admin Panel">
      {children}
    </HBLink>
  );
}

/** External link to the report an issue channel in Team Stellar */
export function TeamStellarReportIssueChannel({
  children,
}: ReactChildrenOnly): JSX.Element {
  return (
    <HBLink
      sx={{ color: Color.blue }}
      href={
        // eslint-disable-next-line max-len
        "https://teams.microsoft.com/l/channel/19%3A41af2ba71f5249d3a952a120105037e4%40thread.tacv2/Report%20an%20issue?groupId=d70c17f2-1502-4999-b2a7-e03b2be3b6f9&tenantId=a79dc4cb-6a24-4e47-8db4-c7bd746e23b7"
      }
      title="Report an issue in Team Stellar"
    >
      {children}
    </HBLink>
  );
}
