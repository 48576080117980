import {
  AddButton,
  CreateAccountProjectButton,
  CSVExportButton,
} from "@components/buttons";
import { HBFooter } from "@components/hb-customs/hb-footer";
import { AdminAPITypes } from "@stellar/api-logic";
import { HBTooltip } from "@components/hb-customs/hb-tooltips";
import { useAppNavigation } from "@router/use-app-navigation";
import { ISubjectInfo, SubjectEntityTypes } from "@utils/utility-types";
import { CSVDataUtils } from "@utils/csv-data";
import { Counter } from "@components/counter";

interface IProjectInfoFooterProps {
  /** All the projects of a specific subjectId */
  projects?: AdminAPITypes.IAdmProject[];

  /** Type of the subject entity of project */
  subjectType: SubjectEntityTypes;

  /** Additional options */
  options?: {
    /** Hide create project button. Default is false */
    shouldHideCreateProjectButton?: boolean;
  };
}

/**
 * Show a footer related to the provided projects
 */
export function ProjectInfoFooter({
  projects = [],
  subjectType,
  options,
}: IProjectInfoFooterProps): JSX.Element {
  const rightProperties: JSX.Element[] = [
    <CSVExportButton
      key="CSVExportButton"
      onClick={() => CSVDataUtils.downloadProjectData(projects)}
      isDisabled={!projects.length}
    />,
  ];

  if (
    subjectType === AdminAPITypes.ESubjectType.user &&
    !options?.shouldHideCreateProjectButton
  ) {
    rightProperties.push(<CreateAccountProjectButton />);
  }

  return (
    <HBFooter
      leftProperty={
        projects && <Counter counter={projects.length} label="Project(s)" />
      }
      rightProperties={rightProperties}
    />
  );
}

interface IPlanInfoFooterProps extends ISubjectInfo {
  /** Number that will be rendered as number of plans */
  numberOfPlans: number;
}

/**
 * Show a footer related to the provided plans
 */
export function PlanInfoFooter({
  numberOfPlans,
  subjectId,
  subjectType,
}: IPlanInfoFooterProps): JSX.Element {
  const { navigateToPlanCreation } = useAppNavigation();

  const isUser = subjectType === AdminAPITypes.ESubjectType.user;
  const isDevice = subjectType === AdminAPITypes.ESubjectType.device;

  return (
    <HBFooter
      leftProperty={
        <Counter
          counter={numberOfPlans}
          label={isDevice ? "Hardware Licenses" : "Plan(s)"}
        />
      }
      rightProperties={
        <HBTooltip
          title={
            isUser
              ? "Plans can only be assigned to Dashboards, projects and hardware"
              : ""
          }
        >
          <AddButton
            isDisabled={isUser}
            onClick={() => navigateToPlanCreation(subjectType, subjectId)}
          >
            {isDevice ? "Add License" : "Add Plan"}
          </AddButton>
        </HBTooltip>
      }
    />
  );
}

interface IGroupInfoFooterProps extends ISubjectInfo {
  /** All the groups of a specific subjectId */
  groups: AdminAPITypes.ICompanyGroup[];
}

/**
 * Show a footer related to the list of groups
 */
export function GroupInfoFooter({
  groups = [],
}: IGroupInfoFooterProps): JSX.Element {
  return (
    <HBFooter
      leftProperty={
        groups && <Counter counter={groups.length} label="Group(s)" />
      }
      rightProperties={
        <CSVExportButton
          key="CSVExportButton"
          onClick={() => CSVDataUtils.downloadGroupData(groups)}
          isDisabled={!groups.length}
        />
      }
    />
  );
}
