import { createTheme } from "@mui/material/styles";
import { Color } from "@styles/default-colors";

/** The theme to be used. It defines default styles for components and the app in general */
export const hbTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          WebkitFontSmoothing: "auto",
        },

        body: {
          margin: "0px",
          fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            "sans-serif",
          ].join(","),
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
        },

        // eslint-disable-next-line @typescript-eslint/naming-convention -- naming on html class
        "#root": {
          display: "flex",
          flexDirection: "column",
        },

        // eslint-disable-next-line @typescript-eslint/naming-convention -- naming on html class and tag
        "body, #root": {
          height: "100vh",
        },

        main: {
          height: "100%",
        },

        code: {
          fontFamily: [
            "source-code-pro",
            "Menlo",
            "Monaco",
            "Consolas",
            "Courier New",
            "monospace",
          ].join(","),
        },
      },
    },

    MuiButton: {
      defaultProps: {
        // Remove elevation effect (i.e. backdrop shadow) from buttons
        // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: "normal",
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: Color.grey87,
          height: "60px",
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: Color.grey39,
          paddingLeft: "116px",

          "& button": {
            color: Color.white,
            textTransform: "capitalize",

            "&.Mui-selected": {
              backgroundColor: Color.white,
              color: Color.grey52,
            },
          },
        },
        indicator: {
          display: "none",
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          "&:first-of-type": {
            paddingLeft: "50px",
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        // Text and backgroundColor
        tooltip: {
          color: Color.grey46,
          backgroundColor: Color.grey01,
          maxWidth: "515px",
          fontSize: "1em",
          fontWeight: 400,
          boxShadow: `0px 0px 5px 0px ${Color.grey46}`,
        },
        // Arrow only
        arrow: {
          color: Color.grey01,
          "&:before": {
            boxShadow: `0px 0px 5px 0px ${Color.grey46}`,
          },
        },
      },
    },
  },

  // Adjustments of the material-ui palette to our own needs
  // More information: https://material-ui.com/customization/palette/
  palette: {
    background: {
      default: Color.white,
    },

    primary: {
      main: Color.grey76,
    },

    secondary: {
      main: Color.hbYellow,
    },

    text: {
      secondary: Color.black,
    },

    info: {
      main: Color.blue,
    },
  },
});
