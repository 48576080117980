import { AdminAPITypes } from "@stellar/api-logic";
import { isCompanyContext } from "@utils/type-guards";
import {
  formatPlanName,
  getAccountDisplayName,
  getPlanAssigneeName,
} from "@utils/data-display";
import { Constraint } from "@utils/constraint";
import { PlanDataExtractor } from "@utils/plan-utils/plan-data-extractor";
import { Convert } from "@stellar/web-core";
import { DateTimeUtils } from "@stellar/web-core";

// Function that compares two values and returns a comparison result according to the Array.sort() spec.
// Link to spec: https://www.ecma-international.org/ecma-262/5.1/#sec-15.4.4.11
export type ComparatorFn<T> = (a: T, b: T) => number;

/**
 * Module containing comparison functions useful for sorting custom data structures.
 */
export const Compare = {
  /**
   * Helper function to create a comparator retrieving the values from an object key before comparing them.
   */
  byObjectKey<Key extends string, V, T extends { [key in Key]: V }>(
    key: Key,
    compareFunction: ComparatorFn<V>
  ): ComparatorFn<T> {
    return (a: T, b: T): number => {
      return compareFunction(a[key], b[key]);
    };
  },

  /**
   * Helper functions to create a compareFunction that sorts by multiple criteria.
   * The order of arguments is the order of sorting priority.
   */
  chain<T>(...compareFunctions: ComparatorFn<T>[]): ComparatorFn<T> {
    return (a: T, b: T) => {
      let result = 0;

      for (const compareFunction of compareFunctions) {
        result = compareFunction(a, b);

        // Only continue with the next compare function, if the current didn't come to a conclusive result.
        if (result !== 0) {
          break;
        }
      }

      return result;
    };
  },

  /**
   * Helper function create a compare function that compares optional values.
   * Sorts all undefined values to the bottom.
   */
  optionalValues<T>(
    compareFunction: ComparatorFn<T>
  ): ComparatorFn<T | undefined | null> {
    return (a: T | undefined | null, b: T | undefined | null) => {
      if (isDefined(a) && isDefined(b)) {
        return compareFunction(a, b);
      } else if (isDefined(a)) {
        return 1;
      } else if (isDefined(b)) {
        return -1;
      }
      return 0;
    };
  },

  /**
   * Default comparator that works reasonably for any kind of data.
   * Falls back to a js comparison.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- no better way to make this comparison generic
  defaultComparison(a: any, b: any): number {
    if (typeof a === "string" && typeof b === "string") {
      return Compare.stringsCaseInsensitive(a, b);
    }

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  },

  /**
   * Compares strings by their alphabetical order.
   */
  stringsCaseInsensitive(a: string, b: string): number {
    if (a.toUpperCase() < b.toUpperCase()) {
      return -1;
    } else if (a.toUpperCase() > b.toUpperCase()) {
      return 1;
    }

    return 0;
  },

  /**
   * Parse the input (strings or number) as date and compare the date
   */
  parametersAsDate(a: number | string, b: number | string): number {
    return DateTimeUtils.diffBetweenTwoDatesInDays(a, b);
  },

  /**
   * Compares two projects by their companies name.
   * Projects without a company will always be sorted below projects with a company.
   */
  projectsByCompany(
    projectA: AdminAPITypes.IAdmProject,
    projectB: AdminAPITypes.IAdmProject
  ): number {
    const companyNameA = isCompanyContext(projectA.context)
      ? projectA.context.company.name
      : "";
    const companyNameB = isCompanyContext(projectB.context)
      ? projectB.context.company.name
      : "";

    return Compare.stringsCaseInsensitive(companyNameA, companyNameB);
  },

  /**
   * Compares two projects by the display name of their projectManagers.
   */
  projectsByProjectManager(
    projectA: AdminAPITypes.IAdmProject,
    projectB: AdminAPITypes.IAdmProject
  ): number {
    return Compare.stringsCaseInsensitive(
      getAccountDisplayName(projectA.projectManager),
      getAccountDisplayName(projectB.projectManager)
    );
  },

  /**
   * Compares two projects by the name of their group.
   */
  projectsByGroup(
    projectA: AdminAPITypes.IAdmProject,
    projectB: AdminAPITypes.IAdmProject
  ): number {
    const groupNameA = isCompanyContext(projectA.context)
      ? projectA.context.group.name
      : "";

    const groupNameB = isCompanyContext(projectB.context)
      ? projectB.context.group.name
      : "";

    return Compare.stringsCaseInsensitive(groupNameA, groupNameB);
  },

  /**
   * Compares two projects by their area size.
   */
  projectsByArea(
    projectA: AdminAPITypes.IAdmProject,
    projectB: AdminAPITypes.IAdmProject
  ): number {
    const sqftA = getProjectAreaInSqft(projectA);
    const sqftB = getProjectAreaInSqft(projectB);

    return Compare.defaultComparison(sqftA, sqftB);
  },

  /**
   * Compares users by their name
   */
  usersByName(
    userA: AdminAPITypes.IAdmUser,
    userB: AdminAPITypes.IAdmUser
  ): number {
    return Compare.stringsCaseInsensitive(
      getAccountDisplayName(userA),
      getAccountDisplayName(userB)
    );
  },

  /**
   * Compares users by the name of their first company.
   */
  usersByCompany(
    userA: AdminAPITypes.IAdmUser,
    userB: AdminAPITypes.IAdmUser
  ): number {
    const companyNameA = userA.companies[0] ? userA.companies[0].name : "";
    const companyNameB = userB.companies[0] ? userB.companies[0].name : "";

    return Compare.stringsCaseInsensitive(companyNameA, companyNameB);
  },

  /**
   * Compares plans by their display name.
   */
  plansByName(planA: AdminAPITypes.IPlan, planB: AdminAPITypes.IPlan): number {
    return Compare.stringsCaseInsensitive(
      formatPlanName(planA),
      formatPlanName(planB)
    );
  },

  /**
   * Compares plans by their first constraint.
   * Sorts plans without constraints to the bottom.
   */
  plansByConstraints(
    planA: AdminAPITypes.IPlan,
    planB: AdminAPITypes.IPlan
  ): number {
    const firstConstraintA = planA.constraints[0];
    const firstConstraintB = planB.constraints[0];

    const compareFunction = Compare.optionalValues(
      Compare.chain(Compare.constraintsByType, Compare.constraintsByAmount)
    );

    return compareFunction(firstConstraintA, firstConstraintB);
  },

  /**
   * Compares plans by their subject type.
   */
  plansBySubject(
    planA: AdminAPITypes.IPlan,
    planB: AdminAPITypes.IPlan
  ): number {
    return Compare.defaultComparison(planA.subject.type, planB.subject.type);
  },

  /**
   * Compares plans first by their assignee name.
   */
  plansByAssignee(
    planA: AdminAPITypes.IPlan,
    planB: AdminAPITypes.IPlan
  ): number {
    return Compare.stringsCaseInsensitive(
      getPlanAssigneeName(planA),
      getPlanAssigneeName(planB)
    );
  },

  // TODO: Replace the functions using diffBetweenTwoDatesInDays with parametersAsDate function
  /**
   * Compares plans first by their created date.
   */
  plansByCreatedDate(
    planA: AdminAPITypes.IPlan,
    planB: AdminAPITypes.IPlan
  ): number {
    return DateTimeUtils.diffBetweenTwoDatesInDays(
      planA.createdAt,
      planB.createdAt
    );
  },

  // TODO: Can be replaced by parametersAsDate function
  /**
   * Compares plans first by their start date.
   */
  plansByStartDate(
    planA: AdminAPITypes.IPlan,
    planB: AdminAPITypes.IPlan
  ): number {
    return DateTimeUtils.diffBetweenTwoDatesInDays(
      planA.startDate,
      planB.startDate
    );
  },

  // TODO: Can be replaced by parametersAsDate function
  /**
   * Compares plans first by their end date.
   * Plans without an end date will be sorted to the bottom.
   */
  plansByEndDate(
    planA: AdminAPITypes.IPlan,
    planB: AdminAPITypes.IPlan
  ): number {
    const compareFunction = Compare.optionalValues(
      DateTimeUtils.diffBetweenTwoDatesInDays
    );

    return compareFunction(
      PlanDataExtractor.getEndDate(planA),
      PlanDataExtractor.getEndDate(planB)
    );
  },

  /**
   * Compares two plans by their status.
   */
  plansByStatus(
    planA: AdminAPITypes.IPlan,
    planB: AdminAPITypes.IPlan
  ): number {
    const statusOrder: AdminAPITypes.PlanStatusIdentifier[] = [
      "invalid",
      "active",
      "inactive",
      "deactivated",
    ];

    const statusOrderIndexA = statusOrder.indexOf(planA.status.identifier);
    const statusOrderIndexB = statusOrder.indexOf(planB.status.identifier);

    return Compare.defaultComparison(statusOrderIndexA, statusOrderIndexB);
  },

  /**
   * Compares two constraints by their type.
   */
  constraintsByType(
    constraintA: AdminAPITypes.Constraint,
    constraintB: AdminAPITypes.Constraint
  ): number {
    const constraintOrder = [
      AdminAPITypes.EConstraintType.userCount,
      AdminAPITypes.EConstraintType.projectCount,
      AdminAPITypes.EConstraintType.projectArea,
      AdminAPITypes.EConstraintType.waypoint,
      AdminAPITypes.EConstraintType.timetravelOnWaypoint,
    ];

    const constraintOrderIndexA = constraintOrder.indexOf(constraintA.type);
    const constraintOrderIndexB = constraintOrder.indexOf(constraintB.type);

    return Compare.defaultComparison(
      constraintOrderIndexA,
      constraintOrderIndexB
    );
  },

  /**
   * Compares two constraints by their amount.
   */
  constraintsByAmount(
    constraintA: AdminAPITypes.Constraint,
    constraintB: AdminAPITypes.Constraint
  ): number {
    const constraintAmountA = Constraint.getMaxValueOfConstraint(constraintA);
    const constraintAmountB = Constraint.getMaxValueOfConstraint(constraintB);

    return Compare.defaultComparison(constraintAmountA, constraintAmountB);
  },
};

/**
 * Typeguard to check whether a generic value is neither null or undefined.
 */
function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

/**
 * Returns a projects area in sqft.
 */
function getProjectAreaInSqft(project: AdminAPITypes.IAdmProject): number {
  switch (project.area.unit) {
    case AdminAPITypes.EAreaUnit.sqm:
      return Convert.sqMeterToSqFoot(project.area.amount);
    case AdminAPITypes.EAreaUnit.sqft:
      return project.area.amount;
  }
}
