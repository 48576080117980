import { AdminAPITypes } from "@stellar/api-logic";
import { DateTimeUtils } from "@stellar/web-core";
import { BaseProduct } from "@store/plan-creation/plan-creation-slice-helper-types";
import { formatConstraintType } from "@utils/data-display";

interface ValidatePlanCreationProps {
  /** Type of the plan */
  planSubjectType: AdminAPITypes.ESubjectType;

  /** The selected feature or bundle to add to plan */
  selectedProduct: BaseProduct | null;

  /** Comments provided by the user */
  comments: string;

  /** A boolean flag that indicates whether the plan should have limits or not */
  shouldProductHaveLimits: boolean;

  /** List of active constraints*/
  activeConstraints: AdminAPITypes.CreateConstraintPayload[];

  /** Start date of the plan */
  startDate: number;

  /** End date of the plan */
  endDate: number;
}

/** This function validates the plan creation data and returns an error message or null if valid */
export function validatePlanCreation({
  planSubjectType,
  selectedProduct,
  comments,
  shouldProductHaveLimits,
  activeConstraints,
  startDate,
  endDate,
}: ValidatePlanCreationProps): string | null {
  // A feature must be selected
  if (selectedProduct === null) {
    return planSubjectType === AdminAPITypes.ESubjectType.device
      ? "Please select a license type"
      : "Please select a product";
  }
  // Adding a comment for all type of plans is mandatory
  if (!comments) {
    return "Please add a comment";
  }

  if (!DateTimeUtils.isValidDate(startDate)) {
    return "Please select a valid start date";
  }

  if (!DateTimeUtils.isValidDate(endDate)) {
    return "Please select a valid end date";
  }

  if (
    startDate &&
    endDate &&
    DateTimeUtils.diffBetweenTwoDatesInDays(startDate, endDate) > 0
  ) {
    return "Please select an end date that comes after the start date";
  }

  if (shouldProductHaveLimits && activeConstraints.length === 0) {
    return "Please add at least one valid limit";
  }

  for (const constraint of activeConstraints) {
    if (constraint.data.max <= 0) {
      return `Please enter a valid ${formatConstraintType(
        constraint.type
      )} constraint`;
    }

    // Ensure that at least one user role is selected when the constraint type is 'userRole'
    if (constraint.type === AdminAPITypes.EConstraintType.userRole) {
      const roles = constraint.data.roles;
      const isCompanyRolesSelected = roles?.company && roles.company.length > 0;
      const isResourceRolesSelected =
        roles?.resource && roles.resource.length > 0;

      if (!isCompanyRolesSelected && !isResourceRolesSelected) {
        return "Please select at least one user role for the constraint.";
      }
    }
  }

  return null;
}
