import { CoreApi } from "@api/index";
import { ErrorHandlingFunction } from "@components/error-boundary/error-handling-context-types";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { ICompanyProjectCreation } from "@pages/create-project/project-creation-context-types";
import { useState } from "react";

interface IRequestCreateProjectParams {
  /** Metadata to create the project with */
  createProjectPayload: AdminAPITypes.CreateProjectPayload;

  /** The company in which the project is going to be created for */
  subject: ICompanyProjectCreation;
}

interface IUseCreateProjectParams {
  /** Function which will be called to handle errors */
  errorHandler: ErrorHandlingFunction;
}

interface IUseCreateProjectReturn {
  /** Indicator wether there is currently a project creation request in process */
  isRequestingProjectCreation: boolean;

  /** Request the project creation on the backend with the provided parameters */
  requestCreateProject(params: IRequestCreateProjectParams): Promise<void>;

  /** ID of the newly created project */
  createdProjectId: APITypes.ProjectId | undefined;
}

/** Hook that should be used to create a project for companies. */
export function useCreateProject({
  errorHandler,
}: IUseCreateProjectParams): IUseCreateProjectReturn {
  const [isRequestingProjectCreation, setIsRequestingProjectCreation] =
    useState(false);

  const [createdProjectId, setCreatedProjectId] =
    useState<APITypes.ProjectId>();

  async function requestCreateProject({
    createProjectPayload,
    subject,
  }: IRequestCreateProjectParams): Promise<void> {
    setIsRequestingProjectCreation(true);

    try {
      const createdProject: AdminAPITypes.IAdmProject =
        await CoreApi.V0.ADMIN.createEmptyProjectWithinCompanyGroup(
          createProjectPayload,
          subject.company.id,
          subject.group.id
        );
      setCreatedProjectId(createdProject.id);
    } catch (error) {
      errorHandler("Could not create project", error);
    }

    setIsRequestingProjectCreation(false);
  }

  return {
    isRequestingProjectCreation,
    requestCreateProject,
    createdProjectId,
  };
}
