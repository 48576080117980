import { Grid2, Grid2Props } from "@mui/material";
import { Color } from "@styles/default-colors";
import { ILabelInfoPair } from "@components/info-grid/info-row-types";

interface IInfoRowProps extends ILabelInfoPair {
  /** The alignment of the text */
  labelTextAlign?: "left" | "right";

  /** Whether to show a colon between label and info or not */
  shouldShowColon?: boolean;

  /** Optional sx properties to override the InfoRow main style. */
  sx?: Grid2Props["sx"];
}

/** Displays a pair for label/info in a flex row */
export function InfoRow({
  label,
  info,
  labelTextAlign = "right",
  shouldShowColon,
  sx = {},
}: IInfoRowProps): JSX.Element {
  return (
    <Grid2 container sx={{ padding: "3px 0px", ...sx }}>
      <Grid2
        sx={{
          minWidth: "40%",
          textAlign: labelTextAlign,
          paddingRight: "10px",
          color: "rgb(137, 137, 137, 0.5)",
          alignSelf: "center",
        }}
      >
        {label}
        {shouldShowColon ? ":" : ""}
      </Grid2>
      <Grid2
        sx={{
          color: Color.grey46,
          overflowWrap: "anywhere",
          whiteSpace: "pre",
          maxHeight: "200px",
          overflowY: "auto",
          flexGrow: 1,
        }}
      >
        {info}
      </Grid2>
    </Grid2>
  );
}
