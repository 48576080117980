import { Box } from "@mui/material";
import { DateTimeUtils } from "@stellar/web-core";
import { DASH } from "@utils/data-display";
import { isInTheLast30Days, isInTheNext30Days } from "@utils/date-time";
import WarningIcon from "@mui/icons-material/Warning";

interface Props {
  /** The end date for the plan */
  endDate?: DateTimeUtils.DateTimeInput;
}

/**
 * Renders a column displaying the end date of a plan, with conditional visual indicators.
 * This component displays the end date of a plan, formatted according to the `DateTimeUtils` utility.
 * If the `endDate` is not provided, a "—" is shown. If the `endDate` is within
 * the last 30 days or is today, a warning icon is displayed to indicate that the plan has recently expired
 * or is expiring soon. The icon's color and tooltip vary depending on the expiration status.
 */
export function EndDateColumn({ endDate }: Props): JSX.Element {
  if (!endDate) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{DASH}</>;
  }
  return (
    <>
      {DateTimeUtils.format({ date: endDate })}
      {isInTheLast30Days(endDate) && (
        <Box
          component="span"
          title="Plan expired less than a month ago"
          sx={{ marginLeft: "10px" }}
        >
          <WarningIcon
            sx={{ verticalAlign: "text-bottom" }}
            color="error"
            fontSize="small"
          />
        </Box>
      )}
      {(isInTheNext30Days(endDate) || DateTimeUtils.isToday(endDate)) && (
        <Box
          component="span"
          title="Plan will expire in less than a month"
          sx={{ marginLeft: "10px" }}
        >
          <WarningIcon
            sx={{ verticalAlign: "text-bottom" }}
            color="secondary"
            fontSize="small"
          />
        </Box>
      )}
    </>
  );
}
