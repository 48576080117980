import { useErrorHandler } from "@components/error-boundary/error-handling-context";
import { useAccount } from "@hooks/use-account";
import { APITypes } from "@stellar/api-logic";

// Although this component is only used in plan-info-grid.ts, it is kept as a separate file.
// The reason is that there is a mock for this file which removes the necessity of network request.
// Moving this component will make related tests to fail.
// TODO: Move this file into plan-info-grid.ts and adjust the tests and network mocking so that the tests are passed

/** Props for the AccountEmail component */
interface IProps {
  /** The accountId to display the email for */
  accountId: APITypes.UserId;
}

/** Displays the account email for an accountId. */
export function AccountEmail({ accountId }: IProps): JSX.Element {
  const { handleErrorWithSnackbar } = useErrorHandler();

  const [account, isFetchingAccount] = useAccount(
    accountId,
    handleErrorWithSnackbar
  );

  if (isFetchingAccount) {
    return <i>Loading...</i>;
  } else if (!account) {
    return <i>Invalid User</i>;
  }

  return <span>{account.email}</span>;
}
