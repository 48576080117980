import { CoreApi } from "@api/index";
import { ErrorHandlingFunction } from "@components/error-boundary/error-handling-context-types";
import { AdminAPITypes } from "@stellar/api-logic";
import { PlanDataExtractor } from "@utils/plan-utils/plan-data-extractor";
import { removeCurrentFieldFromConstraints } from "@utils/constraint";

// TODO: Replace deactivated with status after the implementation of https://bitstars.atlassian.net/browse/BACK-4215.
/** The properties of plan that we know they can be updated by now */
type UpdatePlanKey = keyof Pick<
  AdminAPITypes.UpdatePlanPayload,
  "description" | "deactivated"
>;

/** Type of updated plan field with key-val pair of plan */
type UpdatePlanField<T extends UpdatePlanKey> = Record<
  T,
  AdminAPITypes.UpdatePlanPayload[T]
>;

interface IUseUpdatePlan {
  /** Use this to update any key-val pair inside a plan */
  updatePlan<T extends UpdatePlanKey>(
    plan: AdminAPITypes.IPlan,
    updatedField: UpdatePlanField<T>
  ): Promise<AdminAPITypes.IPlan | undefined>;
}

/**
 * A custom hook that wraps different functions to update plan
 * Returns functions that can be called to update or deactivate plan
 */
export function useUpdatePlan(
  errorHandler: ErrorHandlingFunction
): IUseUpdatePlan {
  /**
   * Calling an api to update the plan
   */
  async function apiCall(
    plan: AdminAPITypes.IPlan,
    params: AdminAPITypes.UpdatePlanPayload
  ): Promise<AdminAPITypes.IPlan> {
    // Remove 'current' field from each constraint in params.constraints
    params.constraints = removeCurrentFieldFromConstraints(params.constraints);
    return await CoreApi.V0.ADMIN.updatePlan(plan.id, params);
  }

  /**
   * Update a plan by the updatedField
   *
   * @param updatedField The key-val pair of plan that is changed
   */
  async function updatePlan<T extends UpdatePlanKey>(
    plan: AdminAPITypes.IPlan,
    updatedField: UpdatePlanField<T>
  ): Promise<AdminAPITypes.IPlan | undefined> {
    try {
      return await apiCall(plan, {
        ...PlanDataExtractor.Payload.extractUpdatePlanPayloadFromPlan(plan),
        ...updatedField,
      });
    } catch (error) {
      errorHandler("Error while updating the plan", error);
    }
  }

  return { updatePlan };
}
