import { AdminAPITypes } from "@stellar/api-logic";
import { DateTimeUtils } from "@stellar/web-core";
import {
  BaseProduct,
  CustomProductIdentification,
} from "@store/plan-creation/plan-creation-slice-helper-types";
import {
  isProductBundleFeature,
  isProductSingleFeature,
} from "@utils/product/product-utils";
import { customProductIdToBundleIdentification } from "@utils/product/product-utils";
import { removeCurrentFieldFromConstraints } from "@utils/constraint";

interface Props {
  /** The ID of the subject that the plan is going to be associated to */
  planSubjectId: string;

  /** The type of the subject that the plan is going to be associated to */
  planSubjectType: AdminAPITypes.ESubjectType;

  /** The selected feature or bundle to add to plan */
  selectedProduct: BaseProduct | null;

  /** Active constraints for the plan that is going to be created */
  activeConstraints: AdminAPITypes.CreateConstraintPayload[];

  /** Start date of the plan */
  startDate: number;

  /** End date of the plan */
  endDate: number;

  /** Comments for the plan that is going to be created */
  comments: string;
}

/** Convert the PlanCreationContext into an AdminAPITypes.CreatePlanPayload object */
export function createPlanCreationPayload({
  planSubjectId,
  planSubjectType,
  selectedProduct,
  activeConstraints,
  startDate,
  endDate,
  comments,
}: Props): AdminAPITypes.CreatePlanPayload {
  if (!startDate) {
    throw new Error(
      "createPlanCreationPayload: startDate must be set for unpaid plans"
    );
  }
  if (!endDate) {
    throw new Error(
      "createPlanCreationPayload: endDate must be set for unpaid plans"
    );
  }

  const payment: AdminAPITypes.IUnpaidPaymentInformation = {
    unpaid: {
      startDate: DateTimeUtils.getStartOfDay({
        date: startDate,
        shouldUseUtc: true,
      }).toISOString(),

      endDate: DateTimeUtils.getEndOfDay({
        date: endDate,
        shouldUseUtc: true,
      }).toISOString(),
    },
  };

  // Remove 'current' field from each constraint in activeConstraints
  const cleanedActiveConstraints =
    removeCurrentFieldFromConstraints(activeConstraints);
  // TODO: Think about moving this feature logic somewhere else
  // because createPlanCreationPayload should not be concerned with figuring out
  // if the selected "product" is a feature or a bundle
  const selectedFeatureBundleIdentifiers: string[] = [];
  const selectedFeatureIdentifiers: string[] = [];

  if (selectedProduct) {
    if (isProductSingleFeature(selectedProduct)) {
      selectedFeatureIdentifiers.push(selectedProduct.identifier);
    } else if (isProductBundleFeature(selectedProduct)) {
      selectedFeatureBundleIdentifiers.push(selectedProduct.identifier);
    } else {
      const allSelectedIdentifications =
        customProductIdToBundleIdentification[
          selectedProduct.identifier as CustomProductIdentification
        ];
      selectedFeatureBundleIdentifiers.push(...allSelectedIdentifications);
    }
  }

  return {
    subject: {
      type: planSubjectType,
      id:
        // There are two methods for creating device licenses:
        // one where admins provide a specific serial number, and another for any device without a serial number,
        // which uses auto-binding later.For these subscriptions, a special string '??-' is used as the serial number;
        // creating the subscription with subject ID '??-'.
        planSubjectType === AdminAPITypes.ESubjectType.device
          ? "??-"
          : planSubjectId,
    },

    constraints: cleanedActiveConstraints,
    featureBundles: selectedFeatureBundleIdentifiers,
    features: selectedFeatureIdentifiers,
    label: selectedProduct?.name ?? "",
    description: comments,
    payment,
  };
}
