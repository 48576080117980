import { AdminAPITypes } from "@stellar/api-logic";
import { DateTimeUtils } from "@stellar/web-core";
import { BaseProduct } from "@store/plan-creation/plan-creation-slice-helper-types";
import { isProductSingleFeature } from "@utils/product/product-utils";

interface IProps {
  /** The ID of the subject that the plan is going to be associated to */
  planSubjectId: string;

  /** The type of the subject that the plan is going to be associated to */
  planSubjectType: AdminAPITypes.ESubjectType;

  /** The selected feature or bundle to add to plan */
  selectedProduct: BaseProduct;

  /** Active constraints for the plan that is going to be created */
  activeConstraints: AdminAPITypes.CreateConstraintPayload[];

  /** Start date of the plan */
  startDate: number;

  /** End date of the plan */
  endDate: number;

  /** Comments for the plan that is going to be created */
  comments: string;

  /** Whether the plan is infinite or not */
  isInfinite?: boolean;
}

/**
 * Convert PlanCreationContext into an AdminAPITypes.CreatePlanPayload object with the custom selected feature or bundle
 */
export function createCustomPlanCreationPayload({
  planSubjectId,
  planSubjectType,
  selectedProduct,
  activeConstraints,
  startDate,
  endDate,
  comments,
  isInfinite = false,
}: IProps): AdminAPITypes.CreatePlanPayload {
  const payment: AdminAPITypes.IUnpaidPaymentInformation = {
    unpaid: {
      startDate: DateTimeUtils.getStartOfDay({
        date: new Date(startDate),
        shouldUseUtc: true,
      }).toISOString(),

      endDate: isInfinite
        ? "2099-01-01T00:00:00Z"
        : DateTimeUtils.getEndOfDay({
            date: new Date(endDate),
            shouldUseUtc: true,
          }).toISOString(),
    },
  };

  return {
    subject: {
      type: planSubjectType,
      id: planSubjectId,
    },
    constraints: activeConstraints,
    featureBundles: isProductSingleFeature(selectedProduct)
      ? []
      : [selectedProduct.identifier],
    features: isProductSingleFeature(selectedProduct)
      ? [selectedProduct.identifier]
      : [],
    // As we will simply use the feature name for displaying the plan, value of label does not matter
    label: selectedProduct?.name ?? "",

    description: comments,

    payment,
  };
}
