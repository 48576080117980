import { runtimeConfigObject } from "@src/app-config";

declare global {
  interface Window {
    /** Our build/server will make sure to inject this runtimeConfig */
    appConfig?: RuntimeConfig;
  }
}

export type RuntimeConfig = {
  readonly urls: {
    readonly baseUrl: string;
    readonly apiUrl: string;
    readonly webEditorUrl: string;
    readonly dashboardUrl: string;
    readonly entryPageUrl: string;
    readonly adminPanelApi: string;
  };
  readonly deployment: {
    readonly topLevelDomain: string;
    readonly environment: string;
  };
  readonly keys: {
    readonly sentryDsn: string;
  };
  readonly appEnv: string;
  readonly appVersion: string;
  readonly appTimestamp: string;
  readonly commitId: string;
  readonly commitUrl: string;
  readonly pullRequestId: string;
  readonly pullRequestUrl: string;
  readonly jiraTicketId: string;
  readonly jiraTicketUrl: string;
  readonly mailSupportAddress: string;
  /** Flag whether the current domain is running on a numbered environment */
  readonly isNumberedEnv: boolean;
  /** The numbered environment if the current domain is running on a numbered environment */
  readonly numberedEnv: string | null;
};

class RuntimeConfigReader {
  static getRuntimeEnv(hostUrl: string): string {
    try {
      const matches = hostUrl.match(
        /.*dev\d\.admin\.(staging|dev)\.holobuilder.(eu|com)/
      );
      if (!matches) {
        if (hostUrl === "localhost") {
          for (const key in runtimeConfigObject) {
            if (
              runtimeConfigObject[key].appEnv === import.meta.env.VITE_APP_ENV
            ) {
              return `${key}Local`;
            }
          }
        }
        if (hostUrl === "admin.holobuilder.eu") {
          return "prodEu";
        }
        if (hostUrl === "admin.holobuilder.com") {
          return "prodCom";
        }
        if (hostUrl === "test.admin.holobuilder.eu") {
          return "prodEu";
        }
        if (hostUrl === "test.admin.holobuilder.com") {
          return "prodCom";
        }
        if (hostUrl.endsWith("admin.dev.holobuilder.com")) {
          return "developmentCom";
        }
        if (hostUrl.endsWith("admin.staging.holobuilder.com")) {
          return "stagingCom";
        }
        if (hostUrl.endsWith("admin.dev.holobuilder.eu")) {
          return "developmentEu";
        }
        if (hostUrl.endsWith("admin.staging.holobuilder.eu")) {
          return "stagingEu";
        }
        // Default case if no specific conditions are met
        return "developmentCom";
      }

      const env =
        matches[1] && matches[1] === "dev" ? "development" : "staging";
      const region = matches[2] === "com" ? "Com" : "Eu";
      return `${env}${region}`;
    } catch (err) {
      throw new Error(`Failed to open env file for dev environment ${hostUrl}`);
    }
  }
  static getRuntimeConfig(): RuntimeConfig {
    /* eslint-disable no-restricted-globals */
    const hostUrl = location.hostname;
    /* eslint-enable no-restricted-globals */
    const runtimeEnv = this.getRuntimeEnv(hostUrl);
    return runtimeConfigObject[runtimeEnv];
  }
}

window.appConfig = RuntimeConfigReader.getRuntimeConfig();

// If the runtimeConfig is missing something is broken in the dev server or prod server
if (!window.appConfig) {
  throw Error("Runtime config missing");
}

const runtimeConfig = window.appConfig;
// make it immutable
Object.freeze(runtimeConfig);

export { runtimeConfig };
