import { AdminAPITypes } from "@stellar/api-logic";
import { createContext, useContext } from "react";

export const ProjectContext = createContext<AdminAPITypes.IAdmProject | null>(
  null
);

export function useProjectContext(): AdminAPITypes.IAdmProject {
  const context = useContext(ProjectContext);

  if (!context) {
    throw new Error(
      "useProjectContext() has to be used within a ProjectContext."
    );
  }

  return context;
}
