import { useErrorHandler } from "@components/error-boundary/error-handling-context";
import { ContentLayout } from "@pages/content-layout";
import { CompanyBreadcrumbs } from "@components/breadcrumbs/company-breadcrumbs";
import { CompanyProfileHeader } from "@pages/company-profile//company-profile-header";
import { useCompany } from "@hooks/use-company";
import { CompanyContext } from "@pages/company-profile//company-context";
import { CompanyInfo } from "@pages/company-profile//tab-contents/company-info";
import { PlanInfo } from "@pages/plan-info";
import { AdminAPITypes } from "@stellar/api-logic";
import { ProjectsInfo } from "@pages/projects-info";
import { LoadingScreen } from "@components/loading-screen";
import { GroupsInfo } from "@pages/company-profile/tab-contents/groups-info";
import { useAppParams } from "@router/router-helper";

/**
 * Fetch company data and display the data in company profile page
 */
export function CompanyProfilePage(): JSX.Element {
  const { companyId } = useAppParams();

  const { handleErrorWithPage } = useErrorHandler();

  const [company, isFetchingData] = useCompany(companyId, handleErrorWithPage);

  return (
    <LoadingScreen isFetching={isFetchingData} data={company}>
      {company && (
        <CompanyContext.Provider value={company}>
          <ContentLayout
            breadcrumbs={<CompanyBreadcrumbs company={company} />}
            header={<CompanyProfileHeader />}
            tabs={[
              {
                label: "Company Information",
                content: <CompanyInfo />,
              },

              {
                label: "Groups",
                content: <GroupsInfo companyId={company.id} />,
              },

              {
                label: "Projects",
                content: <ProjectsInfo ownerId={company.id} />,
              },

              {
                label: "Plans",
                content: (
                  <PlanInfo
                    subjectType={AdminAPITypes.ESubjectType.company}
                    subjectId={company.id}
                  />
                ),
              },
              {
                label: "Hardware Licenses",
                content: (
                  <PlanInfo
                    subjectType={AdminAPITypes.ESubjectType.device}
                    subjectId={company.id}
                  />
                ),
              },
            ]}
            defaultTabIndex={2}
          />
        </CompanyContext.Provider>
      )}
    </LoadingScreen>
  );
}
