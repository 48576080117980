import { AppBar, Avatar, Grid2, Toolbar } from "@mui/material";
import { Color } from "@styles/default-colors";
import { HBLogoIcon } from "@components/icons";
import { UserMailAddress } from "@components/user-mail-address";
import { HBTooltip } from "@components/hb-customs/hb-tooltips";
import { HBEuropeFlagIcon } from "@components/icons";
import { runtimeConfig } from "@src/runtime-config";
import { WorkspaceLink } from "@components/links/external-links";
import { HomeLink } from "@components/links/internal-links";
import { HBButton } from "@components/hb-customs/hb-button";
import { AboutTooltipInfo } from "@components/about-info-tooltip";

interface INavBarProps {
  /** Image URL of user's profile */
  profileImageUrl: string;
}

export function NavBar({ profileImageUrl }: INavBarProps): JSX.Element {
  return (
    <AppBar position="static" elevation={0}>
      <Toolbar
        sx={{
          // set this to 60px as it is set to 64px per default for big devices
          // and that would cause problems with the layout (because of root-height)
          minHeight: "60px",
        }}
      >
        <Grid2
          container
          sx={{
            "& a": {
              textDecoration: "none",
            },
            "& Button": {
              color: Color.white,
              textDecoration: "none",
              textTransform: "capitalize",
              height: "42px",
              width: "42px",
            },
            width: "100%",
          }}
          justifyContent="space-around"
          alignContent="center"
        >
          <Grid2 size={6} container alignItems="center" spacing={2}>
            <Grid2>
              <HBTooltip title={<AboutTooltipInfo />}>
                <HBLogoIcon />
              </HBTooltip>
            </Grid2>

            <Grid2>
              <HomeLink>
                <HBButton type="text">Home</HBButton>
              </HomeLink>
            </Grid2>
          </Grid2>

          <Grid2
            size={6}
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid2>
              <UserMailAddress />
            </Grid2>

            <Grid2>
              <WorkspaceLink>
                <Avatar src={profileImageUrl} />
              </WorkspaceLink>
            </Grid2>

            <Grid2>
              <DeploymentInfo />
            </Grid2>
          </Grid2>
        </Grid2>
      </Toolbar>
    </AppBar>
  );
}

function DeploymentInfo(): JSX.Element {
  return (
    <Grid2 container alignItems="center">
      {formatDeployment(runtimeConfig.deployment.environment)}

      <Grid2 sx={{ ml: "5px" }}>
        <DeploymentRegion region={runtimeConfig.deployment.topLevelDomain} />
      </Grid2>
    </Grid2>
  );
}

/**
 * Format a string containing an environment (production, staging, development)
 * For production, we just do not print the environment
 */
function formatDeployment(environment: string): string {
  switch (environment) {
    case "production":
      return "";

    case "development":
      return "dev";

    default:
      return `${environment}`;
  }
}

interface IDeploymentRegionProps {
  /** Region of the current deployment (i.e. "eu" or "com") */
  region: string;
}

function DeploymentRegion({ region }: IDeploymentRegionProps): JSX.Element {
  switch (region) {
    case "eu":
      return <HBEuropeFlagIcon fontSize="large" />;

    default:
      return <>{region.toUpperCase()}</>;
  }
}
