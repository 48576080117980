import { ReactNode } from "react";
import { MenuItem } from "@mui/material";

/** Describes a menu item */
interface IMenuItemInput {
  /** Unique value that identifies the menu item. */
  value: string | number;

  /** React Node that is displayed for this menu item. */
  label: ReactNode;

  /** Disables selection of the menu item. */
  isDisabled?: boolean;
}

interface IHBMenuItemsProps {
  menuItemInputs: IMenuItemInput[];
}

/**
 * Generates one menu item for every member of the given menuItemInput
 * Directly returns a MenuItem array due to MuiSelects not working properly with Function Component children.
 */
export function HBMenuItems({
  menuItemInputs,
}: IHBMenuItemsProps): JSX.Element[] {
  return menuItemInputs.map(({ value, label, isDisabled }) => {
    return (
      <MenuItem key={value} value={value} disabled={isDisabled}>
        {label}
      </MenuItem>
    );
  });
}

/** Supported Enum Values for mapping into menu items */
type PossibleEnumValue = string | number;

/** Helper type to get the enum-object type for an an EnumValue type */
type GenericEnumType<EnumValue extends PossibleEnumValue> = Record<
  string,
  EnumValue
>;

/** Helper type describing a mapping function */
type MapFunction<Source, Target> = (enumValue: Source) => Target;

/** Props for the HBMenuItems component */
interface IHBEnumMenuItemsProps<
  EnumValue extends PossibleEnumValue,
  EnumType extends GenericEnumType<EnumValue>
> {
  /** The enum providing the values for selection */
  fromEnum: EnumType;

  /** Function for mapping an enum value to a display string (defaults to stringified value) */
  formatLabelWith?: MapFunction<EnumValue, ReactNode>;

  /** Array of values to be disabled */
  disabledValues?: EnumValue[];
}

/**
 * Renders HBMenuItems for the values of a given enum.
 * The displayed MenuItem defaults to the enum value, but can be changed by passing a mapping function.
 * Directly returns a MenuItem array due to MuiSelects not working properly with Function Component children.
 */
export function HBEnumMenuItems<
  EnumValue extends PossibleEnumValue,
  EnumType extends GenericEnumType<EnumValue>
>({
  fromEnum,
  formatLabelWith = String,
  disabledValues = [],
}: IHBEnumMenuItemsProps<EnumValue, EnumType>): JSX.Element[] {
  const menuItemInputs: IMenuItemInput[] = Object.values(fromEnum).map(
    (value) => {
      return {
        value: value,
        label: formatLabelWith(value),
        isDisabled: disabledValues.includes(value),
      };
    }
  );

  return HBMenuItems({ menuItemInputs });
}

/** Props for the HBArrayMenuItems helper */
interface IHBArrayMenuItemsProps<T> {
  /** The values available for selection */
  fromArray: T[];

  /** Function for mapping an array value to a display node (defaults to stringified value) */
  formatLabelWith?: MapFunction<T, ReactNode>;

  /** Array of values to be disabled */
  disabledValues?: T[];
}

/**
 * Renders HBMenuItems for the values of a given array.
 * Display value defaults to the stringified value of the array item.
 * Directly returns a MenuItem array due to MuiSelects not working properly with Fragments.
 */
export function HBArrayMenuItems<T extends string | number>({
  fromArray,
  formatLabelWith = String,
  disabledValues = [],
}: IHBArrayMenuItemsProps<T>): JSX.Element[] {
  const menuItemInputs: IMenuItemInput[] = fromArray.map((value) => {
    return {
      value: value,
      label: formatLabelWith(value),
      isDisabled: disabledValues.includes(value),
    };
  });

  return HBMenuItems({ menuItemInputs });
}
