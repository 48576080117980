import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Color } from "@styles/default-colors";
import { ReactNode } from "react";

export interface IListItems {
  /** The text that is displayed as the main content */
  primaryText: ReactNode;

  /** The text that is displayed as the second content below the primaryText */
  secondaryText?: ReactNode;

  /** The image that is displayed next to the primary and secondary text */
  logo?: string;

  /** Link of the target when user clicks */
  link: string;

  /** The text to be shown on hover */
  title?: string;
}

interface IHBPaperListProps {
  /** Holds each list item as an element in the array */
  items: IListItems[];
}

/**
 * Custom HoloBuilder List
 *
 * Wrapper around the material-ui List component offering limited settings.
 * Each item behaves as a link with a hovering effect of background color changing
 */
export function HBPaperList({ items }: IHBPaperListProps): JSX.Element | null {
  if (!items.length) {
    return null;
  }

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {items.map((item, index) => {
        return (
          <Box
            component={Link}
            key={index}
            to={item.link}
            sx={{ textDecoration: "none", marginTop: "10px" }}
            title={item.title}
          >
            <Paper
              key={index}
              elevation={3}
              sx={{
                padding: "10px",
                margin: "10px",
                width: "300px",
                height: "80%",
                "&:hover": {
                  backgroundColor: Color.grey05,
                },
              }}
            >
              <List disablePadding>
                <ListItem key={index} dense disableGutters>
                  <ListItemAvatar>
                    <Avatar src={item.logo} variant="rounded" />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      "& span": {
                        fontWeight: "bold",
                      },
                    }}
                    primary={item.primaryText}
                    secondary={item.secondaryText}
                  />
                </ListItem>
              </List>
            </Paper>
          </Box>
        );
      })}
    </Box>
  );
}
