/**
 * Names for all the local storage items that this application stores as booleans.
 * These names can be extended as required.
 */
type LocalStorageBooleanName = never;

/**
 * Names for all the local storage items that this application stores as strings.
 * These names can be extended as required.
 */
type LocalStorageStringName =
  // Stores the time when the app was initialized.
  "app-init-time";

/**
 * Names for all the local storage items that this application stores as JSONs.
 * These names can be extended as required.
 */
type LocalStorageJSONName = never;

/** Names for all the local storage items that this application stores in all data types. */
type LocalStorageName =
  | LocalStorageStringName
  | LocalStorageBooleanName
  | LocalStorageJSONName;

/**
 * This code includes internal functions for handling the local storage. These functions are not
 * meant to be used outside of this file. Instead, to retrieve and set values from the local
 * storage, you should use LocalStorageUtils.
 */
const LocalStorageUtilsInternal = {
  /**
   * Checks whether the browser supports local storage. Otherwise the app can use cookies.
   *
   * @returns True if the browser supports local storage.
   */
  hasLocalStorageSupport(): boolean {
    // check this link on how to test for availability of local storage
    // eslint-disable-next-line max-len -- Link is too long
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
    try {
      const localStorageInternal = window.localStorage;
      if (!localStorageInternal) {
        throw new Error(
          "The application does not support local storage. Will use cookies instead"
        );
      }
      localStorageInternal.setItem("test", "test");
      localStorageInternal.removeItem("test");
      return true;
    } catch (error) {
      return false;
    }
  },

  /**
   * Sets an item in the form of a string.
   *
   * @param name Name of the item to set.
   * @param value value to be stored as a string.
   * @param days Optional days to store the item. Only applies when localStorage is not available.
   */
  setItem(name: LocalStorageName, value: string, days?: number): void {
    let expires = "";
    if (days) {
      const date = new Date();
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- necessary for calculation
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    if (this.hasLocalStorageSupport()) {
      localStorage.setItem(name, value);
    } else {
      document.cookie = `${name}=${value}${expires}; path=/`;
    }
  },

  /**
   * Gets an item from the local storage, without doing any kind of parsing.
   *
   * @returns The items value in its original string format, returns null if no value was stored.
   */
  getItem(name: LocalStorageName): string | null {
    if (this.hasLocalStorageSupport()) {
      return localStorage.getItem(name);
    } else {
      const nameEQ = `${name}=`;
      const cookieArray = document.cookie.split(";");
      for (let c of cookieArray) {
        while (c.charAt(0) === " ") {
          c = c.substring(1, c.length);
        }

        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
      return null;
    }
  },
};

/**
 * This code has functions that make it simple to handle the local storage by storing or getting
 * parsed values. By default, all values in the local storage are treated as strings.
 * These functions make it easier to convert them to the correct type that you originally set.
 */
export const LocalStorageUtils = {
  // ##########################################  Setters ##########################################

  /**
   * Sets an item in the form of a JSON, the item will be stringified and then saved.
   *
   * @param name Name of the item to set.
   * @param value JSON value to be stored as a string.
   * @param days Optional days to store the item. Only applies when localStorage is not available.
   */
  setStringItem(
    name: LocalStorageStringName,
    value: string,
    days?: number
  ): void {
    LocalStorageUtilsInternal.setItem(name, value, days);
  },

  /**
   * Sets an item in the form of a JSON, the item will be stringified and then saved.
   *
   * @param name Name of the item to set.
   * @param value JSON value to be stored as a string.
   * @param days Optional days to store the item. Only applies when localStorage is not available.
   */
  setBooleanItem(
    name: LocalStorageBooleanName,
    isTrue: boolean,
    days?: number
  ): void {
    LocalStorageUtilsInternal.setItem(name, isTrue ? "true" : "false", days);
  },

  /**
   * Sets an item in the form of a JSON, the item will be stringified and then saved.
   *
   * @param name Name of the item to set.
   * @param value JSON value to be stored as a string.
   * @param days Optional days to store the item. Only applies when localStorage is not available.
   */
  setJSONItem(
    name: LocalStorageJSONName,
    /* eslint-disable  @typescript-eslint/no-explicit-any -- Generic method, it is ok to use any */
    value: Record<string, any>,
    days?: number
  ): void {
    LocalStorageUtilsInternal.setItem(name, JSON.stringify(value), days);
  },

  // ##########################################  Getters ##########################################

  /**
   * Gets an item that should have been stored as a stringified JSON and parses the value.
   *
   * @param name Name of the item to get.
   * @returns Parsed JSON if found, otherwise null.
   */
  getStringItem(name: LocalStorageStringName): string | null {
    return LocalStorageUtilsInternal.getItem(name);
  },

  /**
   * Gets an item that should have been stored as a stringified JSON and parses the value.
   *
   * @param name Name of the item to get.
   * @returns Parsed JSON if found, otherwise null.
   */
  getBooleanItem(name: LocalStorageBooleanName): boolean | null {
    const item = LocalStorageUtilsInternal.getItem(name);
    if (item === null) {
      return null;
    }
    return item === "true";
  },

  /**
   * Gets an item that should have been stored as a stringified JSON and parses the value.
   *
   * @param name Name of the item to get.
   * @returns Parsed JSON if found, otherwise null.
   */
  /* eslint-disable  @typescript-eslint/no-explicit-any -- Generic method, it is ok to use any */
  getJSONItem<T extends Record<string, any>>(
    name: LocalStorageJSONName
  ): T | null {
    const item = LocalStorageUtilsInternal.getItem(name);
    if (!item) {
      return null;
    }
    /* eslint-disable  @typescript-eslint/no-explicit-any -- Generic method, it is ok to use any */
    return JSON.parse(item as any);
  },

  /**
   * Removes an item from the storage.
   *
   * @param name Name of the item to be removed.
   */
  removeItem(name: LocalStorageName): void {
    if (LocalStorageUtilsInternal.hasLocalStorageSupport()) {
      localStorage.removeItem(name);
    } else {
      LocalStorageUtilsInternal.setItem(name, "", -1);
    }
  },
};
