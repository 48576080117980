import { ComparatorFn, Compare } from "@utils/compare";
import {
  ColumnWithCustomSorting,
  ColumnWithDisabledSorting,
  SortableColumn,
} from "@components/tables/hb-table-types";

/**
 * Type guard to check whether a SortableColumn has a custom compare function.
 */
function isColumnWithCustomSorting<T>(
  column: SortableColumn<T>
): column is ColumnWithCustomSorting<T> {
  return !!(column as ColumnWithCustomSorting<T>).compareFunction;
}

/**
 * Type guard to check whether a SortableColumn has a custom compare function.
 */
export function isColumnWithDisabledSorting<T>(
  column: SortableColumn<T>
): column is ColumnWithDisabledSorting {
  return (column as ColumnWithDisabledSorting).shouldDisableSorting;
}

/**
 * Returns the ascending compare function for a column defined in a SortableColumn array.
 * Returns the defaultComparison if the column has no custom comparison function defined.
 */
export function findColumnCompareFunction<
  T extends { [x: string]: string | number }
>(columns: SortableColumn<T>[], columnId: string): ComparatorFn<T> {
  const column = columns.find((column) => column.id === columnId);

  // Return a comparison function that does not change the order of items in case the find fails.
  if (!column) {
    return () => 0;
  }

  return isColumnWithCustomSorting(column)
    ? column.compareFunction
    : Compare.byObjectKey(columnId, Compare.defaultComparison);
}
