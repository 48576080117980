import { Typography } from "@mui/material";
import { HBSnackbar } from "@components/hb-customs/hb-snackbar";
import { ErrorBoundaryUtils } from "@components/error-boundary/error-boundary-utils";
import { IErrorWithTitle } from "@components/error-boundary/error-types";

interface IProps {
  errorWithTitle: IErrorWithTitle;
  onClose(): void;
}

/**
 * Displays an error message in a Snackbar on the bottom left of the screen.
 */
export function ErrorSnackbar({
  errorWithTitle,
  onClose,
}: IProps): JSX.Element {
  function handleClose(): void {
    onClose();
  }

  return (
    <HBSnackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      isOpen
      onClose={handleClose}
    >
      <Typography component="div">
        <b>{errorWithTitle.title}</b>:{" "}
        <ErrorBoundaryUtils.GetErrorDisplayMessage
          error={errorWithTitle.error}
        />
      </Typography>
    </HBSnackbar>
  );
}
