import { Header } from "@components/header";
import { AdminAPITypes } from "@stellar/api-logic";
import { CreatePlanContainer } from "@pages/create-plan/create-plan-container/create-plan-container";
import { ContentLayout } from "@pages/content-layout";
import { useErrorHandler } from "@components/error-boundary/error-handling-context";
import { useProject } from "@hooks/use-project";
import { ProjectBreadcrumbs } from "@components/breadcrumbs/project-breadcrumbs";
import { LoadingScreen } from "@components/loading-screen";
import { useAppParams } from "@router/router-helper";
import { useAppDispatch } from "@store/store-helper";
import { setPlanSubject } from "@store/plan-creation/plan-creation-slice";

export function CreateProjectPlanPage(): JSX.Element {
  const { projectId } = useAppParams();
  const dispatch = useAppDispatch();

  const { handleErrorWithPage } = useErrorHandler();

  const [project, isFetchingProject] = useProject(
    projectId,
    handleErrorWithPage
  );

  if (project) {
    dispatch(
      setPlanSubject({
        planSubjectId: project.id.toString(),
        planSubjectType: AdminAPITypes.ESubjectType.project,
        planSubjectName: project.name,
      })
    );
  }

  return (
    <LoadingScreen isFetching={!project || isFetchingProject}>
      {project && (
        <ContentLayout
          breadcrumbs={<ProjectBreadcrumbs project={project} />}
          header={
            <Header heading="Create New Project Plan" shouldUseSlimVariant />
          }
        >
          <CreatePlanContainer />
        </ContentLayout>
      )}
    </LoadingScreen>
  );
}
