import { AdminAPITypes } from "@stellar/api-logic";

// TODO: isCompanyContext and isUserContext functions should be removed in a new ticket.
// The User type is obsolete( Previously, Projects could exist without a workspace or company
// were associated with a single user account, but this structure is not used anymore).
// Currently, every context always includes a defined 'Company' object.

export function isCompanyContext(
  context:
    | AdminAPITypes.IAdmProjectCompanyContext
    | AdminAPITypes.IAdmProjectUserContext
): context is AdminAPITypes.IAdmProjectCompanyContext {
  return context.kind === "COMPANY";
}

export function isUserContext(
  context:
    | AdminAPITypes.IAdmProjectCompanyContext
    | AdminAPITypes.IAdmProjectUserContext
): context is AdminAPITypes.IAdmProjectUserContext {
  return context.kind === "USER";
}

export function isUnpaidPlan(
  plan: AdminAPITypes.IPlan
): plan is AdminAPITypes.IUnpaidPlan {
  return !!(plan?.payment as AdminAPITypes.IUnpaidPaymentInformation)?.unpaid;
}

export function isCreateProjectAreaConstraintPayload(
  createConstraintPayload: AdminAPITypes.CreateConstraintPayload
): createConstraintPayload is AdminAPITypes.CreateProjectAreaConstraintPayload {
  return (
    createConstraintPayload.type === AdminAPITypes.EConstraintType.projectArea
  );
}

export function isCreateProjectCountConstraintPayload(
  createConstraintPayload: AdminAPITypes.CreateConstraintPayload
): createConstraintPayload is AdminAPITypes.CreateProjectCountConstraintPayload {
  return (
    createConstraintPayload.type === AdminAPITypes.EConstraintType.projectCount
  );
}

export function isCreateUserCountConstraintPayload(
  createConstraintPayload: AdminAPITypes.CreateConstraintPayload
): createConstraintPayload is AdminAPITypes.CreateUserCountConstraintPayload {
  return (
    createConstraintPayload.type === AdminAPITypes.EConstraintType.userCount
  );
}

/**
 * Returns true if the argument is in the type of Waypoint Constraint payloads
 */
export function isCreateWaypointConstraintPayload(
  createConstraintPayload: AdminAPITypes.CreateConstraintPayload
): createConstraintPayload is AdminAPITypes.CreateWaypointConstraintPayload {
  return (
    createConstraintPayload.type === AdminAPITypes.EConstraintType.waypoint
  );
}

/**
 * Returns true if the argument is in the type of Timetravel on Waypoint Constraint payloads
 */
export function isCreateTimetravelOnWaypointConstraintPayload(
  createConstraintPayload: AdminAPITypes.CreateConstraintPayload
): createConstraintPayload is AdminAPITypes.CreateTimetravelOnWaypointConstraintPayload {
  return (
    createConstraintPayload.type ===
    AdminAPITypes.EConstraintType.timetravelOnWaypoint
  );
}

export function isAdmUser(
  obj: AdminAPITypes.IAdmUser | AdminAPITypes.IAdmCompany
): obj is AdminAPITypes.IAdmUser {
  return !!(obj as AdminAPITypes.IAdmUser).companies;
}
