import { Box, Divider, Grid2, TextField, Autocomplete } from "@mui/material";
import { FormContainer } from "@components/forms/form-container";
import { HBSelect } from "@components/hb-customs/hb-select";
import { HBEnumMenuItems } from "@components/hb-customs/hb-menu-items";
import { Header } from "@components/header";
import { HBNumberInput } from "@components/hb-customs/hb-number-input";
import { AdminAPITypes } from "@stellar/api-logic";
import { ContentLayout } from "@pages/content-layout";
import { StandaloneProjectToggle } from "@components/hb-customs/hb-standalone-project-toggle";
import { useAppNavigation } from "@router/use-app-navigation";
import { useProjectCreationContext } from "@pages/create-project/project-creation-context";
import { CreateProjectBreadcrumb } from "@pages/create-project/create-project-breadcrumb";
import {
  formatCreatedProjectSubject,
  isValidEmail,
} from "@pages/create-project/create-project-helper";

export function CreateProjectPage(): JSX.Element {
  const projectCreationContext = useProjectCreationContext();
  const { navigateToProfilePage } = useAppNavigation();

  const {
    subject,
    projectName,
    setProjectName,
    projectAddress,
    setProjectAddress,
    projectArea,
    setProjectArea,
    projectAreaUnit,
    setProjectAreaUnit,
    projectManager,
    setProjectManager,
    isStandalone,
    setIsStandalone,
    setShouldShowSummary,
  } = projectCreationContext;

  function isProjectCreationDataValid(): boolean {
    return !!(
      projectName &&
      projectAddress &&
      projectArea &&
      projectArea > 0 &&
      isValidEmail(projectManager)
    );
  }

  return (
    <ContentLayout
      breadcrumbs={<CreateProjectBreadcrumb subject={subject} />}
      header={<Header heading="Create New Project" shouldUseSlimVariant />}
    >
      <FormContainer
        sx={{
          width: "550px",
          ml: "110px",
          mt: "10px",
          "& .MuiFormControl-root": {
            mt: "20px",
          },
        }}
        handleCancelClicked={() =>
          navigateToProfilePage(subject.type, subject.company.id)
        }
        handleCreateClicked={() => setShouldShowSummary(true)}
        shouldCreateButtonBeDisabled={!isProjectCreationDataValid()}
      >
        <Box sx={{ fontSize: "20px", marginBottom: "20px" }}>
          Project Details
        </Box>

        <Grid2 container>
          {/* project name */}
          <TextField
            label="Project Name"
            variant="outlined"
            value={projectName}
            onChange={(event: React.ChangeEvent<{ value: string }>) => {
              setProjectName(event.target.value);
            }}
            required
            fullWidth
          />

          {/* project address */}
          <TextField
            label="Project Address"
            variant="outlined"
            value={projectAddress}
            onChange={(event: React.ChangeEvent<{ value: string }>) => {
              setProjectAddress(event.target.value);
            }}
            required
            fullWidth
          />

          <Grid2 container spacing={1}>
            <Grid2 size={{ xs: 7 }}>
              {/* project area */}
              <HBNumberInput
                label="Project Area"
                value={projectArea}
                onChange={setProjectArea}
                minValue={1}
                isRequired
                isIntegerValue
                isFullWidth
                variant="outlined"
              />
            </Grid2>

            <Grid2 size={{ xs: 5 }}>
              {/* product type */}
              <HBSelect
                label="Project Area Unit"
                value={projectAreaUnit}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  setProjectAreaUnit(
                    event.target.value as AdminAPITypes.EAreaUnit
                  );
                }}
                variant="outlined"
                isFullWidth
              >
                {HBEnumMenuItems({ fromEnum: AdminAPITypes.EAreaUnit })}
              </HBSelect>
            </Grid2>

            {/* standalone projects */}
            <StandaloneProjectToggle {...{ isStandalone, setIsStandalone }} />
          </Grid2>
        </Grid2>

        <Divider sx={{ margin: "20px 0px 10px 0px" }} />

        <Box sx={{ fontSize: "20px", marginBottom: "20px" }}>
          Project Assignment
        </Box>

        <Grid2 container>
          {/* Subject Type */}
          <TextField
            label="Add To"
            value={formatCreatedProjectSubject(subject)}
            variant="outlined"
            fullWidth
            disabled
          />
          {/* Project Manager */}

          <Autocomplete
            defaultValue={projectManager}
            value={projectManager}
            options={subject.groupMembers.map(({ user }) => user.email)}
            freeSolo
            autoHighlight
            onInputChange={(_, value) => setProjectManager(value)}
            fullWidth
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label={"Project Manager"}
                variant="outlined"
              />
            )}
          />
        </Grid2>
      </FormContainer>
    </ContentLayout>
  );
}
