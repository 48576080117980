import { ContentLayout } from "@pages/content-layout";
import { AccountBreadcrumbs } from "@components/breadcrumbs/account-breadcrumbs";
import { AccountContext } from "@pages/account-profile/account-context";
import { AccountProfileHeader } from "@pages/account-profile/account-profile-header";
import { useErrorHandler } from "@components/error-boundary/error-handling-context";
import { useAccount } from "@hooks/use-account";
import { AccountInfo } from "@pages/account-profile/tab-contents/account-info";
import { AccountActions } from "@pages/account-profile/tab-contents/account-actions";
import { PlanInfo } from "@pages/plan-info";
import { AdminAPITypes } from "@stellar/api-logic";
import { LoadingScreen } from "@components/loading-screen";
import { useAppParams } from "@router/router-helper";

/**
 * Fetch account data and display the data in account profile page
 */
export function AccountProfilePage(): JSX.Element {
  const { accountId } = useAppParams();

  const { handleErrorWithPage } = useErrorHandler();

  const [account, isFetchingData] = useAccount(accountId, handleErrorWithPage);

  return (
    <LoadingScreen isFetching={isFetchingData} data={account}>
      {account && (
        <AccountContext.Provider value={account}>
          <ContentLayout
            breadcrumbs={<AccountBreadcrumbs account={account} />}
            header={<AccountProfileHeader />}
            tabs={[
              {
                label: "User Information",
                content: <AccountInfo />,
              },
              {
                label: "Plans",
                content: (
                  <PlanInfo
                    subjectType={AdminAPITypes.ESubjectType.user}
                    subjectId={account.identity}
                  />
                ),
              },
              {
                label: "Account",
                content: <AccountActions />,
              },
            ]}
          />
        </AccountContext.Provider>
      )}
    </LoadingScreen>
  );
}
