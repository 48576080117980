import { Component } from "react";
import { ErrorPage } from "@components/error-boundary/error-page";
import { ErrorHandlingContext } from "@components/error-boundary/error-handling-context";
import { ErrorDialog } from "@components/error-boundary/error-dialog";
import { ErrorSnackbar } from "@components/error-boundary/error-snackbar";
import {
  HandleableError,
  IErrorWithTitle,
} from "@components/error-boundary/error-types";
import { ReactChildrenOnly } from "@utils/utility-types";
// eslint-disable-next-line no-restricted-imports -- The only place needed to initialize Logger
import { Logger } from "@stellar/web-core";

interface IState {
  errorPage: IErrorWithTitle | null;
  errorDialog: IErrorWithTitle | null;
  errorSnackbar: IErrorWithTitle | null;
}

/**
 * Use to create a new ErrorBoundary and ErrorHandlingContext.
 * Needs to be a class component due to componentDidCatch not being available in function components.
 */
// eslint-disable-next-line react/prefer-es6-class
export class ErrorBoundary extends Component<ReactChildrenOnly, IState> {
  constructor(props: ReactChildrenOnly) {
    super(props);
    this.state = { errorPage: null, errorDialog: null, errorSnackbar: null };
  }

  /**
   * Catches all React-rendering errors within the children.
   */
  componentDidCatch(error: Error): void {
    this.handleErrorWithPage("An unexpected error occurred: ", error);
  }

  /** Log error in logger */
  logError(title: string, error: HandleableError): void {
    if (typeof error === "string" || error instanceof Error) {
      Logger.logError(title, { error });
    } else {
      Logger.logError(title, { payload: { error } });
    }
  }

  /** Handle the error by showing a page */
  handleErrorWithPage = (title: string, error: HandleableError): void => {
    this.logError(title, error);
    this.setState({
      errorPage: {
        title,
        error,
      },
    });
  };

  /** Handle the error by showing a dialog */
  handleErrorWithDialog = (title: string, error: HandleableError): void => {
    this.logError(title, error);
    this.setState({
      errorDialog: {
        title,
        error,
      },
    });
  };

  /** Handle the error by showing a toast */
  handleErrorWithSnackbar = (title: string, error: HandleableError): void => {
    this.logError(title, error);
    this.setState({
      errorSnackbar: {
        title,
        error,
      },
    });
  };

  handleClosePageError = (): void => {
    this.setState({ errorPage: null });
  };

  handleCloseDialogError = (): void => {
    this.setState({ errorDialog: null });
  };

  handleCloseSnackbarError = (): void => {
    this.setState({ errorSnackbar: null });
  };

  handleOnGoHome = (): void => {
    this.handleClosePageError();

    // React-router is not available in root ErrorBoundary
    window.location.href = "/";
  };

  render(): JSX.Element {
    if (this.state.errorPage) {
      return (
        <ErrorPage
          errorWithTitle={this.state.errorPage}
          onReload={this.handleClosePageError}
          onGoHome={this.handleOnGoHome}
        />
      );
    }

    return (
      <ErrorHandlingContext.Provider
        value={{
          handleErrorWithDialog: this.handleErrorWithDialog,
          handleErrorWithSnackbar: this.handleErrorWithSnackbar,
          handleErrorWithPage: this.handleErrorWithPage,
        }}
      >
        {this.props.children}
        {this.state.errorDialog && (
          <ErrorDialog
            errorWithTitle={this.state.errorDialog}
            onClose={this.handleCloseDialogError}
          />
        )}
        {this.state.errorSnackbar && (
          <ErrorSnackbar
            errorWithTitle={this.state.errorSnackbar}
            onClose={this.handleCloseSnackbarError}
          />
        )}
      </ErrorHandlingContext.Provider>
    );
  }
}
