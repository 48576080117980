import { DateTimeUtils } from "@stellar/web-core";

interface IPlanDateProps {
  date: DateTimeUtils.DateTimeInput;
}

/**
 * Render the provided date with time in the UTC time zone
 */
export function PlanUtcDate({ date }: IPlanDateProps): JSX.Element {
  return (
    <>
      {DateTimeUtils.format({
        date,
        format: DateTimeUtils.EAllowedDateFormat.generalWithSpaceAndTime,
        shouldUseUtc: true,
      })}{" "}
      <i>UTC</i>
    </>
  );
}
