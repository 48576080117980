import { AdminAPITypes, APITypes } from "@stellar/api-logic";

/**
 * Helper functions to access common data from different kind of plans.
 */
export const PlanDataExtractor = {
  /**
   * Returns the endDate of plans that have an actual endDate
   * Returns undefined for recurring plan
   */
  getEndDate(plan: AdminAPITypes.IPlan): APITypes.Iso8601Date | undefined {
    return plan.endDate;
  },

  Payload: {
    /**
     * Transforms a given plan into UpdatePlanPayload
     * The returned object can be adjusted and send to the API to update the plan
     */
    extractUpdatePlanPayloadFromPlan(
      plan: AdminAPITypes.IPlan
    ): AdminAPITypes.UpdatePlanPayload {
      const featureBundleIds = plan.featureBundles.map(
        (featureBundle) => featureBundle.id
      );

      const featureIds = plan.features.map((features) => features.id);
      const constraints: AdminAPITypes.CreateConstraintPayload[] =
        plan.constraints.map((constraint) => {
          const roles = {
            company: constraint.data.roles?.company ?? [],
            resource: constraint.data.roles?.resource ?? [],
          };
          return {
            type: constraint.type,
            data: {
              max: constraint.data.max,
              current: constraint.data.current,
              ...(roles.company.length > 0 || roles.resource.length > 0
                ? { roles }
                : {}),
            },
            createdAt: constraint.createdAt,
            modifiedAt: constraint.modifiedAt,
          };
        });
      return {
        label: plan.label,
        description: plan.description,
        featureBundles: featureBundleIds,
        features: featureIds,
        constraints,
      };
    },
  },
};
