import { Grid2 } from "@mui/material";
import { Color } from "@styles/default-colors";

interface IHBFooterProps {
  /** Content shown in the left side of footer */
  leftProperty?: JSX.Element;

  /** Content shown in the left side of footer */
  rightProperties: JSX.Element | JSX.Element[];
}

/**
 * Shows a footer in the bottom of the page. The footer is divided into left and right section
 */
export function HBFooter({
  leftProperty,
  rightProperties,
}: IHBFooterProps): JSX.Element {
  return (
    <Grid2
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{
        color: Color.white,
        backgroundColor: Color.grey52,
        padding: "12px 47px",
      }}
    >
      <Grid2 size={{ xs: 6 }} container alignItems="center" spacing={2}>
        <Grid2>{leftProperty}</Grid2>
      </Grid2>

      <Grid2
        size={{ xs: 6 }}
        container
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        {Array.isArray(rightProperties) ? (
          rightProperties.map((rightProperty, index) => (
            <Grid2 key={index}>{rightProperty}</Grid2>
          ))
        ) : (
          <Grid2>{rightProperties}</Grid2>
        )}
      </Grid2>
    </Grid2>
  );
}
