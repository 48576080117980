import {
  IconButton,
  IconButtonProps,
  Snackbar,
  SnackbarProps,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { Color } from "@styles/default-colors";
import { PropsWithChildren } from "react";

interface IProps extends Pick<SnackbarProps, "anchorOrigin" | "sx"> {
  /** Whether the snackbar is open or not */
  isOpen: boolean;

  /** Handler when the snackbar is closed */
  onClose(): void;

  /** Optional stylings for close button */
  buttonSx?: IconButtonProps["sx"];
}

export function HBSnackbar({
  isOpen,
  onClose: handleClose,
  children,
  anchorOrigin,
  sx,
  buttonSx,
}: PropsWithChildren<IProps>): JSX.Element {
  return (
    <Snackbar
      ContentProps={{
        sx: {
          ...sx,
        },
      }}
      anchorOrigin={anchorOrigin}
      open={isOpen}
      onClose={handleClose}
      autoHideDuration={5000}
      message={children}
      action={
        <IconButton
          sx={{ color: Color.white, ...buttonSx }}
          onClick={handleClose}
          size="large"
        >
          <Clear />
        </IconButton>
      }
    />
  );
}
