import { ProductIdentifier } from "@store/plan-creation/plan-creation-slice-helper-types";
import DateAdapter from "@date-io/luxon";
import { AdminAPITypes, CoreAPITypes } from "@stellar/api-logic";
import {
  ONE_YEAR_IN_DAYS,
  TRIAL_PLAN_DURATION_IN_DAYS,
} from "@utils/date-time";
import { Constraint } from "@utils/constraint";
import { isIdentifierTrial } from "@utils/product/product-utils";

/**
 * Returns the end date based on the selected product
 * End date is subtracted by 1 as both start and end date are taken into account for plan duration
 */
export function defaultPlanDuration(
  productIdentifier: ProductIdentifier | null,
  startDate: number
): number {
  const dateAdapter = new DateAdapter();
  let endDate = dateAdapter.addDays(
    dateAdapter.date(startDate),
    ONE_YEAR_IN_DAYS - 1
  );

  if (productIdentifier && isIdentifierTrial(productIdentifier)) {
    endDate = dateAdapter.addDays(
      dateAdapter.date(startDate),
      TRIAL_PLAN_DURATION_IN_DAYS - 1
    );
  }

  return dateAdapter.date(endDate).valueOf();
}

/** Returns the default plan limits based on the selected product */
export function defaultPlanActiveConstraints(
  productIdentifier: ProductIdentifier | null
): AdminAPITypes.CreateConstraintPayload[] {
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  switch (productIdentifier) {
    case "standardPointCloud":
      return [createUserRolePayload(3)];

    case "standard360":
      return [createUserRolePayload(3), createProjectCountPayload(3)];

    case "proPointCloud":
      return [createUserRolePayload(10)];

    case "pro360":
      return [createUserRolePayload(10), createProjectCountPayload(10)];

    default:
      return [];
  }
  /* eslint-enable */
}

/** Create user role active constraints */
function createUserRolePayload(
  limit: number
): AdminAPITypes.CreateConstraintPayload {
  return Constraint.createPayloadForConstraintWithValue(
    AdminAPITypes.EConstraintType.userRole,
    limit,
    0,
    {
      company: [
        CoreAPITypes.EUserCompanyRoleUpdated.enterpriseAdmin,
        CoreAPITypes.EUserCompanyRoleUpdated.groupManager,
        CoreAPITypes.EUserCompanyRoleUpdated.projectManager,
      ],
      resource: [
        CoreAPITypes.EUserProjectRoleUpdated.owner,
        CoreAPITypes.EUserProjectRoleUpdated.admin,
        CoreAPITypes.EUserProjectRoleUpdated.editor,
      ],
    }
  );
}

/** Create project count active constraints */
function createProjectCountPayload(
  limit: number
): AdminAPITypes.CreateConstraintPayload {
  return Constraint.createPayloadForConstraintWithValue(
    AdminAPITypes.EConstraintType.projectCount,
    limit
  );
}
