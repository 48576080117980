import { Grid2 } from "@mui/material";
import { InfoRow } from "@components/info-grid/info-row";
import { ILabelInfoPair } from "@components/info-grid/info-row-types";

interface IProps {
  /** Pair of ino to show on the left column */
  leftColumnRows?: ILabelInfoPair[];

  /** Pair of ino to show on the right column */
  rightColumnRows?: ILabelInfoPair[];
}

/** Displays given label/info pairs, providing two columns */
export function InfoGrid({
  leftColumnRows,
  rightColumnRows,
}: IProps): JSX.Element {
  return (
    <Grid2 container spacing={3} sx={{ width: "100%" }}>
      <Grid2 size={{ xs: 6 }}>
        {leftColumnRows &&
          leftColumnRows.map((row, index) => (
            <InfoRow label={row.label} info={row.info} key={index} />
          ))}
      </Grid2>
      <Grid2 size={{ xs: 6 }}>
        {rightColumnRows &&
          rightColumnRows.map((row, index) => (
            <InfoRow label={row.label} info={row.info} key={index} />
          ))}
      </Grid2>
    </Grid2>
  );
}
