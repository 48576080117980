import { AdminAPITypes } from "@stellar/api-logic";
import {
  UNLIMITED,
  formatConstraint,
  nonFormatConstraint,
} from "@utils/data-display";

interface IProps {
  constraints: AdminAPITypes.CreateConstraintPayload[];
  shouldFormatConstraints?: boolean;
}

// TODO: We can refactor this component by passing the formatConstraint as an argument and make it more general
export function Constraints({
  constraints,
  shouldFormatConstraints = true,
}: IProps): JSX.Element {
  if (!constraints.length) {
    return <span>{UNLIMITED}</span>;
  }

  return (
    <>
      {constraints.map((constraint, index) => (
        <span key={index}>
          {shouldFormatConstraints
            ? formatConstraint(constraint)
            : nonFormatConstraint(constraint)}
          <br />
        </span>
      ))}
    </>
  );
}
