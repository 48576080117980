import { ProjectBreadcrumbs } from "@components/breadcrumbs/project-breadcrumbs";
import { useErrorHandler } from "@components/error-boundary/error-handling-context";
import { AdminAPITypes } from "@stellar/api-logic";
import { PlanInfo } from "@pages/plan-info";
import { useProject } from "@hooks/use-project";
import { ContentLayout } from "@pages/content-layout";
import { ProjectContext } from "@pages/project-profile/project-context";
import { ProjectProfileHeader } from "@pages/project-profile/project-profile-header";
import { ProjectDetails } from "@pages/project-profile/tab-contents/project-details";
import { LoadingScreen } from "@components/loading-screen";
import { useAppParams } from "@router/router-helper";

export function ProjectProfilePage(): JSX.Element {
  const { projectId } = useAppParams();

  const { handleErrorWithPage } = useErrorHandler();

  const [project, isFetchingProject] = useProject(
    projectId,
    handleErrorWithPage
  );

  return (
    <LoadingScreen isFetching={isFetchingProject} data={project}>
      {project && (
        <ProjectContext.Provider value={project}>
          <ContentLayout
            breadcrumbs={<ProjectBreadcrumbs project={project} />}
            header={<ProjectProfileHeader />}
            tabs={[
              {
                label: "Project Information",
                content: <ProjectDetails />,
              },
              {
                label: "Plans",
                content: (
                  <PlanInfo
                    subjectType={AdminAPITypes.ESubjectType.project}
                    subjectId={project.id}
                  />
                ),
              },
            ]}
          />
        </ProjectContext.Provider>
      )}
    </LoadingScreen>
  );
}
