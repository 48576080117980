import { Header } from "@components/header";
import { ILabelInfoPair } from "@components/info-grid/info-row-types";
import { getAccountDisplayName } from "@utils/data-display";
import { OptionalDate } from "@components/optional-date";
import { useAccountContext } from "@pages/account-profile/account-context";
import { DateTimeUtils } from "@stellar/web-core";
import { IListItems } from "@components/hb-customs/hb-paper-list";
import { generateCompanyRoute } from "@router/router-helper";
import { OldAdminPanelAccountLink } from "@components/links/external-links";
import LaunchIcon from "@mui/icons-material/Launch";

/**
 * Display account information in the header of account profile page
 */
export function AccountProfileHeader(): JSX.Element {
  const account = useAccountContext();

  const AccountHeaderRightProperties: ILabelInfoPair[] = [
    {
      label: "Created",
      info: DateTimeUtils.format({ date: account.createdAt }),
    },
    {
      label: "Last Login",
      info: <OptionalDate date={account.lastLoginAt} />,
    },
    {
      label: "Account ID",
      info: (
        <>
          {account.id}
          <OldAdminPanelAccountLink accountId={account.identity}>
            <LaunchIcon
              sx={{ verticalAlign: "text-bottom" }}
              fontSize="small"
              color="primary"
            />
          </OldAdminPanelAccountLink>
        </>
      ),
    },
  ];

  const AccountHeaderLeftProperties: IListItems[] = account.companies.map(
    (company) => ({
      link: generateCompanyRoute(company.id),
      primaryText: company.name,
      secondaryText: company.role,
      logo: company.logoUrl,
      title: "Go to Company Profile",
    })
  );

  return (
    <Header
      title="User"
      heading={getAccountDisplayName(account)}
      subheader={account.email}
      rightProperties={AccountHeaderRightProperties}
      leftProperties={AccountHeaderLeftProperties}
    />
  );
}
