import { Box, Grid2 } from "@mui/material";
import { HBGridItem } from "@components/hb-customs/hb-grid-item";
import { ContentLayout } from "@pages/content-layout";
import { ProjectInfoFooter } from "@components/footers";
import { ChangeAccountAuthProvider } from "@pages/account-profile/change-account-login-method";
import { useAccountContext } from "@pages/account-profile/account-context";
import { AdminAPITypes } from "@stellar/api-logic";
import { Color } from "@styles/default-colors";

/**
 * Content inside the account actions tab
 */
export function AccountActions(): JSX.Element {
  const account = useAccountContext();

  return (
    <ContentLayout
      footer={
        <ProjectInfoFooter subjectType={AdminAPITypes.ESubjectType.user} />
      }
    >
      <Grid2 container sx={{ padding: "16px", fontSize: "16px" }}>
        <HBGridItem width={12}>
          <Box sx={{ color: Color.black, pb: "16px" }}>
            Change Authentication Method
          </Box>
          <ChangeAccountAuthProvider
            userId={account.id}
            userName={`${account.firstName} ${account.lastName}`}
            providerId={account.providerId}
          />
        </HBGridItem>
      </Grid2>
    </ContentLayout>
  );
}
