import { InfoRow } from "@components/info-grid/info-row";
import { useMemo } from "react";
import { formatPlanSubjectType } from "@utils/data-display";
import { Color } from "@utils/styles/default-colors";
import { FormContainer } from "@components/forms/form-container";
import { useErrorHandler } from "@components/error-boundary/error-handling-context";
import { Constraints } from "@components/constraints";
import { createPlanCreationPayload } from "@pages/create-plan/create-plan-creation-payload";
import { DateTimeUtils } from "@stellar/web-core";
import { Box, Grid2 } from "@mui/material";
import { AdminAPITypes } from "@stellar/api-logic";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  activeConstraintsSelector,
  selectedProductSelector,
} from "@store/plan-creation/plan-creation-selector";
import { useCreatePlan } from "@utils/hooks/plan/use-create-plan";
import {
  commentsSelector,
  creationDatesSelector,
  planSubjectSelector,
} from "@store/plan-creation/plan-creation-selector";
import {
  resetPlanCreationState,
  setIsFetchingData,
} from "@store/plan-creation/plan-creation-slice";
import { ReactSetStateFunction } from "@utils/utility-types";
import { assert } from "@utils/assert";

interface Props {
  /** Setter to show the summary page */
  setShouldShowSummary: ReactSetStateFunction<boolean>;
}

/** Shows the summary of the plan that is going to be created */
export function PlanCreationSummary({
  setShouldShowSummary,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { handleErrorWithDialog } = useErrorHandler();
  const { planSubjectId, planSubjectType, planSubjectName } =
    useAppSelector(planSubjectSelector);
  const activeConstraints = useAppSelector(activeConstraintsSelector);

  const comments = useAppSelector(commentsSelector);
  const { startDate, endDate } = useAppSelector(creationDatesSelector);

  const isSubjectTypeDevice = useMemo(() => {
    return planSubjectType === AdminAPITypes.ESubjectType.device;
  }, [planSubjectType]);

  const { createDevicePlan, createPlan } = useCreatePlan();

  const selectedProduct = useAppSelector(selectedProductSelector);

  assert(planSubjectType, "Plan subject type should be available");
  assert(planSubjectId, "Plan subject ID should be available");

  async function onCreateClicked(
    planSubjectId: string,
    planSubjectType: AdminAPITypes.ESubjectType
  ): Promise<void> {
    dispatch(setIsFetchingData(true));

    try {
      const payload = createPlanCreationPayload({
        planSubjectId,
        planSubjectType,
        selectedProduct,
        activeConstraints,
        startDate,
        endDate,
        comments,
      });

      isSubjectTypeDevice
        ? await createDevicePlan(payload, planSubjectId)
        : await createPlan(payload);
      dispatch(resetPlanCreationState());
    } catch (error) {
      handleErrorWithDialog("Error while creating a new plan", error);

      // Hide spinner in case the request failed
      dispatch(setIsFetchingData(false));
    }
  }

  const formattedStartDate = DateTimeUtils.format({
    date: startDate,
    shouldUseUtc: true,
  });
  const formattedEndDate = DateTimeUtils.format({
    date: endDate,
    shouldUseUtc: true,
  });

  return (
    <FormContainer
      sx={{
        "& > p": {
          color: Color.orange,
        },
      }}
      createButtonText="Confirm"
      handleCreateClicked={() =>
        onCreateClicked(planSubjectId, planSubjectType)
      }
      cancelButtonText="Back"
      handleCancelClicked={() => setShouldShowSummary(false)}
    >
      <b>Confirm the plan and invoicing details.</b>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, sm: 6, md: 4 }}>
          <Box>
            <h2>Plan Details</h2>
            <Box sx={{ pl: "10px" }}>
              <InfoRow
                labelTextAlign="left"
                label={formatPlanSubjectType(planSubjectType)}
                info={planSubjectName}
              />

              <InfoRow
                labelTextAlign="left"
                label="Product"
                info={selectedProduct?.name}
              />

              {/* We don't need to show the Plan limits section for the Device subject type: */}
              {!isSubjectTypeDevice && (
                <InfoRow
                  labelTextAlign="left"
                  label="Plan Limits"
                  info={
                    <Constraints
                      constraints={activeConstraints}
                      shouldFormatConstraints={false}
                    />
                  }
                />
              )}

              <InfoRow
                labelTextAlign="left"
                label="Start Date"
                info={`${formattedStartDate} (UTC)`}
              />
              <InfoRow
                labelTextAlign="left"
                label="End Date"
                info={`${formattedEndDate} (UTC)`}
              />

              <InfoRow labelTextAlign="left" label="Comment" info={comments} />
            </Box>
          </Box>
        </Grid2>
      </Grid2>
    </FormContainer>
  );
}
