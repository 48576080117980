// eslint-disable-next-line no-restricted-imports -- The only place needed to define useParams
import { generatePath, useParams } from "react-router-dom";
import { APITypes } from "@stellar/api-logic";
import { MainRoutes, RouteParams } from "@router/route-params";

/** Use throughout app instead of plain `useParams` to get type-safety */
export function useAppParams(): Readonly<Partial<RouteParams>> {
  return useParams<RouteParams>();
}

/** Name of different paths in the app  */
enum EAppRoutes {
  HomePage = "HomePage",
  CompanyRoute = "CompanyRoute",
  CompanyAddPlanRoute = "CompanyAddPlanRoute",
  CompanyAddDevicePlanRoute = "CompanyAddDevicePlanRoute",
  CompanyAddProjectRoute = "CompanyAddProjectRoute",
  ProjectRoute = "ProjectRoute",
  ProjectAddPlanRoute = "ProjectAddPlanRoute",
  AccountRoute = "AccountRoute",
  GroupRoute = "GroupRoute",
}

/** List of routes available in the app */
const AppRoutes: Record<EAppRoutes, string> = {
  /** The route of home page */
  [EAppRoutes.HomePage]: MainRoutes.root,

  /** The route of company with dynamic segments */
  [EAppRoutes.CompanyRoute]: `/${MainRoutes.company}/:companyId`,

  /** The route of adding plan to company with dynamic segments */
  [EAppRoutes.CompanyAddPlanRoute]: `/${MainRoutes.company}/:companyId/add-plan`,

  /** The route of adding device plan to company with dynamic segments */
  [EAppRoutes.CompanyAddDevicePlanRoute]: `/${MainRoutes.company}/:companyId/add-device-plan`,

  /** The route of adding project to company with dynamic segments */
  [EAppRoutes.CompanyAddProjectRoute]: `/${MainRoutes.company}/:companyId/group/:groupId/add-project`,

  /** The route of project with dynamic segments */
  [EAppRoutes.ProjectRoute]: `/${MainRoutes.project}/:projectId`,

  /** The route of adding plan to project with dynamic segments */
  [EAppRoutes.ProjectAddPlanRoute]: `/${MainRoutes.project}/:projectId/add-plan`,

  /** The route of account with dynamic segments */
  [EAppRoutes.AccountRoute]: `/${MainRoutes.account}/:accountId`,

  /** The route of group with dynamic segments */
  [EAppRoutes.GroupRoute]: `/${MainRoutes.group}/:groupId`,
};

/** Generates the company route */
export function generateCompanyRoute(companyId: APITypes.CompanyId): string {
  return generatePath(AppRoutes.CompanyRoute, {
    companyId,
  });
}

/** Generates the company add plan route */
export function generateCompanyAddPlanRoute(
  companyId: APITypes.CompanyId
): string {
  return generatePath(AppRoutes.CompanyAddPlanRoute, {
    companyId,
  });
}

/** Generates the company add plan route */
export function generateCompanyAddDevicePlanRoute(
  companyId: APITypes.CompanyId
): string {
  return generatePath(AppRoutes.CompanyAddDevicePlanRoute, {
    companyId,
  });
}

/** Generates the company add plan route */
export function generateCompanyAddProjectPlanRoute(
  companyId: APITypes.CompanyId,
  groupId: APITypes.GroupId
): string {
  return generatePath(AppRoutes.CompanyAddProjectRoute, {
    companyId,
    groupId,
  });
}

/** Generates the project route */
export function generateProjectRoute(projectId: APITypes.ProjectId): string {
  return generatePath(AppRoutes.ProjectRoute, {
    projectId,
  });
}

/** Generates the project add plan route */
export function generateProjectAddPlanRoute(
  projectId: APITypes.ProjectId
): string {
  return generatePath(AppRoutes.ProjectAddPlanRoute, {
    projectId,
  });
}

/** Generates the account route */
export function generateAccountRoute(accountId: APITypes.UserId): string {
  return generatePath(AppRoutes.AccountRoute, {
    accountId,
  });
}

/** Generates the group route */
export function generateGroupRoute(groupId: APITypes.GroupId): string {
  return generatePath(AppRoutes.GroupRoute, {
    groupId,
  });
}
