import { Divider, ListSubheader } from "@mui/material";
import { HBSelect } from "@components/hb-customs/hb-select";
import { HBMenuItems } from "@components/hb-customs/hb-menu-items";
import { useProductItem } from "@hooks/use-product-item";
import { AdminAPITypes } from "@stellar/api-logic";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  allProductsOfTypeSelector,
  selectedProductIdentifierSelector,
} from "@store/plan-creation/plan-creation-selector";
import { setSelectedProductIdentifier } from "@store/plan-creation/plan-creation-slice";
import {
  BaseProduct,
  ProductIdentifier,
} from "@store/plan-creation/plan-creation-slice-helper-types";

interface Props {
  /** The subject type of the plan */
  planSubjectType: AdminAPITypes.ESubjectType;
}

/** A dropdown to select a product */
export function ProductDropdown({ planSubjectType }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const selectedProductIdentifier = useAppSelector(
    selectedProductIdentifierSelector
  );

  const features = useAppSelector(
    allProductsOfTypeSelector("feature", planSubjectType)
  );
  const bundles = useAppSelector(
    allProductsOfTypeSelector("bundle", planSubjectType)
  );
  const customProducts = useAppSelector(
    allProductsOfTypeSelector("custom", planSubjectType)
  );

  const trialProducts = useAppSelector(
    allProductsOfTypeSelector("trial", planSubjectType)
  );

  const sortedBundles = bundles.reduce<BaseProduct[]>(
    (acc, bundle) =>
      bundle.identifier === AdminAPITypes.EFeatureBundleIdentifier.vpm
        ? [bundle, ...acc]
        : [...acc, bundle],
    []
  );

  const getMenuItem = useProductItem();

  return (
    <HBSelect
      label={
        planSubjectType === AdminAPITypes.ESubjectType.device
          ? "License Type"
          : "Product"
      }
      value={selectedProductIdentifier ?? ""}
      onChange={(event) =>
        dispatch(
          setSelectedProductIdentifier(event.target.value as ProductIdentifier)
        )
      }
      variant="outlined"
      isFullWidth
      isRequired
    >
      {customProducts.length > 0 && (
        <ListSubheader>Sphere XG Plans</ListSubheader>
      )}
      {HBMenuItems({
        menuItemInputs: getMenuItem(customProducts),
      })}
      {customProducts.length > 0 && <Divider />}

      {trialProducts.length > 0 && (
        <ListSubheader>Sphere XG Trial Plans</ListSubheader>
      )}
      {HBMenuItems({
        menuItemInputs: getMenuItem(trialProducts),
      })}
      {trialProducts.length > 0 && <Divider />}

      {sortedBundles.length > 0 && (
        <ListSubheader>
          {planSubjectType === AdminAPITypes.ESubjectType.device
            ? "Available License types"
            : "Bundles"}
        </ListSubheader>
      )}
      {HBMenuItems({
        menuItemInputs: getMenuItem(sortedBundles),
      })}
      {sortedBundles.length > 0 && <Divider />}

      {features.length > 0 && <ListSubheader>Features</ListSubheader>}
      {HBMenuItems({
        menuItemInputs: getMenuItem(features),
      })}
    </HBSelect>
  );
}
