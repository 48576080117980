import { PropsWithChildren, ReactNode, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";

export interface ITabComponent {
  /** The label that is shown on the tab */
  label: string;

  /** The content of the tab */
  content: ReactNode;
}

interface ITabComponentProps {
  /** List of all tabs to be shown */
  tabs: ITabComponent[];

  /** Zero-based index of the tab (in tabs) that should be opened/selected per default */
  defaultTabIndex?: number;
}

/** Manages displaying the tabs and their selection */
export function TabComponent({
  tabs,
  defaultTabIndex,
}: ITabComponentProps): JSX.Element {
  const [currentTab, setCurrentTab] = useState<number>(defaultTabIndex ?? 0);

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={(event, selectedTab: number) => setCurrentTab(selectedTab)}
      >
        {tabs.map((tab, index) => {
          return <Tab disableRipple key={index} label={tab.label} />;
        })}
      </Tabs>

      {/* Content to show for each individual tab */}
      {tabs.map((tab, index) => {
        return (
          <TabPanel key={index} value={currentTab} index={index}>
            {tab.content}
          </TabPanel>
        );
      })}
    </>
  );
}

interface ITabPanelProps {
  /** Index number related to a tab */
  index: number;

  /** Index of the selected tab in a group. Useful to show whether or not a tab should be shown */
  value: number;
}

/** Content of the tab and when to show it */
function TabPanel({
  index,
  value,
  children,
}: PropsWithChildren<ITabPanelProps>): JSX.Element {
  return (
    <Box sx={{ flexGrow: 1 }} role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </Box>
  );
}
