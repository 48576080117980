import { Chip } from "@mui/material";
import { Link } from "react-router-dom";

interface IHBChipProps {
  /** Text to shown on chip */
  label: string;

  /** URL of the link */
  routeTo: string;
}

/**
 * Custom HoloBuilder Chip
 *
 * Wrapper around the material-ui Chip component offering limited settings.
 * Uses the Link component of react-router-dom to navigate to the destination
 * "to" prop actually passes to the Link component provided via component
 */
export function HBChip({ label, routeTo }: IHBChipProps): JSX.Element {
  return (
    <Chip
      sx={{ margin: "5px" }}
      component={Link}
      clickable
      to={routeTo}
      color="primary"
      variant="outlined"
      label={label}
    />
  );
}
