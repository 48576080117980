import { AdminAPITypes } from "@stellar/api-logic";
import { createContext, useContext } from "react";

interface IPlansContext {
  /** List of plans available within this context */
  plans: AdminAPITypes.IPlan[];

  /** Callback to trigger a re-fetching of the plans */
  fetchPlans(): void;
}

export const PlansContext = createContext<IPlansContext | null>(null);

export function usePlansContext(): IPlansContext {
  const context = useContext(PlansContext);

  if (!context) {
    throw new Error("usePlansContext() has to be used within a PlansContext.");
  }

  return context;
}
