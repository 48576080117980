/**
 * Check if the predicate is valid. If it is false, throw an error.
 * This is useful for validating types at runtime and ensuring type safe in the rest of the component, file we use this.
 * It is copied from lotv-monorepo
 *
 * @param predicate The condition to check
 * @param message The message associated to the exception
 */
export function assert(predicate: unknown, message: string): asserts predicate {
  if (!predicate) {
    throw new Error(message);
  }
}
