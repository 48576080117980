import { CoreApi } from "@api/index";
import { ErrorHandlingFunction } from "@components/error-boundary/error-handling-context-types";
import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { useEffect, useState } from "react";

/**
 * Use this hook to fetch the Company for a specific companyId.
 * Returns a tuple of the fetched company and a loading flag.
 * If the loading fails, the fetched company will be undefined.
 */
export function useCompany(
  companyId: APITypes.CompanyId | undefined,
  errorHandler: ErrorHandlingFunction
): [AdminAPITypes.IAdmCompany | undefined, boolean] {
  const [company, setCompany] = useState<AdminAPITypes.IAdmCompany>();
  const [isFetching, setIsFetching] = useState<boolean>(true);

  useEffect(() => {
    async function fetchCompany(companyId: APITypes.CompanyId): Promise<void> {
      setIsFetching(true);

      try {
        setCompany(await CoreApi.V0.ADMIN.getCompanyById(companyId));
      } catch (error) {
        errorHandler("Could not load Company", error);
        setCompany(undefined);
      } finally {
        setIsFetching(false);
      }
    }

    if (companyId !== undefined) {
      fetchCompany(companyId);
    }
  }, [companyId, errorHandler]);

  return [company, isFetching];
}
