import { Grid2, IconButton, Box, Autocomplete, TextField } from "@mui/material";
import { AddButton } from "@components/buttons";
import { HBEnumMenuItems } from "@components/hb-customs/hb-menu-items";
import { AdminAPITypes, CoreAPITypes } from "@stellar/api-logic";
import { useEffect, useState } from "react";
import { Constraint, AllowedUserRoles } from "@utils/constraint";
import { HBSelect } from "@components/hb-customs/hb-select";
import { HBNumberInput } from "@components/hb-customs/hb-number-input";
import {
  formatConstraintType,
  formatUserRoleString,
  GIGA_BYTE_SIZE,
} from "@utils/data-display";
import { Clear } from "@mui/icons-material";
import { activeConstraintsSelector } from "@store/plan-creation/plan-creation-selector";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { setActiveConstraints } from "@store/plan-creation/plan-creation-slice";

interface IPlanLimitFormProps {
  planSubjectType: AdminAPITypes.ESubjectType;
}

/** Define the structure of props for the updateConstraint function */
interface IUpdateConstraintProps {
  /** The index of the constraint in the list */
  index: number;

  /** The maximum value allowed for this constraint */
  maxValue?: number;

  /** The current value of the constraint */
  currentValue?: number;

  /** The type of the constraint */
  type?: AdminAPITypes.EConstraintType;

  /** The user roles selected for this constraint by the user*/
  selectedRoles?: AdminAPITypes.IConstraintUserRoles;
}

export function PlanLimitForm({
  planSubjectType,
}: IPlanLimitFormProps): JSX.Element {
  const activeConstraints = useAppSelector(activeConstraintsSelector);
  const dispatch = useAppDispatch();
  const [selectedRoles, setSelectedRoles] =
    useState<AdminAPITypes.IConstraintUserRoles>({
      company: [],
      resource: [],
    });

  function updateConstraint({
    index,
    maxValue,
    currentValue,
    type,
    selectedRoles,
  }: IUpdateConstraintProps): void {
    const updatedConstraints = activeConstraints.map((constraint, i) => {
      if (i !== index) {
        return constraint;
      }
      const isUserRoleConstraint =
        (type ?? constraint.type) === AdminAPITypes.EConstraintType.userRole;
      // Set 'roles' to 'selectedRoles' or the existing roles if it's a 'userRole' constraint;
      // otherwise, set it to 'undefined' since it doesn't apply to non-'userRole' constraints
      const roles = isUserRoleConstraint
        ? selectedRoles ?? constraint.data.roles
        : undefined;

      return Constraint.createPayloadForConstraintWithValue(
        type ?? constraint.type,
        maxValue ?? constraint.data.max,
        currentValue ?? constraint.data.current,
        roles
      );
    });
    dispatch(setActiveConstraints(updatedConstraints));
  }

  function removeActiveConstraintByIndex(index: number): void {
    const updatedConstraints = activeConstraints.filter(
      (constraint, i) => i !== index
    );
    dispatch(setActiveConstraints(updatedConstraints));
  }

  function addConstraintField(): void {
    if (
      activeConstraints.length <
      Constraint.availableConstraintsForSubjectTypeAsArray(planSubjectType)
        .length
    ) {
      const updatedConstraints = [
        ...activeConstraints,
        Constraint.createPayloadForConstraintWithValue(
          getNextUnusedConstraintType(activeConstraints, planSubjectType),
          0,
          0
        ),
      ];
      dispatch(setActiveConstraints(updatedConstraints));
    }
  }

  /** Calculate and update the max value */
  function calculateMaxValue(
    newLimitValue: number,
    constraintType: AdminAPITypes.EConstraintType,
    index: number
  ): void {
    // 0 is used intentionally for the "null" state of the input
    const selectedMax = newLimitValue || 0;
    // Convert storage size from gigabytes to bytes, as the backend expects sizes in bytes
    const maxValue =
      constraintType === AdminAPITypes.EConstraintType.storage
        ? selectedMax * GIGA_BYTE_SIZE
        : selectedMax;

    // Update the constraint with the calculated max value
    updateConstraint({ index, maxValue });
  }

  // Make sure that at least on constraint is shown while this component is rendered
  useEffect(() => {
    if (activeConstraints.length === 0) {
      const updatedConstraints = [
        Constraint.createPayloadForConstraintWithValue(
          getNextUnusedConstraintType(activeConstraints, planSubjectType),
          0,
          0
        ),
      ];
      dispatch(setActiveConstraints(updatedConstraints));
    }
  }, [activeConstraints, dispatch, planSubjectType]);

  return (
    <Grid2 container spacing={1}>
      {activeConstraints.map((value, index) => {
        const constraintMaxValue = Constraint.getMaxValueOfConstraint(
          activeConstraints[index]
        );
        const currentConstraint = activeConstraints[index];
        const isStorageType =
          currentConstraint.type === AdminAPITypes.EConstraintType.storage;
        const isUserRoleType =
          currentConstraint.type === AdminAPITypes.EConstraintType.userRole;
        return (
          <Grid2
            container
            spacing={1}
            key={index}
            wrap="wrap"
            sx={{ width: "100%" }}
          >
            {/* plan limit type */}
            <Grid2 size={{ xs: 12, sm: 5 }}>
              <HBSelect
                label="Limit Type"
                value={currentConstraint.type}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  updateConstraint({
                    index,
                    maxValue: 0,
                    type: event.target.value as AdminAPITypes.EConstraintType,
                    selectedRoles: { company: [], resource: [] },
                  });
                }}
                variant="outlined"
                isFullWidth
                isRequired
              >
                {HBEnumMenuItems({
                  fromEnum:
                    Constraint.availableConstraintsForSubjectType(
                      planSubjectType
                    ),
                  formatLabelWith: formatConstraintType,
                  disabledValues: activeConstraints.map(
                    (constraint) => constraint.type
                  ),
                })}
              </HBSelect>
            </Grid2>
            {/* plan limit */}
            <Grid2 size={{ xs: 12, sm: 5 }}>
              <HBNumberInput
                label="Plan Limit"
                value={constraintMaxValue || null}
                onChange={(newLimitValue: number): void => {
                  calculateMaxValue(
                    newLimitValue,
                    currentConstraint.type,
                    index
                  );
                }}
                minValue={1}
                isIntegerValue
                isFullWidth
                isRequired
              />
            </Grid2>

            {/* Display unit size label */}
            {/* This grid item shows the 'GB' label only if the selected constraint type is 'Storage'. */}
            <Grid2
              size={{ xs: 12, sm: 1 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ fontSize: "20px" }}>{isStorageType ? "GB" : ""}</Box>
            </Grid2>
            {/* Remove button */}
            <Grid2
              size={{ xs: 12, sm: 1 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                sx={{ mt: "18px" }}
                onClick={() => removeActiveConstraintByIndex(index)}
                size="large"
              >
                <Clear />
              </IconButton>
            </Grid2>

            {isUserRoleType && (
              <Grid2 container spacing={2} sx={{ width: "100%" }}>
                <Grid2 size={{ xs: 12, sm: 6 }}>
                  <Autocomplete
                    multiple
                    options={AllowedUserRoles.allResourceRoles}
                    value={
                      currentConstraint.data.roles?.resource ||
                      selectedRoles.resource
                    }
                    onChange={(event, newValue) => {
                      setSelectedRoles((prev) => ({
                        ...prev,
                        resource: newValue,
                      }));
                      updateConstraint({
                        index,
                        selectedRoles: {
                          company: currentConstraint.data.roles?.company ?? [],
                          resource: newValue,
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Project Roles"
                        variant="outlined"
                        required={true}
                      />
                    )}
                    getOptionLabel={(
                      option: CoreAPITypes.EUserProjectRoleUpdated
                    ) => formatUserRoleString(option)}
                    isOptionEqualToValue={(option, value) => option === value}
                    sx={{ width: "100%" }}
                  />
                </Grid2>
                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <Autocomplete
                    multiple
                    options={AllowedUserRoles.allCompanyRoles}
                    value={
                      currentConstraint.data.roles?.company ||
                      selectedRoles.company
                    }
                    onChange={(event, newValue) => {
                      setSelectedRoles((prev) => ({
                        ...prev,
                        company: newValue,
                      }));
                      updateConstraint({
                        index,
                        selectedRoles: {
                          company: newValue,
                          resource:
                            currentConstraint.data.roles?.resource ?? [],
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Company Roles"
                        variant="outlined"
                        required={true}
                      />
                    )}
                    getOptionLabel={(
                      option: CoreAPITypes.EUserCompanyRoleUpdated
                    ) => formatUserRoleString(option)}
                    isOptionEqualToValue={(option, value) => option === value}
                    sx={{ width: "100%" }}
                  />
                </Grid2>
              </Grid2>
            )}
          </Grid2>
        );
      })}

      {/* Show the add button only if not all constraints have been added yet */}
      {activeConstraints.length <
        Constraint.availableConstraintsForSubjectTypeAsArray(planSubjectType)
          .length && (
        <Grid2>
          <AddButton type="text" onClick={addConstraintField}>
            Add Plan Limit
          </AddButton>
        </Grid2>
      )}
    </Grid2>
  );
}

function getNextUnusedConstraintType(
  activeConstraints: AdminAPITypes.CreateConstraintPayload[],
  planSubjectType: AdminAPITypes.ESubjectType
): AdminAPITypes.EConstraintType {
  for (const constraintType of Constraint.availableConstraintsForSubjectTypeAsArray(
    planSubjectType
  )) {
    if (
      !activeConstraints
        .map((activeConstraint) => activeConstraint.type)
        .includes(constraintType)
    ) {
      return constraintType;
    }
  }
  throw new Error("All constraint types are already in use");
}
