/**
 * Adds a numbered environment to the URL.
 * E.g. https://sphere.holobuilder.com -> https://dev1.sphere.holobuilder.com
 *
 * @param url The original url
 * @param numberedEnv The numbered environment to add
 * @returns New url with the numbered environment added
 */
export function addNumberedEnv(
  url: string,
  numberedEnv?: string | null
): string {
  if (!numberedEnv) {
    return url;
  }

  const urlObj = new URL(url);

  // Add numbered environment only to sphere.*.holobuilder.com or sphere.*.eu URLs
  if (urlObj.hostname.match(/entry|sphere|admin.*\.holobuilder\.(com|eu)/)) {
    const parts = urlObj.hostname.split(".");
    parts[0] = `${numberedEnv}.${parts[0]}`;
    urlObj.hostname = parts.join(".");
  }

  return urlObj.toString();
}

/** Gets the URL for the init route of the Admin Panel API. */
export function getAdminPanelApiUrl(
  sphereEntryPageApi: string,
  numberedEnv: string | null
): string {
  return addNumberedEnv(sphereEntryPageApi, numberedEnv);
}

/**
 * Returns the pathname from the URL with the ids replaced by the key names.
 *
 * @param url A URL with ids in the path, e.g. /project/1234
 * @param appParams An object with the key names and their corresponding ids, e.g. { projectId: 1234 }
 * @returns The masked pathname, e.g. /project/:projectId
 */
export function getPathnameFromUrl(
  originalPathname: string,
  appParams: Partial<Record<string, string>>
): string {
  let pathname = originalPathname;
  let key: keyof typeof appParams;
  for (key in appParams) {
    // Replace all ids in the path with the key name
    // e.g. /project/1234 -> /project/:projectId
    if (key in appParams && key.endsWith("Id")) {
      pathname = pathname.replace(`${appParams[key]}`, `:${key}`);
    }
  }

  return pathname;
}

/**
 * Returns the query parameters from the search string.
 *
 * @param search The search string from the URL, e.g. ?key1=value1&key2=value2
 * @returns The query parameters, e.g. ["key1", "key2"]
 */
export function getQueryParamsFromSearch(search: string): string[] {
  if (!search || search === "?") {
    return [];
  }
  const searchSplit = search.split("?");
  const queryParams = searchSplit.length > 1 ? searchSplit[1] : searchSplit[0];
  return queryParams.split("&").map((param) => param.split("=")[0]);
}
