import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { NavBar } from "@components/nav-bar";
import { hbTheme } from "@styles/hb-theme";
import "typeface-roboto";
import { useLoggedInUser } from "@hooks/use-logged-in-user";
import { HBSpinner } from "@components/hb-customs/hb-spinner";
import { useAppNavigation } from "@router/use-app-navigation";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useTrackPageLoad } from "@utils/hooks/use-track-page-load";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";

/** The root component of the admin-panel */
export function App(): JSX.Element {
  const [loggedInUser, isFetching] = useLoggedInUser();
  const { navigateToWorkspacePage } = useAppNavigation();
  // Initializes the tracking of the user in the entry page API
  useTrackPageLoad();

  const content = useMemo(() => {
    if (isFetching) {
      return <HBSpinner />;
    }
    if (loggedInUser) {
      return (
        <>
          <header>
            <NavBar profileImageUrl={loggedInUser.profileImageUrl} />
          </header>
          <main>
            <Outlet />
          </main>
        </>
      );
    }

    return <>{navigateToWorkspacePage()}</>;
  }, [isFetching, loggedInUser, navigateToWorkspacePage]);

  return (
    <ThemeProvider theme={hbTheme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
