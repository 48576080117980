import { TableCell } from "@mui/material";
import { HBSortableTable } from "@components/tables/hb-sortable-table";
import { AdminAPITypes } from "@stellar/api-logic";
import { isCompanyContext } from "@utils/type-guards";
import { formatProjectArea, getAccountDisplayName } from "@utils/data-display";
import { Compare } from "@utils/compare";
import { SortableColumn } from "@components/tables/hb-table-types";
import { AccountLink, ProjectLink } from "@components/links/internal-links";
import { DateTimeUtils } from "@stellar/web-core";
import { HBChip } from "@components/hb-customs/hb-chip";
import { generateCompanyRoute } from "@router/router-helper";

interface IHBProjectTable {
  projects: AdminAPITypes.IAdmProject[];
}

export function HBProjectTable({ projects }: IHBProjectTable): JSX.Element {
  const columns: SortableColumn<AdminAPITypes.IAdmProject>[] = [
    { id: "name", label: "Name" },
    { id: "id", label: "Project ID" },
    { id: "area", label: "Area", compareFunction: Compare.projectsByArea },
    {
      id: "company",
      label: "Company",
      compareFunction: Compare.projectsByCompany,
    },
    {
      id: "projectManager",
      label: "User",
      compareFunction: Compare.projectsByProjectManager,
    },
    { id: "createdAt", label: "Created" },
  ];

  return (
    <HBSortableTable
      renderTableCells={(project: AdminAPITypes.IAdmProject) => (
        <ProjectTableRowCells project={project} />
      )}
      columns={columns}
      tableData={projects}
    />
  );
}

function ProjectTableRowCells({
  project,
}: {
  project: AdminAPITypes.IAdmProject;
}): JSX.Element {
  return (
    <>
      <TableCell component="th" scope="row" padding="none">
        <ProjectLink projectId={String(project.id)}>{project.name}</ProjectLink>
      </TableCell>
      <TableCell>{project.id}</TableCell>
      <TableCell>{formatProjectArea(project.area)}</TableCell>
      <TableCell>
        {isCompanyContext(project.context) && (
          <HBChip
            label={project.context.company.name}
            routeTo={generateCompanyRoute(project.context.company.id)}
          />
        )}
      </TableCell>

      <TableCell>
        <AccountLink accountId={project.projectManager.identity}>
          {getAccountDisplayName(project.projectManager)}
        </AccountLink>
      </TableCell>

      <TableCell>{DateTimeUtils.format({ date: project.createdAt })}</TableCell>
    </>
  );
}
