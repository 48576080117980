import { Grid2, GridSize } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface IHBGridItemProps {
  /** Defines the number of grids the component is going to use in xs format */
  width: GridSize;
}

/**
 * Custom HoloBuilder Grid Item component.
 * Wrapper around the material-ui Grid Item component offering limited settings.
 *
 * @param width width of the grid item. Width by default is true making the items equitably share the available space
 */
export function HBGridItem({
  children,
  width,
}: PropsWithChildren<IHBGridItemProps>): JSX.Element | null {
  if (!React.Children.count(children)) {
    return null;
  }

  return <Grid2 size={{ xs: width }}>{children}</Grid2>;
}
