import { Link } from "@mui/material";
import { runtimeConfig } from "@src/runtime-config";

const shouldShowPRLink =
  runtimeConfig.pullRequestId &&
  runtimeConfig.pullRequestId !== "0" &&
  runtimeConfig.pullRequestUrl &&
  runtimeConfig.pullRequestUrl !== "0";

const shouldShowJiraLink =
  runtimeConfig.jiraTicketUrl &&
  runtimeConfig.jiraTicketUrl !== "0" &&
  runtimeConfig.jiraTicketId &&
  runtimeConfig.jiraTicketId !== "0";

const maxCommitHashLength = 8;
const commitSimplifiedHash = runtimeConfig.commitId.slice(
  0,
  maxCommitHashLength
);

export function AboutTooltipInfo(): JSX.Element {
  const version = <>Version: {runtimeConfig.appVersion}</>;

  /**
   * !: We don't want to show information about commit hash, Jira ticket, and PR link in production.
   */
  if (
    runtimeConfig.appEnv.toLowerCase().includes("prod") ||
    window.location.hostname === "localhost"
  ) {
    return <var>{version}</var>;
  }

  return (
    <>
      {/* App version */}
      <p>
        <var>{version}</var>
      </p>
      {/* Commit SHA */}
      <p>
        Commit SHA{" "}
        <Link href={runtimeConfig.commitUrl} target="_blank">
          <var>{commitSimplifiedHash}</var>
        </Link>
      </p>
      {/* Link to the Jira Ticket */}
      {shouldShowJiraLink && (
        <p>
          Jira Ticket{" "}
          <Link href={runtimeConfig.jiraTicketUrl} target="_blank">
            <var>{runtimeConfig.jiraTicketId}</var>
          </Link>
        </p>
      )}
      {/* Link to the Pull Request */}
      {shouldShowPRLink && (
        <p>
          Pull Request Id{" "}
          <Link href={runtimeConfig.pullRequestUrl} target="_blank">
            <var>{runtimeConfig.pullRequestId}</var>
          </Link>
        </p>
      )}
    </>
  );
}
