import { AdminAPITypes } from "@stellar/api-logic";
import { BreadcrumbsData } from "@components/breadcrumbs/breadcrumbs-data";
import { HBBreadcrumbs } from "@components/breadcrumbs/hb-breadcrumbs";

interface ICompanyBreadcrumbsProps {
  /** Object represents current company to generate breadcrumb out of it */
  company: AdminAPITypes.IAdmCompany;
}

/**
 * Display breadcrumbs for a company
 */
export function CompanyBreadcrumbs({
  company,
}: ICompanyBreadcrumbsProps): JSX.Element {
  return (
    <HBBreadcrumbs breadcrumbsData={[BreadcrumbsData.forCompany(company)]} />
  );
}
