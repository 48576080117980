import { AdminAPITypes, APITypes } from "@stellar/api-logic";
import { runtimeConfig } from "@src/runtime-config";
import { SubjectEntityIdTypes, SubjectEntityTypes } from "@utils/utility-types";
import {
  generateAccountRoute,
  generateCompanyAddDevicePlanRoute,
  generateCompanyAddPlanRoute,
  generateCompanyAddProjectPlanRoute,
  generateCompanyRoute,
  generateProjectAddPlanRoute,
  generateProjectRoute,
} from "@router/router-helper";
import { useNavigate } from "react-router-dom";

interface IProjectCreationForCompany {
  /** The ID of the company in which the project is created for */
  companyId: APITypes.CompanyId;

  /** The ID of the group in which the project is created for */
  groupId: APITypes.GroupId;
}

/**
 * Return type of useNavigation hook
 * Consist of different functions each navigating to a page in Admin Panel
 */
interface UseAppNavigation {
  /** Navigate to the plan creation page for the different entities */
  navigateToPlanCreation(
    subjectType: SubjectEntityTypes,
    subjectId: SubjectEntityIdTypes
  ): void;

  /** Navigate to the project creation page for different entities */
  navigateToProjectCreation(subject: IProjectCreationForCompany): void;

  /** Navigate to the profile page for different entities */
  navigateToProfilePage(
    subjectType: AdminAPITypes.ESubjectType,
    subjectId: SubjectEntityIdTypes
  ): void;

  /** Navigate to workspace page by making a new http request */
  navigateToWorkspacePage(): void;
}

/**
 * A custom hook that calls the history and wraps different navigation functions
 * Returns functions that can be called to navigate to different places
 */
export function useAppNavigation(): UseAppNavigation {
  const navigate = useNavigate();

  function navigateToPlanCreation(
    subjectType: SubjectEntityTypes,
    subjectId: SubjectEntityIdTypes
  ): void {
    switch (subjectType) {
      case AdminAPITypes.ESubjectType.company: {
        navigate(generateCompanyAddPlanRoute(subjectId));
        break;
      }
      case AdminAPITypes.ESubjectType.device: {
        navigate(generateCompanyAddDevicePlanRoute(subjectId));
        break;
      }
      case AdminAPITypes.ESubjectType.project: {
        navigate(generateProjectAddPlanRoute(subjectId));
        break;
      }

      default: {
        throw new Error(
          `navigateToPlanCreation: Unsupported subjectType ${subjectType}`
        );
      }
    }
  }

  function navigateToProjectCreation(
    subject: IProjectCreationForCompany
  ): void {
    navigate(
      generateCompanyAddProjectPlanRoute(subject.companyId, subject.groupId)
    );
  }

  function navigateToProfilePage(
    profileType: AdminAPITypes.ESubjectType,
    profileId: SubjectEntityIdTypes
  ): void {
    switch (profileType) {
      case AdminAPITypes.ESubjectType.project: {
        navigate(generateProjectRoute(profileId));
        break;
      }
      case AdminAPITypes.ESubjectType.company: {
        navigate(generateCompanyRoute(profileId));
        break;
      }
      case AdminAPITypes.ESubjectType.device: {
        navigate(generateCompanyRoute(profileId));
        break;
      }
      case AdminAPITypes.ESubjectType.user: {
        navigate(generateAccountRoute(profileId as string));
        break;
      }
      default: {
        // eslint-disable-next-line no-console -- No need to show this to user, even though worth share it in console
        console.warn(
          `navigateToProfilePage not implemented for ${profileType} yet`
        );
        break;
      }
    }
  }

  function navigateToWorkspacePage(): void {
    window.location.href = runtimeConfig.urls.entryPageUrl;
  }

  return {
    navigateToPlanCreation,
    navigateToProjectCreation,
    navigateToProfilePage,
    navigateToWorkspacePage,
  };
}
