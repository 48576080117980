import { HBCompanyTable } from "@pages/home/hb-table/hb-company-table";
import { HBAccountTable } from "@pages/home/hb-table/hb-account-table";
import { HBProjectTable } from "@pages/home/hb-table/hb-project-table";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid2,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { HBSpinner, HBSpinnerInline } from "@components/hb-customs/hb-spinner";
import { EAllSearchOptions, SearchState } from "@hooks/use-search-types";
import { Counter } from "@components/counter";
import { Color } from "@styles/default-colors";
import { PropsWithChildren } from "react";

interface IProps {
  results: SearchState;
}

export function SearchResults({ results }: IProps): JSX.Element {
  if (areAllFetching(results)) {
    return <HBSpinner />;
  }

  if (!includesAnyResult(results)) {
    return <EmptyResults />;
  }

  const accountResults = results[EAllSearchOptions.account];
  const companyResults = results[EAllSearchOptions.company];
  const projectResults = results[EAllSearchOptions.project];

  // TODO: once tables are refactored, no need to create specific "accordions"
  // this would also further simply usage by removing the need to import EAllSearchOptions
  return (
    <>
      {
        <SearchResultAccordion
          title="Users"
          numberOfResults={accountResults.result.length}
          isFetching={accountResults.isFetching}
        >
          <HBAccountTable accounts={accountResults.result} />
        </SearchResultAccordion>
      }

      {
        <SearchResultAccordion
          title="Companies"
          numberOfResults={companyResults.result.length}
          isFetching={companyResults.isFetching}
        >
          <HBCompanyTable companies={companyResults.result} />
        </SearchResultAccordion>
      }

      {
        <SearchResultAccordion
          title="Projects"
          numberOfResults={projectResults.result.length}
          isFetching={projectResults.isFetching}
        >
          <HBProjectTable projects={projectResults.result} />
        </SearchResultAccordion>
      }
    </>
  );
}

/** All data which we use to display for each accordion header of the search results */
interface ISearchResultAccordionProps {
  /** The name to display in the title */
  title: string;

  /** The exact number of search results obtained */
  numberOfResults: number;

  /** Whether the specific request to display here is still running */
  isFetching: boolean;
}

function SearchResultAccordion({
  title,
  numberOfResults,
  isFetching,
  children,
}: PropsWithChildren<ISearchResultAccordionProps>): JSX.Element {
  return (
    <Accordion
      sx={{
        mx: "20px",
        "&.Mui-expanded": {
          backgroundColor: Color.grey15,
          mx: "20px",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`${title}-content`}
        id={`${title}-header`}
      >
        {isFetching ? (
          <HBSpinnerInline />
        ) : (
          <Counter counter={numberOfResults} />
        )}
        <span>{title}</span>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

/** Centered text to display if search results are empty */
function EmptyResults(): JSX.Element {
  return (
    <Grid2
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid2 size={{ xs: 3 }}>
        <span>No results</span>
      </Grid2>
    </Grid2>
  );
}

function areAllFetching(results: SearchState): boolean {
  return (
    results[EAllSearchOptions.account].isFetching &&
    results[EAllSearchOptions.company].isFetching &&
    results[EAllSearchOptions.project].isFetching
  );
}

function includesAnyResult(results: SearchState): boolean {
  return !!(
    results[EAllSearchOptions.account].result.length ||
    results[EAllSearchOptions.company].result.length ||
    results[EAllSearchOptions.project].result.length
  );
}
