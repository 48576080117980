import { Box, useTheme } from "@mui/material";
import { Add, GetApp } from "@mui/icons-material";
import { HBButton, IHBButtonProps } from "@components/hb-customs/hb-button";
import { PropsWithChildren, useState } from "react";
import { HBSnackbar } from "@components/hb-customs/hb-snackbar";
import { TeamStellarReportIssueChannel } from "@components/links/external-links";
import { Color } from "@utils/styles/default-colors";

type ClipboardButtonProps = Pick<IHBButtonProps, "onClick">;

export function ClipboardButton({
  onClick: handleClick,
}: ClipboardButtonProps): JSX.Element {
  return (
    <HBButton sx={{ width: "251px", height: "40px" }} onClick={handleClick}>
      Copy to Clipboard
    </HBButton>
  );
}

type AddButtonProps = Pick<IHBButtonProps, "onClick" | "isDisabled" | "type">;

export function AddButton({
  children,
  onClick,
  isDisabled,
  type,
}: PropsWithChildren<AddButtonProps>): JSX.Element {
  const theme = useTheme();

  return (
    <HBButton
      startIcon={<Add sx={{ color: theme.palette.info.main }} />}
      {...{
        onClick,
        isDisabled,
        type,
      }}
    >
      {children}
    </HBButton>
  );
}

type CSVExportButtonProps = Pick<IHBButtonProps, "onClick" | "isDisabled">;

/**
 * Adds a button with download icon used for exporting csv data
 */
export function CSVExportButton({
  onClick: handleClick,
  isDisabled,
}: CSVExportButtonProps): JSX.Element {
  const theme = useTheme();

  return (
    <HBButton
      startIcon={<GetApp sx={{ color: theme.palette.info.main }} />}
      onClick={handleClick}
      isDisabled={isDisabled}
    >
      Export as CSV
    </HBButton>
  );
}

/** Create project button for accounts */
export function CreateAccountProjectButton(): JSX.Element {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  return (
    <>
      <HBSnackbar
        isOpen={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        sx={{ backgroundColor: Color.red50 }}
        buttonSx={{ color: Color.black }}
      >
        <Box
          component="div"
          sx={{
            color: Color.black,
          }}
        >
          This function is deprecated. You can only create projects for
          companies by going to the group tab in company profile page. If there
          is any issue, please report it in our team channel:{" "}
          <TeamStellarReportIssueChannel>Link</TeamStellarReportIssueChannel>
        </Box>
      </HBSnackbar>
      <AddButton onClick={() => setIsSnackbarOpen(true)}>
        Create Project
      </AddButton>
    </>
  );
}
