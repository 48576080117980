import { Tooltip, TooltipProps, useTheme } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

type HBHelpTooltipProps = Pick<HBTooltipProps, "children" | "className">;

/**
 * Component showing a question mark icon that will show the provided children on hover
 *
 * Interface reverses usage of children in HBTooltip to allow to pass the on-hover content as children
 * instead of using a prop, which makes the usage of HBHelpTooltip more "React-y"
 */
export function HBHelpTooltip({
  children,
  className,
}: HBHelpTooltipProps): JSX.Element {
  const theme = useTheme();

  return (
    <HBTooltip className={className} title={children}>
      <HelpIcon
        sx={{
          color: theme.palette.info.light,
          marginLeft: "5px",
        }}
      />
    </HBTooltip>
  );
}

type HBTooltipProps = Pick<
  TooltipProps,
  | "className"

  /**
   * The content to render that will show the "title" on hover
   * Can't use React.PropsWithChildren here because Tooltip expects NonNullable<React.ReactNode> for `title`
   */
  | "children"

  /** The content to show when hovering over the children */
  | "title"
>;

/**
 * Component showing the provided children that will show the provided title on hover
 */
export function HBTooltip({ children, title }: HBTooltipProps): JSX.Element {
  if (!title) {
    return children;
  }

  return (
    <Tooltip title={title} placement="bottom-start" arrow>
      <span>
        {/* span needed for ref forwarding of Tooltip */}
        {/* See: https://material-ui.com/guides/composition/#caveat-with-refs */}
        {children}
      </span>
    </Tooltip>
  );
}
