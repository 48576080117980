import { CompanyBreadcrumbs } from "@components/breadcrumbs/company-breadcrumbs";
import { ICompanyProjectCreation } from "@pages/create-project/project-creation-context-types";

interface ICreateProjectBreadcrumbProps {
  /** The subject of the project which it belongs to */
  subject: ICompanyProjectCreation;
}

/**
 * Decide what type of breadcrumb to show depending on the subject type
 */
export function CreateProjectBreadcrumb({
  subject,
}: ICreateProjectBreadcrumbProps): JSX.Element {
  return <CompanyBreadcrumbs company={subject.company} />;
}
