import { AdminAPITypes } from "@stellar/api-logic";
import { formatPlanName } from "@utils/data-display";

/** All the plans that should be shown next to the main plan in plan table */
const ADDITIONAL_PLAN_FEATURES = {
  [AdminAPITypes.EFeatureBundleIdentifier.flash]: "Flash",
  [AdminAPITypes.EFeatureBundleIdentifier.faroStream]: "Stream",
};

/** Returns a name to identify a plan in table. It will add the name of custom features to the end of actual one */
export function formatPlanTableName(plan: AdminAPITypes.IPlan): string {
  const additionalFeaturesToDisplay = plan.featureBundles.reduce(
    (acc, bundle) => {
      for (const [key, value] of Object.entries(ADDITIONAL_PLAN_FEATURES)) {
        if (bundle.identifier === key) {
          acc = `${acc} - ${value}`;
        }
      }
      return acc;
    },
    ""
  );

  return `${formatPlanName(plan)}${additionalFeaturesToDisplay}`;
}
