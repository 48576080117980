// represents all options we can use to start a search
import { AdminAPITypes } from "@stellar/api-logic";

/** Represents all entities we can search for (used in UI, not to create requests) */
export enum EAllSearchOptions {
  account = "Users",
  company = "Companies",
  project = "Projects",

  // Disabled because those are currently not supported
  // group = "Group",
  // domain = "Domain",
}

/** Object represents result, internally objects for better robustness to extension */
export type SearchState = {
  [EAllSearchOptions.account]: ISearchResult<AdminAPITypes.IAdmUser>;
  [EAllSearchOptions.project]: ISearchResult<AdminAPITypes.IAdmProject>;
  [EAllSearchOptions.company]: ISearchResult<AdminAPITypes.IAdmCompany>;
};

/** Return of each specific search request */
interface ISearchResult<T> {
  result: T[];
  isFetching: boolean;
}
