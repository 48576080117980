import { Grid2 } from "@mui/material";
import { HBDatePicker } from "@components/hb-customs/hb-date-picker";
import { DateTimeUtils } from "@stellar/web-core";
import { HBNumberInput } from "@components/hb-customs/hb-number-input";

interface IProps {
  /** Start date and its corresponding setter */
  startDate: Date;

  /** End date and its corresponding setter */
  endDate: Date;

  /** Handler when the start date changes */
  onStartDateChanged: (newDate: Date) => void;

  /** Handler when the end date changes */
  onEndDateChanged: (newDate: Date) => void;
}

/** Date range form. It includes start date, end date, and duration. */
export function HBDateRangePicker({
  startDate,
  onStartDateChanged,
  endDate,
  onEndDateChanged,
}: IProps): JSX.Element {
  // Makes sure the startDate timestamp is set to the start of the day
  function handleStartDateChange(newStartDate: Date | null): void {
    if (newStartDate) {
      onStartDateChanged(newStartDate);
    }
  }

  /** Makes sure the endDate timestamp is set to the end of the day */
  function handleEndDateChange(newEndDate: Date | null): void {
    if (newEndDate) {
      onEndDateChanged(newEndDate);
    }
  }

  function handleDaysChange(newDays: number | null): void {
    if (newDays !== null) {
      handleEndDateChange(
        DateTimeUtils.addDays({
          date: startDate,
          days: newDays - 1,
        })
      );
    }
  }

  const diffBetweenDatesInDays =
    DateTimeUtils.diffBetweenTwoDatesInDays(endDate, startDate) + 1;

  return (
    <Grid2 container spacing={1}>
      <Grid2 size={{ xs: 7 }}>
        {/* Start date */}
        <HBDatePicker
          label="Start Date"
          value={startDate}
          handleOnChange={handleStartDateChange}
        />
      </Grid2>
      <Grid2 size={{ xs: 5 }}>
        {/* Duration */}
        <HBNumberInput
          label="Days"
          value={diffBetweenDatesInDays}
          onChange={handleDaysChange}
          minValue={1}
          isRequired
          isIntegerValue
          isFullWidth
        />
      </Grid2>
      <Grid2 size={{ xs: 7 }}>
        {/* End date */}
        <HBDatePicker
          label="End Date"
          value={endDate}
          handleOnChange={handleEndDateChange}
          minDate={startDate}
          minDateMessage={
            endDate < startDate
              ? "End date should not be before start date"
              : ""
          }
        />
      </Grid2>
    </Grid2>
  );
}
