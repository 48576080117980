import { AdminAPITypes, CoreAPITypes } from "@stellar/api-logic";

export const AccountUtils = {
  /**
   * Returns the first company where the user has a managing role (enterprise admin, group manager or project manager)
   */
  getCompanyWithManagingRole(
    account: AdminAPITypes.IAdmUser
  ): AdminAPITypes.IAdmCompany | undefined {
    return account.companies.find(
      (company) =>
        company.role === CoreAPITypes.EUserCompanyRole.companyExecutive ||
        company.role === CoreAPITypes.EUserCompanyRole.companyManager ||
        company.role === CoreAPITypes.EUserCompanyRole.projectManager
    );
  },

  /**
   * Returns true if the account is not created yet (it is only linked to a pending invite)
   * The account is not created yet, if account's identity is equal to the email address
   */
  isAccountCreated(account: AdminAPITypes.IAdmUser): boolean {
    return account.identity !== account.email;
  },
};
