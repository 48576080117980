import { createSelector } from "@reduxjs/toolkit";
import { AdminAPITypes } from "@stellar/api-logic";
import { RootState } from "@store/store-helper";
import { PlanCreationAdapter } from "@store/plan-creation/plan-creation-slice";
import {
  BaseProduct,
  ProductIdentifier,
} from "@store/plan-creation/plan-creation-slice-helper-types";
import { PlanCreationState } from "@store/plan-creation/plan-creation-slice";

export const planSubjectSelector: (
  state: RootState
) => PlanCreationState["planSubject"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.planCreation.planSubject;
  }
);

/** Returns all the stored products */
export const allProductsSelector: (state: RootState) => BaseProduct[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return PlanCreationAdapter.getSelectors().selectAll(state.planCreation);
    }
  );

/** Returns the selected product identifier */
export const selectedProductIdentifierSelector: (
  state: RootState
) => ProductIdentifier | null = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.planCreation.selectedProductIdentifier;
  }
);

/** Returns the selected product */
export const selectedProductSelector: (state: RootState) => BaseProduct | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedProductIdentifier =
        selectedProductIdentifierSelector(state);
      return getProductByIdentifierSelector(
        selectedProductIdentifier ?? undefined
      )(state);
    }
  );

/** Gets a product by providing the identifier */
export function getProductByIdentifierSelector(
  productIdentifier: ProductIdentifier | undefined
): (state: RootState) => BaseProduct | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!productIdentifier) {
        return null;
      }
      return (
        PlanCreationAdapter.getSelectors().selectById(
          state.planCreation,
          productIdentifier
        ) ?? null
      );
    }
  );
}

/** Get all the products of provided type from the store for the provided planSubjectType */
export function allProductsOfTypeSelector(
  productType: BaseProduct["type"],
  planSubjectType: AdminAPITypes.ESubjectType
): (state: RootState) => BaseProduct[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const allProductsOfType = allProductsSelector(state).filter(
        (product) => product.type === productType
      );

      /** All the products of type productType that have planSubjectType as their scope */
      return allProductsOfType.filter(({ identifier }) => {
        const availableScope =
          getProductByIdentifierSelector(identifier)(state)?.scope;
        if (availableScope === undefined) {
          return false;
        }
        return Object.keys(availableScope).includes(planSubjectType);
      });
    }
  );
}

/** Whether the products are fetching or not */
export const isFetchingProductsSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.planCreation.isFetching;
    }
  );

/** Returns true if the selected product should have limits */
export function shouldSelectedProductHaveLimits(
  planSubjectType: AdminAPITypes.ESubjectType
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedProduct = selectedProductSelector(state);
      if (selectedProduct === null) {
        return false;
      }
      return selectedProduct.scope[planSubjectType] ?? false;
    }
  );
}

export const activeConstraintsSelector: (
  state: RootState
) => AdminAPITypes.CreateConstraintPayload[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.planCreation.activeConstraints;
  }
);

export const creationDatesSelector: (
  state: RootState
) => PlanCreationState["creationDates"] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.planCreation.creationDates;
  }
);

export const commentsSelector: (state: RootState) => string = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.planCreation.comments;
  }
);

/** Whether the data is being fetched or not */
export const isFetchingPlanCreationDataSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.planCreation.isFetching;
    }
  );
