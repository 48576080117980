import { HBDialog } from "@components/hb-customs/hb-dialog";
import { AdminAPITypes } from "@stellar/api-logic";
import { useProjectCreationContext } from "@pages/create-project/project-creation-context";
import { useAppNavigation } from "@router/use-app-navigation";

/**
 * Dialog to ask the user if they want to create a plan for the newly created project
 *
 * If so, they will be directed to the plan creation page for the project
 * Otherwise, they will be directed to the profile page of the user or company
 */
export function PlanCreationDialog(): JSX.Element {
  const projectCreationContext = useProjectCreationContext();

  const { navigateToPlanCreation, navigateToProfilePage } = useAppNavigation();

  const { subject, createdProjectId } = projectCreationContext;

  return (
    <HBDialog
      onClose={() =>
        navigateToProfilePage(subject.type, subject.company.id.toString())
      }
      confirmButtonText="Yes"
      closeButtonText="No"
      title="Do you want to create a plan for this project now?"
      onConfirm={() =>
        createdProjectId &&
        navigateToPlanCreation(
          AdminAPITypes.ESubjectType.project,
          createdProjectId
        )
      }
    />
  );
}
