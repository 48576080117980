import { useErrorHandler } from "@components/error-boundary/error-handling-context";
import { HBCollapsibleTableRow } from "@components/tables/hb-collapsible-table-row";
import { usePlansContext } from "@src/contexts/plans-context";
import { AdminAPITypes } from "@stellar/api-logic";
import { ReactNode, useState } from "react";
import { HBSortableTable } from "@components/tables/hb-sortable-table";
import { SortableColumn } from "@components/tables/hb-table-types";
import { PlanInfoGrid } from "@components/plan-info-grid/plan-info-grid";
import { FormControlLabel, Switch } from "@mui/material";
import { LoadingScreen } from "@components/loading-screen";
import { useUpdatePlan } from "@hooks/plan/use-update-plan";
import { DeactivatePlanDialog } from "@components/tables/deactivate-plan-dialog";
import { pickStatusColor } from "@utils/plan-utils/plan-table";
import { formatPlanTableName } from "@components/tables/plans-table/plans-table-utils";

interface IPlansTableProps {
  /**
   * A function that generates an array of React nodes representing the cells for a given plan in the table.
   * This allows for custom rendering of table cells based on the plan data.
   */
  createCellNodes: (plan: AdminAPITypes.IPlan) => ReactNode[];

  /** An array of column definitions used to sort and display plan data in the table. */
  columns: SortableColumn<AdminAPITypes.IPlan>[];

  /** The label to be displayed the `license` or `plan` string in the different tables. */
  licenseOrPlanLabel: string;
}

/**
 * Renders a list of plans in a sortable table with collapsible table rows.
 * Can only be used within a PlansContext.
 */
export function PlansTable({
  createCellNodes,
  columns,
  licenseOrPlanLabel,
}: IPlansTableProps): JSX.Element {
  const { plans, fetchPlans } = usePlansContext();
  const { handleErrorWithDialog } = useErrorHandler();
  const { updatePlan } = useUpdatePlan(handleErrorWithDialog);

  const [selectedPlan, setSelectedPlan] = useState<AdminAPITypes.IPlan | null>(
    null
  );
  const [isDeactivatingPlan, setIsDeactivatingPlan] = useState<boolean>(false);
  const [shouldFilterPlans, setShouldFilterPlans] = useState<boolean>(false);

  const filteredPlans = plans.filter(
    (plan) =>
      !(
        plan.status.identifier === "deactivated" ||
        plan.status.identifier === "inactive"
      )
  );

  async function onConfirmPlanDeactivation(
    plan: AdminAPITypes.IPlan,
    updatedComment: string
  ): Promise<void> {
    setIsDeactivatingPlan(true);
    await updatePlan(plan, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      deactivated: true,
      description: updatedComment,
    });
    fetchPlans();
    setSelectedPlan(null);
    setIsDeactivatingPlan(false);
  }

  return (
    <LoadingScreen isFetching={isDeactivatingPlan}>
      <>
        <FormControlLabel
          label={`Hide inactive/deactivated ${licenseOrPlanLabel}s`}
          labelPlacement="start"
          control={
            <Switch
              checked={shouldFilterPlans}
              onChange={() =>
                setShouldFilterPlans((isPrevCheck) => !isPrevCheck)
              }
              color="secondary"
            />
          }
        />
        <HBSortableTable
          columns={columns}
          tableData={shouldFilterPlans ? filteredPlans : plans}
          renderTableRow={(plan) => {
            let dropdownMenuItems;
            if (plan.status.identifier !== "deactivated") {
              dropdownMenuItems = [
                {
                  label: `Deactivate ${licenseOrPlanLabel}`,
                  onClickHandler: async () => {
                    setSelectedPlan(plan);
                  },
                },
              ];
            }

            return (
              <HBCollapsibleTableRow
                dropdownMenuItems={dropdownMenuItems}
                key={plan.id}
                name={formatPlanTableName(plan)}
                cellNodes={createCellNodes(plan)}
                backgroundColor={pickStatusColor(plan.status.identifier)}
              >
                <PlanInfoGrid plan={plan} />
              </HBCollapsibleTableRow>
            );
          }}
          isSelectable={false}
          orderById={"status"}
        />

        {selectedPlan && (
          <DeactivatePlanDialog
            plan={selectedPlan}
            onCancel={() => setSelectedPlan(null)}
            onConfirm={(updatedComment) =>
              onConfirmPlanDeactivation(selectedPlan, updatedComment)
            }
          />
        )}
      </>
    </LoadingScreen>
  );
}
