import { Box, Divider, Grid2, TextField } from "@mui/material";
import { FormContainer } from "@components/forms/form-container";
import { formatPlanSubjectType } from "@utils/data-display";
import { PlanLimitForm } from "@components/forms/plan-limit-form";
import { useAppNavigation } from "@router/use-app-navigation";
import { ProductDropdown } from "@pages/create-plan/create-plan-components/product-dropdown";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  activeConstraintsSelector,
  selectedProductSelector,
  shouldSelectedProductHaveLimits,
} from "@store/plan-creation/plan-creation-selector";
import { validatePlanCreation } from "@utils/plan-utils/validate-plan-creation";
import { HBMultilineTextField } from "@components/hb-customs/hb-multiline-text-field";
import { CreatePlanDateSection } from "@pages/create-plan/create-plan-components/create-plan-date-section";
import {
  commentsSelector,
  creationDatesSelector,
  planSubjectSelector,
} from "@store/plan-creation/plan-creation-selector";
import {
  resetPlanCreationState,
  setActiveConstraints,
  setComments,
} from "@store/plan-creation/plan-creation-slice";
import { ReactSetStateFunction } from "@utils/utility-types";
import { assert } from "@utils/assert";
import { useEffect } from "react";

interface Props {
  /** Setter to show the summary page */
  setShouldShowSummary: ReactSetStateFunction<boolean>;
}

/**
 * Renders the "Create Plan" form used for creating and managing company and project plans.
 * This form includes fields for entering plan details, and validating input.
 */
export function CreatePlanForm({ setShouldShowSummary }: Props): JSX.Element {
  const { navigateToProfilePage } = useAppNavigation();
  const comments = useAppSelector(commentsSelector);
  const { planSubjectId, planSubjectType, planSubjectName } =
    useAppSelector(planSubjectSelector);
  const activeConstraints = useAppSelector(activeConstraintsSelector);

  const dispatch = useAppDispatch();

  assert(planSubjectType, "Plan subject type should be available");
  assert(planSubjectId, "Plan subject ID should be available");

  const selectedProduct = useAppSelector(selectedProductSelector);
  const shouldProductHaveLimits = useAppSelector(
    shouldSelectedProductHaveLimits(planSubjectType)
  );
  const { startDate, endDate } = useAppSelector(creationDatesSelector);

  /**
   * Holds the validation error message, or `null` if the form data is valid.
   * If not `null`, the "Create" button is disabled and the error message is displayed to the user
   */
  const validationErrorMessage: string | null = validatePlanCreation({
    planSubjectType,
    selectedProduct,
    comments,
    startDate,
    endDate,
    shouldProductHaveLimits,
    activeConstraints,
  });

  /**  Clear constraints if the plan should not have any limits (e.g. for project plans and some features) */
  useEffect(() => {
    if (!shouldProductHaveLimits) {
      dispatch(setActiveConstraints([]));
    }
  }, [dispatch, shouldProductHaveLimits]);

  return (
    <FormContainer
      sx={{
        "& .MuiFormControl-root": {
          mt: "20px",
        },
      }}
      handleCreateClicked={() => {
        setShouldShowSummary(true);
      }}
      handleCancelClicked={() => {
        navigateToProfilePage(planSubjectType, planSubjectId);
        dispatch(resetPlanCreationState());
      }}
      shouldCreateButtonBeDisabled={validationErrorMessage !== null}
      createButtonTooltip={validationErrorMessage}
    >
      <>
        <Box sx={{ fontSize: "20px" }}>Plan Details</Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {/* plan subject name */}
          <Grid2 size={{ md: 6 }}>
            <TextField
              label={formatPlanSubjectType(planSubjectType)}
              variant="outlined"
              value={planSubjectName}
              disabled
              fullWidth
            />
          </Grid2>

          <Grid2 size={{ md: 6 }}>
            <Divider sx={{ margin: "20px 0px 10px 0px" }} />
          </Grid2>

          {/* product type */}
          <Grid2 size={{ md: 6 }}>
            <ProductDropdown planSubjectType={planSubjectType} />
          </Grid2>

          {shouldProductHaveLimits && (
            <Grid2 size={{ md: 6 }} sx={{ pt: "15px", pb: "5px" }}>
              <PlanLimitForm planSubjectType={planSubjectType} />
            </Grid2>
          )}

          <Grid2 container justifyContent="space-between">
            <CreatePlanDateSection />
          </Grid2>

          {/* comments */}
          <Grid2 size={{ md: 6 }}>
            <HBMultilineTextField
              label="comments"
              text={comments}
              setText={(newText) => dispatch(setComments(newText))}
            />
          </Grid2>
        </Box>
      </>
    </FormContainer>
  );
}
