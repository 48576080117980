export enum MainRoutes {
  /** The root page of the app which will be redirected to app route */
  root = "/",

  /** The company page of the app */
  company = "company",

  /** The project page of the app */
  project = "project",

  /** The account page of the app */
  account = "user",

  /** The group page of the app */
  group = "group",
}

/** All the params to use with Router */
export type RouteParams = {
  /** The id of the company */
  companyId: string;

  /** The id of the project */
  projectId: string;

  /** The ID of the member */
  accountId: string;

  /** The ID of the device */
  deviceId: string;

  /** The ID of the group */
  groupId: string;
};
