import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPITypes } from "@stellar/api-logic";
import { CoreApi } from "@api/index";

/** Fetches single features */
export const fetchSingleFeatures = createAsyncThunk<{
  allSingleFeatures: AdminAPITypes.IFeature[];
}>("products/fetchSingleFeatures", async () => {
  try {
    const allSingleFeatures = await CoreApi.V0.ADMIN.getAllFeatures();

    return {
      allSingleFeatures,
    };
  } catch (error) {
    throw new Error("Could not load Features");
  }
});

/** Fetches non single features meaning bundles, subscriptions and addons */
export const fetchNonSingleFeatures = createAsyncThunk<{
  allNonSingleFeatures: AdminAPITypes.IFeatureBundle[];
}>("products/fetchNonSingleFeatures", async () => {
  try {
    const allNonSingleFeatures = await CoreApi.V0.ADMIN.getAllFeatureBundles();

    return {
      allNonSingleFeatures,
    };
  } catch (error) {
    throw new Error("Could not load Feature Bundles");
  }
});
