import { TableCell } from "@mui/material";
import { CompanyLink } from "@components/links/internal-links";
import { HBSortableTable } from "@components/tables/hb-sortable-table";
import { AdminAPITypes } from "@stellar/api-logic";
import { DateTimeUtils } from "@stellar/web-core";
import { SortableColumn } from "@components/tables/hb-table-types";

interface IHBCompanyTableProps {
  companies: AdminAPITypes.IAdmCompany[];
}

export function HBCompanyTable({
  companies,
}: IHBCompanyTableProps): JSX.Element {
  const columns: SortableColumn<AdminAPITypes.IAdmCompany>[] = [
    { id: "name", label: "Name" },
    { id: "projectCount", label: "Projects" },
    { id: "memberCount", label: "Members" },
    { id: "createdAt", label: "Created" },
  ];

  return (
    <HBSortableTable
      renderTableCells={(company: AdminAPITypes.IAdmCompany) => (
        <CompanyTableRowCells company={company} />
      )}
      columns={columns}
      tableData={companies}
    />
  );
}

function CompanyTableRowCells({
  company,
}: {
  company: AdminAPITypes.IAdmCompany;
}): JSX.Element {
  return (
    <>
      <TableCell component="th" scope="row" padding="none">
        <CompanyLink companyId={company.id}>{company.name}</CompanyLink>
      </TableCell>
      <TableCell>{company.projectCount}</TableCell>
      <TableCell>{company.memberCount}</TableCell>
      <TableCell>{DateTimeUtils.format({ date: company.createdAt })}</TableCell>
    </>
  );
}
