import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { runtimeConfig } from "@src/runtime-config";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  dsn: runtimeConfig.keys.sentryDsn,
  environment: `${runtimeConfig.deployment.topLevelDomain}-${runtimeConfig.deployment.environment}`,
  release: runtimeConfig.appVersion,
  // Sets the sample rate to be 10% as suggested by Sentry
  replaysSessionSampleRate: 0.1,

  // Sample all sessions that an error occurs
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),

    // This integration is used to mask sensitive data in the replay session
    Sentry.replayIntegration({
      /* eslint-disable @typescript-eslint/naming-convention -- properties coming from external package */
      maskAllText: true,
      blockAllMedia: true,
      maskAllInputs: true,
      /* eslint-enable */
    }),
  ],
});
