import { AdminAPITypes } from "@stellar/api-logic";
import { Color } from "@utils/styles/default-colors";

export function pickStatusColor(
  status: AdminAPITypes.PlanStatusIdentifier
): string {
  switch (status) {
    case "active":
      return Color.planStatusActive;
    case "inactive":
      return Color.planStatusInactive;
    case "invalid":
      return Color.planStatusInvalid;
    case "deactivated":
      return Color.planStatusDeactivated;
    default:
      return "";
  }
}
