import { ReactNode } from "react";
import { PlanStatusTableHeader } from "@components/tables/plan-status-table-header";
import { Compare } from "@utils/compare";
import { SortableColumn } from "@components/tables/hb-table-types";
import { AdminAPITypes } from "@stellar/api-logic";
import { PlansTable } from "@components/tables/plans-table/plans-table";
import { EndDateColumn } from "@components/tables/plans-table/end-date-column";
import { PlanDataExtractor } from "@utils/plan-utils/plan-data-extractor";
import { getFormattedConstraints, uppercaseFirst } from "@utils/data-display";
import { PlanSubjectLink } from "@components/tables/plan-subject-link";
import { DateTimeUtils } from "@stellar/web-core";

/**
 * Defines the configuration for the table columns, including labels, sorting behavior, and custom rendering.
 * Each object in the `columns` array represents a column in the plan table with the following properties:
 *   - `id`: A unique identifier for the column.
 *   - `label`: The display name as the column header.
 *   - `compareFunction`: A custom comparison function to enable sorting by the column's data.
 *   - `shouldDisableSorting` (optional): Disables sorting for specific columns if set to `true`.
 */
const columns: SortableColumn<AdminAPITypes.IPlan>[] = [
  { id: "name", label: "Plan", compareFunction: Compare.plansByName },
  {
    id: "limits",
    label: "Limits",
    compareFunction: Compare.plansByConstraints,
  },
  {
    id: "status",
    label: <PlanStatusTableHeader key={"Status"} />,
    compareFunction: Compare.plansByStatus,
  },
  {
    id: "subject",
    label: "Assigned To",
    compareFunction: Compare.plansBySubject,
  },
  {
    id: "assignee",
    label: "Assignee Name",
    compareFunction: Compare.plansByAssignee,
  },
  {
    id: "startDate",
    label: "Start Date",
    compareFunction: Compare.plansByStartDate,
  },
  {
    id: "endDate",
    label: "Expiration Date",
    compareFunction: Compare.plansByEndDate,
  },
  {
    id: "actions",
    label: "",
    shouldDisableSorting: true,
  },
];

/** Renders a sortable table displaying all company/project plans. */
export function GeneralPlansTable(): JSX.Element {
  return (
    <PlansTable
      createCellNodes={createCellNodes}
      columns={columns}
      licenseOrPlanLabel="plan"
    />
  );
}

/** Generates an array of React nodes, each representing a cell in the table row for the given company/project plan. */
function createCellNodes(plan: AdminAPITypes.IPlan): ReactNode[] {
  return [
    <div key="constraints">
      {getFormattedConstraints(plan.constraints)
        .split("\n")
        .map((constraint, index) => (
          <p key={index}>{constraint}</p>
        ))}
    </div>,
    uppercaseFirst(plan.status.identifier),
    plan.subject.type,
    <PlanSubjectLink plan={plan} key={plan.subject.id} />,
    DateTimeUtils.format({ date: plan.startDate }),
    <EndDateColumn
      endDate={PlanDataExtractor.getEndDate(plan)}
      key={plan.subject.id}
    />,
  ];
}
