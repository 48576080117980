import {
  Collapse,
  Grid2,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
} from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowRight from "@mui/icons-material/ArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { Color } from "@styles/default-colors";
import { HBPopoverMenu } from "@components/hb-customs/hb-popover-menu";

/** Content to show for each cell in a row itself */
interface ICollapsibleTableRowProps {
  /** The content of the first cell in a row */
  name: string;

  /** The content of each cell in a row except the first cell */
  cellNodes: ReactNode[];

  /**  List of menu items to show in the popover menu. If not provided, the corresponding button will be disabled */
  dropdownMenuItems?: {
    /** Text to show for the menu entry */
    label: string;

    /** Function to call when the menu item has been selected */
    onClickHandler(): void | Promise<void>;
  }[];

  /** The background color of the row */
  backgroundColor?: string;
}

/**
 * TableRow that provides more information when clicked on.
 * The content inside the expandable part is provided by the children
 */
export function HBCollapsibleTableRow({
  name,
  cellNodes,
  dropdownMenuItems,
  backgroundColor,
  children,
}: PropsWithChildren<ICollapsibleTableRowProps>): JSX.Element {
  const [isRowExpanded, setIsRowExpanded] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [colSpan, setColSpan] = useState<number>();

  const menuAnchorElementRef = useRef<HTMLButtonElement>();
  const dataTableRowRef = useRef<HTMLTableRowElement>(null);

  const isPopoverMenuEnabled = !!dropdownMenuItems?.length;

  // Make sure that dataTableRowRef has been set on the element and then calculate the column span
  useEffect(() => {
    setColSpan(dataTableRowRef.current?.children.length);
  }, [dataTableRowRef]);

  function handleRowMenuClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    // Do not change open state on menu open
    event.stopPropagation();

    menuAnchorElementRef.current = event.currentTarget;

    setIsMenuOpen(true);
  }

  return (
    <>
      <TableRow
        onClick={() => setIsRowExpanded(!isRowExpanded)}
        sx={{
          cursor: "pointer",
          "& > *": {
            borderBottom: "unset",
            backgroundColor: backgroundColor ?? "",
          },
        }}
        ref={dataTableRowRef}
      >
        <TableCell
          sx={{ color: Color.grey46, verticalAlign: "middle" }}
          scope="row"
        >
          <Grid2 container alignItems="center">
            <Grid2>
              <Grid2 container>
                {isRowExpanded ? <ArrowDropDown /> : <ArrowRight />}
              </Grid2>
            </Grid2>
            <Grid2>{name}</Grid2>
          </Grid2>
        </TableCell>

        {cellNodes.map((cellValue, index) => {
          return (
            <TableCell
              sx={{ color: Color.grey46, verticalAlign: "middle" }}
              key={index}
            >
              {cellValue}
            </TableCell>
          );
        })}

        <TableCell align="right">
          {isPopoverMenuEnabled && (
            <IconButton size="small" onClick={handleRowMenuClick}>
              <MoreVertIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          // Span this cell to be as wide as the whole TableRow above
          colSpan={colSpan}
          sx={{
            py: "0px",
            backgroundColor: backgroundColor ?? "",
          }}
        >
          <Collapse
            sx={{
              mb: "10px",
              mt: "10px",
            }}
            in={isRowExpanded}
            timeout="auto"
            unmountOnExit
          >
            {children}
          </Collapse>
        </TableCell>
      </TableRow>

      {isMenuOpen && isPopoverMenuEnabled && (
        <HBPopoverMenu
          anchorEl={menuAnchorElementRef.current}
          onClose={() => setIsMenuOpen(false)}
        >
          {dropdownMenuItems?.map(({ label, onClickHandler }, index) => (
            <MenuItem
              key={index}
              onClick={async () => {
                await onClickHandler();
                setIsMenuOpen(false);
              }}
            >
              {label}
            </MenuItem>
          ))}
        </HBPopoverMenu>
      )}
    </>
  );
}
