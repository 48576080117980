import { PageNotFound } from "@pages/page-not-found";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { MainRoutes } from "@router/route-params";
import { ProjectProfilePage } from "@pages/project-profile/project-profile";
import { CreateProjectPlanPage } from "@pages/create-plan/create-project-plan-page";
import { CompanyProfilePage } from "@pages/company-profile/company-profile";
import { CreateCompanyPlanPage } from "@pages/create-plan/create-company-plan-page";
import { CreateProjectForCompany } from "@pages/create-project/create-project-for-company";
import { AccountProfilePage } from "@pages/account-profile/account-profile";
import { HomePage } from "@pages/home/home-page";
import { App } from "@src/app";
import * as Sentry from "@sentry/react";

/**
 * Sentry's React Router integration
 * https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v6
 */
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        path={MainRoutes.root}
        element={<App />}
        errorElement={<PageNotFound />}
      >
        <Route
          path={MainRoutes.root}
          element={<HomePage />}
          errorElement={<PageNotFound />}
        />
        <Route path={"company"}>
          <Route path={":companyId"} element={<CompanyProfilePage />} />
          <Route
            path={":companyId/add-plan"}
            element={<CreateCompanyPlanPage isDevicePlan={false} />}
          />
          <Route
            path={":companyId/add-device-plan"}
            element={<CreateCompanyPlanPage isDevicePlan={true} />}
          />
          <Route
            path={":companyId/group/:groupId/add-project"}
            element={<CreateProjectForCompany />}
          />
        </Route>

        <Route path={"project"}>
          <Route path={":projectId"} element={<ProjectProfilePage />} />
          <Route
            path={":projectId/add-plan"}
            element={<CreateProjectPlanPage />}
          />
        </Route>

        <Route path={"user"}>
          <Route path={":accountId"} element={<AccountProfilePage />} />
        </Route>
      </Route>
    </Route>
  )
);
