import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AdminAPITypes } from "@stellar/api-logic";
import {
  BaseProduct,
  ProductIdentifier,
} from "@store/plan-creation/plan-creation-slice-helper-types";
import {
  fetchNonSingleFeatures,
  fetchSingleFeatures,
} from "@store/plan-creation/plan-creation-slice-thunks";
import {
  allAvailableCustomProducts,
  allProducts,
} from "@utils/product/product-list";
import {
  defaultPlanActiveConstraints,
  defaultPlanDuration,
} from "@store/plan-creation/plan-creation-slice-helper";

export interface PlanCreationState
  extends EntityState<BaseProduct, ProductIdentifier> {
  /** The type of plan subject */
  planSubject: {
    /** The ID of the plan subject, e.g. company, project or account */
    planSubjectId: string | null;

    /** The type of the plan subject, e.g. company, project or account */
    planSubjectType: AdminAPITypes.ESubjectType | null;

    /** The name of the plan subject, e.g. company, project or account */
    planSubjectName: string | null;
  };

  /** The identifier of the selected product, either a feature, bundle or a subscription */
  selectedProductIdentifier: ProductIdentifier | null;

  /** Active constraints added to the plan */
  activeConstraints: AdminAPITypes.CreateConstraintPayload[];

  /**
   * Start and end date of the plan creation
   * Setting initial startDate & endDate to "now" because the time of the day does not matter at this point and will
   * be adjusted before creating the plan through API. Using start/end of day at this point can cause problems because
   * of different time zones vs. UTC and adds to much confusion in general.
   */
  creationDates: {
    startDate: number;
    endDate: number;
  };

  /** Comments added to the plan */
  comments: string;

  /** Whether the data is being fetched */
  isFetching: boolean;
}

/** Creates an entity adapter to store a map with all the products that are going to be updated */
export const PlanCreationAdapter = createEntityAdapter({
  selectId: (product: BaseProduct) => product.identifier,
});

const initialState: PlanCreationState = {
  ...PlanCreationAdapter.getInitialState({
    planSubject: {
      planSubjectId: null,
      planSubjectType: null,
      planSubjectName: null,
    },
    selectedProductIdentifier: null,
    activeConstraints: [],
    comments: "",
    creationDates: {
      startDate: new Date().getTime(),
      endDate: defaultPlanDuration(null, new Date().getTime()),
    },
    isFetching: false,
  }),
};

/** Slice to access state of plan creation */
const planCreationSlice = createSlice({
  name: "planCreation",
  initialState,
  reducers: {
    setPlanSubject(
      state,
      action: PayloadAction<PlanCreationState["planSubject"]>
    ) {
      state.planSubject = action.payload;
    },

    /** Set the selected product identifier */
    setSelectedProductIdentifier(
      state,
      action: PayloadAction<ProductIdentifier | null>
    ) {
      state.selectedProductIdentifier = action.payload;

      // Adjust the default plan duration if the selected product is changed
      state.creationDates = {
        ...initialState.creationDates,
        endDate: defaultPlanDuration(
          action.payload,
          state.creationDates.startDate
        ),
      };

      // Adjust the default plan active constraints if the selected product is changed
      state.activeConstraints = defaultPlanActiveConstraints(action.payload);
    },

    /** Set the custom products to the product store */
    setCustomProducts(state) {
      PlanCreationAdapter.setMany(
        state,
        Object.values(allAvailableCustomProducts)
      );
    },

    setActiveConstraints(
      state,
      action: PayloadAction<AdminAPITypes.CreateConstraintPayload[]>
    ) {
      state.activeConstraints = action.payload;
    },

    setCreationDates(
      state,
      action: PayloadAction<Partial<PlanCreationState["creationDates"]>>
    ) {
      state.creationDates = {
        startDate: action.payload.startDate ?? state.creationDates.startDate,
        endDate: action.payload.endDate ?? state.creationDates.endDate,
      };
    },

    setComments(state, action: PayloadAction<string>) {
      state.comments = action.payload;
    },

    setIsFetchingData(state, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },

    /** Reset the whole plan creation slice */
    resetPlanCreationState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSingleFeatures.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(fetchSingleFeatures.fulfilled, (state, action) => {
        state.isFetching = false;

        const features = action.payload.allSingleFeatures.reduce<BaseProduct[]>(
          (acc, feature) => {
            const product = allProducts[feature.identifier];
            if (product) {
              acc.push({
                ...product,
                // The name and description of our definition has priority over what we receive from backend
                name: product.name ?? feature.name,
                description: product.description ?? feature.description,
              });
            }

            return acc;
          },
          []
        );

        PlanCreationAdapter.setMany(state, {
          ...features,
        });
      })
      .addCase(fetchSingleFeatures.rejected, (state, action) => {
        state.isFetching = false;
      })

      .addCase(fetchNonSingleFeatures.pending, (state, action) => {
        state.isFetching = true;
      })
      .addCase(fetchNonSingleFeatures.fulfilled, (state, action) => {
        state.isFetching = false;

        const nonSingleProducts = action.payload.allNonSingleFeatures.reduce<
          BaseProduct[]
        >((acc, bundle) => {
          const product = allProducts[bundle.identifier];
          if (product) {
            acc.push({
              ...product,
              // The name and description of our definition has priority over what we receive from backend
              name: product.name ?? bundle.name,
              description: product.description ?? bundle.description,
            });
          }
          return acc;
        }, []);

        PlanCreationAdapter.setMany(state, {
          ...nonSingleProducts,
        });
      })
      .addCase(fetchNonSingleFeatures.rejected, (state, action) => {
        state.isFetching = false;
      });
  },
});

export const {
  setPlanSubject,
  setSelectedProductIdentifier,
  setCustomProducts,
  setActiveConstraints,
  setCreationDates,
  setComments,
  setIsFetchingData,
  resetPlanCreationState,
} = planCreationSlice.actions;

export const planCreationReducer = planCreationSlice.reducer;
