import { Box, Grid2 } from "@mui/material";
import { InfoRow } from "@components/info-grid/info-row";
import { ILabelInfoPair } from "@components/info-grid/info-row-types";
import { Color } from "@styles/default-colors";
import { HBGridItem } from "@components/hb-customs/hb-grid-item";
import { HBPaperList, IListItems } from "@components/hb-customs/hb-paper-list";

interface IHeaderProps {
  /** Main text of the header */
  heading: string;

  /** Text displayed above the main text */
  title?: string;

  /** Text displayed below the main text */
  subheader?: string;

  /** List of label-text pairs used to render additional information on the left side of the header below subheader */
  leftProperties?: IListItems[];

  /** List of label-text pairs used to render additional information on the right side of the header */
  rightProperties?: ILabelInfoPair[];

  /** Setting for rendering a header with less height */
  shouldUseSlimVariant?: boolean;
}

/** Generates an information panel with a header and list of properties on left and right */
export function Header({
  title,
  heading,
  subheader,
  leftProperties = [],
  rightProperties = [],
  shouldUseSlimVariant,
}: IHeaderProps): JSX.Element {
  return (
    <Grid2
      container
      justifyContent="space-between"
      sx={{
        padding: shouldUseSlimVariant ? "20px 100px" : "50px 100px",
        borderBottom: shouldUseSlimVariant ? `1px solid ${Color.grey05}` : "",
      }}
    >
      <HBGridItem width={6}>
        <Box sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.4)" }}>
          {title}
        </Box>
        <Box sx={{ fontSize: "24px", color: Color.black }}>{heading}</Box>
        {subheader && (
          <Box
            sx={{
              fontSize: "16px",
              color: "rgba(0, 0, 0, 0.4)",
              overflowWrap: "anywhere",
            }}
          >
            {subheader}
          </Box>
        )}
      </HBGridItem>

      <HBGridItem width={6}>
        {rightProperties.map((property, index) => (
          <InfoRow
            key={index}
            sx={{ margin: "16px", fontSize: "16px" }}
            label={property.label}
            info={property.info}
            shouldShowColon
          />
        ))}
      </HBGridItem>

      <HBGridItem width={12}>
        <HBPaperList items={leftProperties} />
      </HBGridItem>
    </Grid2>
  );
}
